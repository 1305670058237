import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Segment,
  Icon,
  Message,
  Button,
  Modal,
  Loader,
  Header,
  Grid,
} from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { store } from '../../store';
import config from '../../config';
import additionalMessages from '../../messages';
import WorkbenchPanel from './Workbenchpanel';
import HeaderPanel from './Header';
import MainMenu from './MainMenu';
import ConfirmAction from '../../components/ConfirmAction';
import {
  uiCollapseSidebar,
  uiExpandSidebar,
  uiSet,
  warningMsg,
} from './mainframe.reducer';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSessionJSON, setSessionJSON } from 'src/utils/useful_functions';

function handleDismiss() {
  store.dispatch(warningMsg());
}

const clearUpdate = () => {
  // clear the hasUpdated flag as soon as it has been read
  localStorage.removeItem('cn.lp.hasUpdated');
  store.dispatch(uiSet({ hasUpdated: false }));
};

function ApplicationUpdating() {
  return (
    <Modal
      open={true}
      dimmer="inverted"
      inverted
      centered={false}
      closeOnDimmerClick={false}
      size="tiny"
    >
      <Modal.Header>
        <Header as="h1">
          <FormattedMessage
            id="mainFrame.Updating"
            defaultMessage="LINKPlanner is updating"
          />
        </Header>
      </Modal.Header>
      <Modal.Content scrolling>
        <Grid>
          <Grid.Row verticalAlign="middle">
            <Grid.Column textAlign="center" width={2}>
              <Loader inline />
            </Grid.Column>
            <Grid.Column width={14}>
              <Segment basic size="huge">
                Please wait for the update to complete...
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

function UpdatedSuccessfully(props) {
  const { formatMessage } = props.intl;

  return (
    <Modal
      open={true}
      dimmer="inverted"
      inverted
      centered={false}
      closeOnDimmerClick={false}
      onClose={clearUpdate}
    >
      <Modal.Header>
        <Header as="h1">
          <FormattedMessage
            id="mainFrame.Updated"
            defaultMessage="LINKPlanner Updated"
          />
        </Header>
      </Modal.Header>
      <Modal.Content scrolling>
        <Segment basic size="huge">
          LINKPlanner has updated successfully to v{window.runtime.version}.
        </Segment>
        <Segment basic size="huge">
          The{' '}
          <a
            href={config.releaseNotes}
            title={formatMessage(additionalMessages.releaseNotes)}
            target="_blank"
            rel="noopener noreferrer"
          >
            release notes
          </a>{' '}
          document the changes in this version.
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={clearUpdate}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
}

function MainFrame(props) {
  const {
    connected,
    warning,
    loggedIn,
    isUpdating,
    hasUpdated,
    expandSidebar,
    projectId,
  } = useSelector((state) => state.mainFrame);
  const [connectedAfterGrace, setConnectedAfterGrace] = useState(true);
  const connectionDelay = useRef(null);
  const sidebarRef = useRef(null);
  const mainContentRef = useRef(null);
  const resizeHandleRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!connected) {
      // start timeout waiting for connection
      // if not connected after 5 sec, show error
      connectionDelay.current = setTimeout(
        () => setConnectedAfterGrace(false),
        5000
      );
    } else {
      if (!connectedAfterGrace) {
        // show as connected if we are currently showing disconnected
        setConnectedAfterGrace(true);
      }

      if (connectionDelay.current != null) {
        // connected, clear timeout if there is one
        clearTimeout(connectionDelay.current);
        connectionDelay.current = null;
      }
    }

    return () => {
      if (connectionDelay.current != null) {
        // clear timeout on unmount
        clearTimeout(connectionDelay.current);
        connectionDelay.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  useEffect(() => {
    let isResizing = false;

    resizeHandleRef.current.addEventListener('mousedown', function (e) {
      isResizing = true;
    });

    document.addEventListener('mousemove', function (e) {
      const { clientX } = e;
      if (!isResizing) return;
      const cutOffWidth = document.getElementById('root').clientWidth;
      if (clientX > cutOffWidth / 2) {
        return;
      }
      if (clientX <= 60) {
        dispatch(uiCollapseSidebar());
      } else {
        dispatch(uiExpandSidebar());
        sidebarRef.current.style.width = clientX + 'px';
        resizeHandleRef.current.style.left = clientX + 'px';
        mainContentRef.current.style.width = `calc(100vw - ${clientX}px)`;
        setSessionJSON('cn.lp.sidebarWidth', clientX);
      }
    });

    document.addEventListener('mouseup', function () {
      isResizing = false;
    });
  }, []);

  useEffect(() => {
    if (!projectId) {
      mainContentRef.current.style.width = `calc(100vw - ${0}px)`;
    }
    if (projectId) {
      const width = expandSidebar
        ? getSessionJSON('cn.lp.sidebarWidth', 250)
        : 50;
      sidebarRef.current.style.width = `${width}px`;
      resizeHandleRef.current.style.left = `${width}px`;
      mainContentRef.current.style.width = `calc(100vw - ${width}px)`;
    }
  }, [expandSidebar, projectId]);

  let message = null;
  if (warning) {
    let dismiss = null;
    if (warning.allowClose) {
      dismiss = handleDismiss;
    }

    const additionalAttrs = {
      error: warning.error ? true : false,
      success: warning.success ? true : false,
      warning: warning.warning ? true : false,
      info: warning.info ? true : false,
    };
    const icon = warning.icon ? warning.icon : 'warning sign';

    message = (
      <Message {...additionalAttrs} icon onDismiss={dismiss} size="mini">
        <Icon name={icon} />
        <Message.Content>
          <Message.Header>{warning.heading}</Message.Header>
          {warning.message}
        </Message.Content>
      </Message>
    );
  }

  // show connection error when user is logged in and not connected
  const connectionError =
    !loggedIn || connectedAfterGrace ? null : (
      <Segment raised>
        <Message error icon>
          <Icon name="warning sign" />
          <Message.Content>
            <Message.Header>
              <FormattedMessage
                id="mainMenu.connectionError"
                defaultMessage="Server Connection Error"
              />
            </Message.Header>
            <FormattedMessage
              id="mainMenu.connectionErrorDetail"
              defaultMessage="The connection with the server has been lost. Please reload this page to try to reconnect."
            />
            <Segment basic>
              <Button
                onClick={() => window.location.reload()}
                color="black"
                size="large"
              >
                Reload Page
              </Button>
            </Segment>
          </Message.Content>
        </Message>
      </Segment>
    );

  const updateNotification = isUpdating
    ? ApplicationUpdating()
    : hasUpdated
    ? UpdatedSuccessfully({ ...props })
    : null;

  let sidebarClass = null;
  if (!projectId) {
    sidebarClass = 'hidden';
  }

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ConfirmAction />
      {updateNotification}
      <HeaderPanel {...props} />
      {message}
      {connectionError ? connectionError : null}
      <div class="outer-container">
        <div class="inner-container">
          <div
            className={`col sidebar-${sidebarClass} noselect`}
            id="sidebar"
            ref={sidebarRef}
          >
            <MainMenu />
            <div
              class="resize-handle"
              id="resizeHandle"
              ref={resizeHandleRef}
            ></div>
          </div>
          <div class="col" id="mainContent" ref={mainContentRef}>
            <WorkbenchPanel>{props.children}</WorkbenchPanel>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(MainFrame);
