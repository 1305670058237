import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Menu,
  Icon,
  Header,
  Popup,
  Input,
  Dropdown,
  Label,
  Divider,
} from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import about from '../../About';
import Preferences from '../user/Preferences';
import AdminTools from '../user/AdminTools';
import config from '../../config';
import { logoutUser } from '../../api';
import additionalMessages from '../../messages';
import { store } from '../../store';
import LayerList from './LayerList';
import { getUserLimits } from './mainframe.reducer';
import { getCanvas } from '../../utils/mapUtils';
import { FileMenu } from './FileMenu';
import { doSave } from './SaveProjectDialog';
import { ExportMenu } from './ExportMenu';
import ReportMenu from './ReportMenu';
import ImportMenu from './ImportMenu';
import CSVFileImportModal from 'src/pages/sites/CSVFileImportModal';

const DEFAULT_FILTER_WIDTH = '8vw';

class HeaderPanelContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      filterWidth: DEFAULT_FILTER_WIDTH,
      showPreferences: false,
      showAdminTools: false,
      importType: null,
    };
  }

  onClearFilter = (e) => {
    this.setState({ filter: '' });
    getCanvas().setFilter('');
  };

  componentDidMount = () => {
    store.dispatch(getUserLimits());
  };

  onChange = (e, { attr, value }) => {
    this.setState({ filter: value });
    getCanvas().setFilter(value);
  };

  showPreferencesHandler = (e) => {
    e.preventDefault();
    this.setState({ showPreferences: true });
  };
  onPreferenceClose = () => {
    this.setState({ showPreferences: false });
  };
  showAdminToolsHandler = (e) => {
    e.preventDefault();
    this.setState({ showAdminTools: true });
  };
  onAdminToolsClose = () => {
    this.setState({ showAdminTools: false });
  };
  setImportType = (importType) => {
    this.setState({ importType });
  };

  render = () => {
    const { filter, filterWidth, showPreferences, showAdminTools, importType } =
      this.state;
    const { props } = this;
    const { formatMessage } = props.intl;
    const {
      loggedIn,
      firstName,
      lastName,
      locked,
      projectId,
      projectName,
      pathname,
      superuser,
      permissionWrite,
      projectModified,
    } = props;
    const name = firstName && lastName ? `${firstName} ${lastName}` : firstName;
    let login = null;
    let logout = null;
    let preferences = null;
    let adminTools = null;
    let preferencesContent = null;
    let adminToolsContent = null;

    if (showPreferences) {
      preferencesContent = (
        <Preferences
          intl={props.intl}
          onClose={this.onPreferenceClose}
        ></Preferences>
      );
    }
    if (showAdminTools) {
      adminToolsContent = (
        <AdminTools
          intl={props.intl}
          onClose={this.onAdminToolsClose}
        ></AdminTools>
      );
    }
    if (loggedIn) {
      preferences = (
        <Dropdown.Item
          as={Link}
          to="/preferences"
          onClick={this.showPreferencesHandler}
          title={formatMessage(additionalMessages.preferenceTooltip)}
          text={formatMessage({
            id: 'header.preferences',
            defaultMessage: 'Preferences',
          })}
          floating
          labeled
          className="mr-2"
          icon={{ name: '' }}
        ></Dropdown.Item>
      );
      adminTools = (
        <Dropdown.Item
          as={Link}
          to="/user/info"
          onClick={this.showAdminToolsHandler}
          title={formatMessage(additionalMessages.adminTooltip)}
        >
          <FormattedMessage
            id="header.adminTools"
            defaultMessage="Admin Tools"
          />
        </Dropdown.Item>
      );
      logout = (
        <Dropdown.Item
          as={Link}
          to="#"
          onClick={logoutUser}
          title={formatMessage(additionalMessages.logout)}
        >
          <FormattedMessage id="header.logout" defaultMessage="Logout" />
        </Dropdown.Item>
      );
    } else if (pathname !== '/login') {
      login = (
        <Link to="/login" title={formatMessage(additionalMessages.login)}>
          <FormattedMessage id="header.login" defaultMessage="Login" />
        </Link>
      );
    }

    const branch = window.runtime.branch ? (
      <>
        <Header>Branch</Header>
        {window.runtime.branch}
      </>
    ) : null;
    const changeset = window.runtime.changeset ? (
      <>
        <Header>Changeset</Header>
        {window.runtime.changeset}
      </>
    ) : null;
    let iconColour = 'grey';

    if (permissionWrite && projectId) {
      if (projectModified) {
        // TODO: Handle when the project has been modified
        iconColour = 'red';
      } else if (loggedIn) {
        iconColour = 'black';
      }
    }

    const tag = (
      <Menu.Item fitted>
        <Header className="App-title">
          <Popup
            //   size="large"
            hoverable
            content={
              <>
                <Header>Release Date</Header>
                {window.runtime.date}
                {changeset}
                {branch}
              </>
            }
            trigger={
              <Label
                color="blue"
                size="large"
                style={{ marginTop: '0', marginRight: '1rem' }}
              >
                {window.runtime.tag
                  ? window.runtime.tag
                  : `v${window.runtime.version}`}
              </Label>
            }
          />
        </Header>
      </Menu.Item>
    );
    // const getWidth = () => {
    //   const mapPanelWidth =
    //     document.getElementsByClassName('map-mode-panel')[0].clientWidth;
    //   return mapPanelWidth + 100 + 'px';
    // };
    return (
      <>
        <Fragment>
          {preferencesContent}
          {adminToolsContent}
          <Menu
            fluid
            borderless
            className="lp-header"
            style={{
              marginTop: '0px',
              height: '48.4px',
              backgroundColor: '#25478D',
              borderRadius: '0',
            }}
          >
            <Menu.Item fitted link href={about.cambiumHome} target="_blank">
              <Icon
                className="brand"
                style={{ fontSize: '14em', marginRight: 0, marginLeft: '12px' }}
              ></Icon>
            </Menu.Item>
            <Menu.Item fitted>
              &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
              <Header className="App-title" style={{ paddingTop: '2px' }}>
                LINKPlanner
              </Header>
            </Menu.Item>

            <div className="d-flex" style={{ marginLeft: '30px' }}>
              {projectId && !locked ? (
                <Menu.Item className="top-menu">
                  <FileMenu />
                  <ReportMenu />
                  <ImportMenu setImportType={this.setImportType} />
                  <ExportMenu />
                  <Dropdown
                    text="Preferences"
                    onClick={this.showPreferencesHandler}
                    title={formatMessage(additionalMessages.preferenceTooltip)}
                    basic
                    floating
                    labeled
                    className="mr-2"
                    icon={{ name: '' }}
                  ></Dropdown>

                  <Dropdown
                    basic
                    text="Help"
                    floating
                    labeled
                    title={formatMessage(additionalMessages.help)}
                    className="mr-2"
                    icon={{ name: '' }}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="a"
                        href={config.userGuide}
                        title={formatMessage(additionalMessages.userGuide)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatMessage(additionalMessages.userGuide)}
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="a"
                        href={config.support}
                        title={formatMessage(additionalMessages.support)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatMessage(additionalMessages.support)}
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="a"
                        href={config.releaseNotes}
                        title={formatMessage(additionalMessages.releaseNotes)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatMessage(additionalMessages.releaseNotes)}
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/about"
                        title={formatMessage(
                          additionalMessages.aboutLINKPlanner
                        )}
                      >
                        {formatMessage(additionalMessages.about)}
                        {window.runtime.version}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>
              ) : null}
            </div>

            <Menu.Item position="right">
              {projectId ? (
                <>
                  <Header
                    className="project-name-header"
                    title={projectName}
                    as={'h3'}
                  >
                    {projectName}
                  </Header>
                  <Popup
                    trigger={
                      <Icon
                        link
                        className="layers-icon"
                        size="big"
                        title="Select visible features in the map"
                      />
                    }
                    on="click"
                    position="bottom center"
                  >
                    <LayerList />
                  </Popup>
                  <Input
                    style={{ width: filterWidth }}
                    title={formatMessage(additionalMessages.filterInMap)}
                    placeholder={formatMessage(
                      additionalMessages.filterInMapPlaceHolder
                    )}
                    icon={{
                      name: 'cancel',
                      onClick: this.onClearFilter,
                      link: true,
                      title: formatMessage(additionalMessages.clearFilter),
                    }}
                    value={filter}
                    onChange={this.onChange}
                    onFocus={() => this.setState({ filterWidth: '15vw' })}
                    onBlur={() =>
                      setTimeout(() => {
                        this.setState({ filterWidth: DEFAULT_FILTER_WIDTH });
                      }, 1)
                    }
                  />
                </>
              ) : null}
              &nbsp; &nbsp; &nbsp; &nbsp;
              {login}
              &nbsp; &nbsp;
              {tag}
              {loggedIn ? (
                <Dropdown
                  direction="left"
                  basic
                  icon={'user'}
                  className="link item user-highlight"
                >
                  <Dropdown.Menu>
                    {superuser && adminTools}
                    {superuser && <Divider />}
                    {logout}
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
            </Menu.Item>
          </Menu>
          <CSVFileImportModal
            groupKind={importType}
            showModal={!!importType}
            handleClose={() => this.setImportType(null)}
          ></CSVFileImportModal>
        </Fragment>
      </>
    );
  };
}

const HeaderPanel = connect((state, ownProps) => {
  const {
    loggedIn,
    firstName,
    lastName,
    locked,
    projectId,
    superuser,
    permissionWrite,
    projectModified,
    projectName,
    mapState,
  } = state.mainFrame;
  return {
    ...ownProps,
    loggedIn,
    firstName,
    lastName,
    locked,
    projectId,
    superuser,
    permissionWrite,
    projectModified,
    projectName,
    mapState,
  };
})(HeaderPanelContainer);

function WrapHeader(props) {
  const { pathname } = useLocation();
  return <HeaderPanel {...props} pathname={pathname} />;
}

export default WrapHeader;
