import { useReducer, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { postWithAuth } from 'src/api';
import { zip, range, pyround } from 'src/utils/useful_functions';
import { getAllEquipmentFromChoices } from './utils';

export function fetchFrequencyLists(
  projectId,
  id,
  localFreqs,
  remoteFreqs,
  choices
) {
  const equipment = getAllEquipmentFromChoices(choices);
  return postWithAuth(
    `project/${projectId}/ptp/${id}/frequency_list`,
    {
      equipment: equipment.map(
        (eq) => Object.fromEntries(Object.values(eq).map((c) => [c.attr_name, c.value]))
      ),
      remote_product_names: equipment.map(
        (eq) => eq?.remote_product?.value
      ),
      frequencies: {
        local: localFreqs,
        remote: remoteFreqs,
      },
    },
    'POST'
  );
}

export function checkInvalidFreqs(localHilo, localValue, remoteValue) {
  // for Any T/R we need to check that the Lo value is actually
  // lower than the Hi value
  // Return true if the Lo is higher than Hi
  let hi, lo;
  if (localHilo === 'Hi') {
    // local is hi
    hi = localValue;
    lo = remoteValue;
  } else {
    // remote is hi
    hi = remoteValue;
    lo = localValue;
  }
  return lo > hi;
}

export function listMissingValue(list, value) {
  if (list.length === 0) {
    return false;
  }

  if (value == null) {
    return true;
  }

  return !list.includes(value);
}

export function trSpacingWillReset(getValues, radios, trSpacing) {
  for (let i = 0; i < radios.length; i++) {
    // check tr spacing of any selected freqs, if available
    const [local, remote] = getValues([
      `local.radios.${i}.frequency.tx_frequency`,
      `remote.radios.${i}.frequency.tx_frequency`,
    ]);
    if (local && remote && Math.abs(local - remote) !== trSpacing) {
      // trSpacing change breaks selected freqs, so reset
      return true;
    }
  }

  return false;
}

/**
 * Expands the range of frequencies denoted by min and max into a
 * contiguous list of frequencies with a gap of "stepSize"
 */
export function getCalculatedList(min, max, stepSize) {
  const rMin = pyround(min, 3);
  let result = [rMin];
  let calculatedValue = pyround(rMin + stepSize / 1000, 3);
  while (calculatedValue <= max) {
    result.push(calculatedValue);
    calculatedValue = pyround(calculatedValue + (stepSize / 1000), 3);
  }
  return result;
}

export function buildFrequencyList(frequencies: any[], stepSize: number) {
  return frequencies
    .map(([low, high]) => getCalculatedList(low, high, stepSize))
    .flat(1);
}

export function initializeLists(frequencyListData) {
  if (frequencyListData == null) {
    return [];
  }

  return frequencyListData.frequencies.map((pathData) => {
    const {
      local,
      remote,
      step_size: stepSize,
      label,
      tr_spacing: trSpacing,
      min_tr_spacing: minTrSpacing,
    } = pathData;

    // both will be true or false at the same time
    // in the case of fixed=true, local.frequencies === remote.frequencies
    let localList, remoteList;
    if (local.fixed && remote.fixed) {
      localList = local.frequencies.map((f) => pyround(f, 3));
      remoteList = remote.frequencies.map((f) => pyround(f, 3));
    } else {
      localList = buildFrequencyList(local.frequencies, stepSize);
      remoteList = buildFrequencyList(remote.frequencies, stepSize);
    }

    return {
      label,
      trSpacing,
      minTrSpacing,
      local: {
        list: localList,
        label: local.label,
        hilo: local.hilo,
        fixed: local.fixed,
      },
      remote: {
        list: remoteList,
        label: remote.label,
        hilo: remote.hilo,
        fixed: remote.fixed,
      },
    };
  });
}

export function useFrequencyState(initialPath) {
  const { watch } = useFormContext();
  const [localRadios, remoteRadios] = watch(['local.radios', 'remote.radios']);

  const TOGGLE_POPUP = 'TOGGLE_POPUP';
  const CLOSE_POPUP = 'CLOSE_POPUP';
  const SET_BOTH_VALUES = 'SET_BOTH_VALUES';
  const SET_ALL_VALUES = 'SET_ALL_VALUES';
  const CHANGE_PATHS_SIZE = 'CHANGE_PATHS_SIZE';

  function reducer(state, action) {
    switch (action.type) {
      case TOGGLE_POPUP:
        if (!state.showPopup) {
          // about to display modal
          let paths = [];
          for (let i = 0; i < localRadios.length; i++) {
            paths.push({
              local: { value: localRadios[i].frequency.tx_frequency },
              remote: { value: remoteRadios[i].frequency.tx_frequency },
            });
          }
          return { paths, showPopup: true };
        } else {
          return { ...state, showPopup: false };
        }
      case CLOSE_POPUP:
        return { ...state, showPopup: false };
      case SET_ALL_VALUES:
        for (let path of state.paths) {
          path.local.value = action.value;
          path.remote.value = action.value;
        }
        return { ...state };
      case SET_BOTH_VALUES:
        state.paths[action.idx].local.value = action.local;
        state.paths[action.idx].remote.value = action.remote;
        return { ...state };
      case CHANGE_PATHS_SIZE:
        return {
          ...state,
          paths: range(action.size).map((i) => {
            return {
              local: {
                value: state.paths[i]?.local.value,
              },
              remote: {
                value: state.paths[i]?.remote.value,
              },
            }
          }),
        };
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    showPopup: false,
    paths: zip(initialPath.local.radios, initialPath.remote.radios).map(
      ([localRadio, remoteRadio]) => {
        return {
          local: {
            value: localRadio.frequency.tx_frequency,
          },
          remote: {
            value: remoteRadio.frequency.tx_frequency,
          },
        };
      }
    ),
  });

  useEffect(() => {
    // if radio lists change, update local state to match
    dispatch({ type: CHANGE_PATHS_SIZE, size: localRadios.length });
  }, [localRadios, remoteRadios]);

  function togglePopup() {
    dispatch({ type: TOGGLE_POPUP });
  }

  function closePopup() {
    dispatch({ type: CLOSE_POPUP });
  }

  function setBothValues(idx, local, remote) {
    dispatch({ type: SET_BOTH_VALUES, idx, local, remote });
  }

  function setAllValues(value) {
    dispatch({ type: SET_ALL_VALUES, value });
  }

  return [
    state,
    {
      togglePopup,
      closePopup,
      setBothValues,
      setAllValues,
    },
  ];
}

export function getOppositeValue(value, trSpacing, sign) {
  // sign is either +1 or -1
  return pyround(value + sign * trSpacing, 3);
}

export function selectionHandler(e, state, endName, idx, pathFrequencyData, hilo) {
  const isHi = hilo.toLowerCase() === endName;
  const sign = isHi ? -1 : +1;
  const isLocal = endName === 'local';
  const otherEnd = isLocal ? 'remote' : 'local';

  const value = parseFloat(e.target.value);

  let opposite;
  if (pathFrequencyData.trSpacing === 'Any') {
    if (isLocal) {
      opposite = state.paths[idx].remote.value;
    } else {
      opposite = state.paths[idx].local.value;
    }
  } else {
    opposite = getOppositeValue(value, pathFrequencyData.trSpacing, sign);

    if (!pathFrequencyData[otherEnd].list.includes(opposite)) {
      opposite = null;
    }
  }

  let localValue, remoteValue;
  if (isLocal) {
    localValue = value;
    remoteValue = opposite;
  } else {
    localValue = opposite;
    remoteValue = value;
  }

  return [localValue, remoteValue];
}

function doGetWarning(statePath, pathFrequencyData) {
  const localValue = statePath.local.value;
  const remoteValue = statePath.remote.value;

  let warning = null;
  if (pathFrequencyData.trSpacing !== 'Any') {
    if (
      (localValue == null && remoteValue != null) ||
      (localValue != null && remoteValue == null)
    ) {
      warning =
        'Error: Unable to determine the frequency pair. Please select an alternative frequency.';
    }
  } else if (localValue != null && remoteValue != null) {
    const { minTrSpacing } = pathFrequencyData;
    if (
      minTrSpacing != null &&
      Math.abs(localValue - remoteValue) < minTrSpacing
    ) {
      // minimum spacing between freqs warning
      warning = `Error: The centre frequency values must be a minimum of ${minTrSpacing} MHz apart.`;
    } else if (
      checkInvalidFreqs(pathFrequencyData.local.hilo, localValue, remoteValue)
    ) {
      // lo freq exceeds hi freq warning
      warning =
        'The center frequencies are invalid for your current Hi/Lo setting.';
    }
  }

  return warning;
}

export function getWarning(state) {
  return (pathFreq, idx) => {
    if (state.paths[idx] == null) {
      // can happen when changing link kind
      return null;
    }
    return doGetWarning(state.paths[idx], pathFreq);
  };
}

export function missingValue(state) {
  return (idx) => {
    const { local, remote } = state.paths[idx];
    return (
      (local.value != null && remote.value == null) ||
      (local.value == null && remote.value != null)
    );
  };
}

export function checkSelected(state, frequencyLists) {
  let values = [];
  for (let i = 0; i < frequencyLists.length; i++) {
    if (state.paths[i] == null) {
      // can happen when changing link kind
      break;
    }
    values.push([state.paths[i].local.value, state.paths[i].remote.value]);
  }
  values = values.flat();

  return !(
    values.every((v) => v != null) /* all selected */
    || values.every((v) => v == null) /* none selected */
  );
}

export function clearAllValues(getValues, setValue, setAllValues, resetForm) {
  // clear form state
  if (resetForm) {
    const [localRadios, remoteRadios] = getValues(['local.radios', 'remote.radios']);
    for (let i = 0; i < localRadios.length; i++) {
      setValue(`local.radios.${i}.frequency.tx_frequency`, null, {
        shouldDirty: true,
      });
    }
    for (let i = 0; i < remoteRadios.length; i++) {
      setValue(`remote.radios.${i}.frequency.tx_frequency`, null, {
        shouldDirty: true,
      });
    }
  }

  // clear "local" state
  setAllValues(null);
}
