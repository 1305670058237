import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import useDebounce from '../../hooks/useDebounce';
import * as Yup from 'yup';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Formik, Form as FormFormik, Field } from 'formik';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { getWithAuth, postWithAuth } from '../../api';
import { polygon, point } from '@turf/helpers';
import SemanticField from '../../components/controls/SemanticField';
import additionalMessages from '../../messages';
import { Modal, Button, Form, Message, Grid } from 'semantic-ui-react';
import {
  getDropdownOptions,
  trimObject,
  getSelectOptions,
  sortObjectsByKey,
} from '../../utils/useful_functions';

import filterWorker from '../../workers/filter.worker';
import { distanceBetweenTwoPoints } from './aplayout/AccessPointProperties';
import { MIN_LINK_LENGTH_M } from 'src/app.constants';

const getSmRegistrationLimit = (apId, projectId, setter) => {
  getWithAuth(`project/${projectId}/access_point/${apId}`).then((ap) => {
    let limit = 0;
    ap.radios.forEach((radio) => {
      if (radio.equipment.sm_registration_limit) {
        limit += parseInt(radio.equipment.sm_registration_limit);
      }
    });
    setter(limit);
  });
};

function AttachSubscribersProject(props) {
  const { formatMessage } = props.intl;
  const [open, setOpen] = useState(true);
  const [spFilter, setSPFilter] = useState('');
  const [errMsg, setErrMsg] = useState(null);
  const [unconnectedSM, setUnconnectedSM] = useState([]);
  const [smRegistrationLimit, setSmRegistrationLimit] = useState(0);
  const { projectId, subscriberSites, accessPoints } = useSelector(
    (state) => state.mainFrame
  );
  const accessPointProperties = accessPoints.features.map(
    (obj) => obj.properties
  );
  let apOptions = getSelectOptions(accessPointProperties);
  apOptions = sortObjectsByKey(apOptions, 'text');
  const [apId, setApId] = useState(
    props.apId ? props.apId : apOptions[0]['key']
  );

  const { validateSubscriberSites } = props;

  // Fetch the AP and get the SM Registration Limit
  useEffect(() => {
    getSmRegistrationLimit(apId, projectId, setSmRegistrationLimit);
  }, [apId]);

  const accessPointProperty = accessPointProperties.find(
    (item) => item.id === apId
  );
  const unconnectedSubscribers =
    accessPointProperty?.unconnected_subscribers || 0;
  const connectedSubscribers = accessPointProperty?.connected_subscribers || 0;
  const allSubscribers = connectedSubscribers + unconnectedSubscribers;
  let apName = null;
  if (props.apId) {
    const apObject = apOptions.filter((ap) => ap.key === apId);
    apName = get(apObject, '0.text');
  }
  const getSPInRange = (apId) => {
    const sps = [];
    const selectedAp = accessPoints.features.filter(
      (ap) => ap.properties.id === apId
    )[0];
    const apGeom = selectedAp.geometry;
    const apProperties = selectedAp.properties;
    const antennaLat = get(apProperties, 'radios.0.antennas.0.latitude');
    const antennaLng = get(apProperties, 'radios.0.antennas.0.longitude');
    let apLat = antennaLat != null ? antennaLat : apProperties.latitude;
    let apLng = antennaLng != null ? antennaLng : apProperties.longitude;
    const selectedApPolygon = polygon(apGeom.coordinates);
    subscriberSites.features.forEach((sp) => {
      const { properties, geometry } = sp;
      if (
        booleanPointInPolygon(point(geometry.coordinates), selectedApPolygon) &&
        distanceBetweenTwoPoints(
          {
            latitude: apLat,
            longitude: apLng,
          },
          {
            latitude: geometry.coordinates[1],
            longitude: geometry.coordinates[0],
          }
        ) > MIN_LINK_LENGTH_M
      ) {
        const spObj = { ...properties, ...geometry };
        sps.push(spObj);
      }
    });
    return sps;
  };
  const [spsInRange, setSPSInRange] = useState(getSPInRange(apId));

  useEffect(() => {
    if (spsInRange.length === 0) {
      setErrMsg(formatMessage(additionalMessages.noSMsInRange));
    }
    if (spsInRange.length) {
      const ids = spsInRange.map((obj) => obj.id);
      postWithAuth(`project/${projectId}/sites/subscriber/unconnected`, ids)
        .then((res) => setUnconnectedSM(res))
        .catch((err) => console.error('not able to fetch unconnected sites'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apId, projectId, spsInRange]);

  const [filteredItems, setFilteredItems] = useState([]);
  const debounceFilter = useDebounce(spFilter, 500);
  const onCloseHandler = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    const worker = new filterWorker();

    worker.onmessage = (m) => {
      const results = JSON.parse(m.data);
      setFilteredItems(results);
    };

    worker.postMessage(
      JSON.stringify({
        command: 'filter',
        items: spsInRange,
        filter: debounceFilter,
      })
    );
    return () => {
      if (worker) {
        worker.terminate();
      }
    };
  }, [debounceFilter, spsInRange]);

  const spOptions = getDropdownOptions(filteredItems);
  const initialValues = {
    apIds: apId,
    spIds: unconnectedSM,
  };
  const validationSchema = Yup.object({
    spIds: Yup.array()
      .of(Yup.string())
      .required(
        formatMessage(additionalMessages.requiredSelectError, {
          fieldName: 'subscriber sites',
        })
      ),
  });
  const onSubmit = (values, { setSubmitting }) => {
    trimObject(values);
    let data = {
      site_ids: values.spIds.map((id) => id),
      ap_id: apId,
    };

    postWithAuth(`project/${projectId}/subscribers`, data)
      .then((response) => {
        onCloseHandler();
        if (props.successCallback) {
          props.successCallback();
        }
      })
      .catch((err) => {
        setSubmitting(false);
        setErrMsg(err.message ?? err.detail);
      });
  };

  return (
    <Modal
      onClose={onCloseHandler}
      size="tiny"
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>
        {apName != null ? (
          `Attach Subscribers to ${apName}`
        ) : (
          <FormattedMessage
            id="accessPoints.attachSP"
            defaultMessage="Attach Subscribers"
          />
        )}
        <Button
          circular
          icon="close"
          title={formatMessage(additionalMessages.close)}
          floated="right"
          onClick={onCloseHandler}
        />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            return (
              <FormFormik className="ui form">
                <Grid stretched>
                  {!props.apId && (
                    <Grid.Row>
                      <Grid.Column>
                        <SemanticField
                          fluid
                          search
                          selection
                          label={formatMessage(additionalMessages.accessPoints)}
                          component={Form.Dropdown}
                          options={apOptions}
                          onChange={(e, data) => {
                            formik.setFieldValue('spIds', []);
                            const sps = getSPInRange(data.value);
                            setApId(data.value);
                            setSPSInRange(sps);
                            //setFilteredItems(sps);
                            setErrMsg(null);
                          }}
                          name="apIds"
                          id="apIds"
                        ></SemanticField>
                        {formik.errors.apIds ? (
                          <Message className="flex-grow-0" negative>
                            <p>{formik.errors.apIds}</p>
                          </Message>
                        ) : null}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    <Grid.Column style={{ height: '40vh' }}>
                      <Form.Input
                        className="flex-grow-0"
                        label={formatMessage(
                          additionalMessages.subscriberSites
                        )}
                        placeholder={formatMessage(additionalMessages.filter)}
                        fluid
                        icon={{
                          name: 'cancel',
                          link: true,
                          onClick: () => {
                            formik.setFieldValue('spIds', []);
                            setSPFilter('');
                          },
                          title: formatMessage(additionalMessages.clearFilter),
                        }}
                        value={spFilter}
                        onChange={(e) => {
                          setSPFilter(e.target.value);
                          formik.setFieldValue('spIds', []);
                        }}
                        autoFocus
                      ></Form.Input>
                      <Field
                        as="select"
                        name="spIds"
                        validate={validateSubscriberSites}
                        multiple
                      >
                        {spOptions}
                      </Field>
                      {formik.values.spIds.length + allSubscribers >
                      smRegistrationLimit ? (
                        <Message className="flex-grow-0" negative>
                          <p>
                            {formatMessage(
                              additionalMessages.apLinksToSMRegistrationLimitError,
                              {
                                totalNumOfSubscribers:
                                  formik.values.spIds.length + allSubscribers,
                                numOfSupportedSubscribers: smRegistrationLimit,
                              }
                            )}
                          </p>
                        </Message>
                      ) : null}
                      {formik.errors.spIds && formik.values.spIds.length ? (
                        <Message className="flex-grow-0" negative>
                          <p>{formik.errors.spIds}</p>
                        </Message>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div className="modal-actions">
                  <Button onClick={onCloseHandler}>
                    {formatMessage(additionalMessages.cancel)}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!formik.isValid || !formik.values.spIds.length}
                    loading={formik.isSubmitting}
                    color="blue"
                  >
                    {formatMessage(additionalMessages.ok)}
                  </Button>
                </div>
              </FormFormik>
            );
          }}
        </Formik>
      </Modal.Content>
      {errMsg && (
        <Message negative attached>
          <Message.Header>
            {formatMessage(additionalMessages.attachSMError)}
          </Message.Header>
          <p>{errMsg}</p>
        </Message>
      )}
    </Modal>
  );
}

export default injectIntl(AttachSubscribersProject);
