const noiseDensityDef = -114;
export const bands = {
  '80 GHz': { normFreq: 78500.0, noiseDensity: noiseDensityDef },
  '60 GHz': { normFreq: 62640.0, noiseDensity: noiseDensityDef },
  '38 GHz': { normFreq: 38500.0, noiseDensity: noiseDensityDef },
  '32 GHz': { normFreq: 32600.0, noiseDensity: noiseDensityDef },
  '28 GHz': { normFreq: 28500.0, noiseDensity: noiseDensityDef },
  '26 GHz': { normFreq: 25375.0, noiseDensity: noiseDensityDef },
  '24 GHz': { normFreq: 24125.0, noiseDensity: noiseDensityDef },
  '23 GHz': { normFreq: 22400.0, noiseDensity: noiseDensityDef },
  '18 GHz': { normFreq: 18700.0, noiseDensity: noiseDensityDef },
  '15 GHz': { normFreq: 14900.0, noiseDensity: noiseDensityDef },
  '13 GHz': { normFreq: 13000.0, noiseDensity: noiseDensityDef },
  '11 GHz': { normFreq: 11200.0, noiseDensity: noiseDensityDef },
  '10 GHz': { normFreq: 10340.0, noiseDensity: noiseDensityDef },
  '8 GHz': { normFreq: 8112.5, noiseDensity: noiseDensityDef },
  '7 GHz': { normFreq: 7500.0, noiseDensity: noiseDensityDef },
  'Upper 6 GHz': { normFreq: 6750.0, noiseDensity: noiseDensityDef },
  'Lower 6 GHz': { normFreq: 6175.0, noiseDensity: noiseDensityDef },
  '5.9 GHz': { normFreq: 5902.5, noiseDensity: -104 },
  '5.8 GHz': { normFreq: 5800.0, noiseDensity: -90 },
  '5.4 GHz': { normFreq: 5597.5, noiseDensity: -104 },
  '5.3 GHz': { normFreq: 5410.0, noiseDensity: -104 },
  '5.2 GHz': { normFreq: 5300.0, noiseDensity: -104 },
  '5.1 GHz': { normFreq: 5200.0, noiseDensity: -104 },
  '4.9 GHz': { normFreq: 4950.0, noiseDensity: -104 },
  '4.8 GHz': { normFreq: 4850.0, noiseDensity: -104 },
  '4.7 GHz': { normFreq: 4700.0, noiseDensity: -104 },
  '3.9 GHz': { normFreq: 3940.0, noiseDensity: noiseDensityDef },
  '3.6 GHz': { normFreq: 3675.0, noiseDensity: noiseDensityDef },
  '3.5 GHz': { normFreq: 3475.0, noiseDensity: noiseDensityDef },
  '2.6 GHz': { normFreq: (2570 + 2620) / 2.0, noiseDensity: noiseDensityDef },
  '2.5 GHz': { normFreq: 2592.5, noiseDensity: noiseDensityDef },
  '2.4 GHz': { normFreq: 2441.75, noiseDensity: noiseDensityDef },
  '2.3 GHz': { normFreq: (2300 + 2400) / 2.0, noiseDensity: noiseDensityDef },
  '1.4 GHz': { normFreq: 1429.5, noiseDensity: noiseDensityDef },
  '900 MHz': { normFreq: 931.0, noiseDensity: -85 },
  '700 MHz': { normFreq: 772.5, noiseDensity: noiseDensityDef },
  'UHF 450-470 MHz:': { normFreq: 460.0, noiseDensity: noiseDensityDef },
  'UHF 430-450 MHz': { normFreq: 440.0, noiseDensity: noiseDensityDef },
  'UHF 406-430 MHz': { normFreq: 418.0, noiseDensity: noiseDensityDef },
};

export const NOMINAL_FREQUENCY_MAP = Object.fromEntries(
  Object.keys(bands).map((band) => [band, bands[band]['normFreq']])
);

export const VALID_PTP_BANDS = [
  '80 GHz',
  '60 GHz',
  '38 GHz',
  '32 GHz',
  '28 GHz',
  '26 GHz',
  '24 GHz',
  '23 GHz',
  '18 GHz',
  '15 GHz',
  '13 GHz',
  '11 GHz',
  '10 GHz',
  '8 GHz',
  '7 GHz',
  'Upper 6 GHz',
  'Lower 6 GHz',
  '5.9 GHz',
  '5.8 GHz',
  '5.4 GHz',
  '5.3 GHz',
  '5.2 GHz',
  '5.1 GHz',
  '4.9 GHz',
  '4.8 GHz',
  '3.6 GHz',
  '3.5 GHz',
  '2.5 GHz',
  '2.4 GHz',
  '900 MHz',
];

export const VALID_PTP_CUSTOM_ANTENNA_BANDS = [
  'Upper 6 GHz',
  'Lower 6 GHz',
  '5.9 GHz',
  '5.8 GHz',
  '5.4 GHz',
  '5.3 GHz',
  '5.2 GHz',
  '5.1 GHz',
  '4.9 GHz',
  '4.8 GHz',
  '3.6 GHz',
  '3.5 GHz',
  '2.5 GHz',
  '2.4 GHz',
  '900 MHz',
];

export const VALID_SM_CUSTOM_ANTENNA_BANDS = [
  'Upper 6 GHz',
  'Lower 6 GHz',
  '5.9 GHz',
  '5.8 GHz',
  '5.4 GHz',
  '5.3 GHz',
  '5.2 GHz',
  '5.1 GHz',
  '4.9 GHz',
  '3.6 GHz',
  '3.5 GHz',
  '2.5 GHz',
  '2.4 GHz',
  '1.4 GHz',
  '900 MHz',
  '700 MHz',
  'UHF 450-470 MHz',
  'UHF 430-450 MHz',
  'UHF 406-430 MHz',
];

export const VALID_PMP_BANDS = [
  '60 GHz',
  '28 GHz',
  '26 GHz',
  'Upper 6 GHz',
  'Lower 6 GHz',
  '5.9 GHz',
  '5.8 GHz',
  '5.4 GHz',
  '5.3 GHz',
  '5.2 GHz',
  '5.1 GHz',
  '4.9 GHz',
  '3.9 GHz',
  '3.6 GHz',
  '3.5 GHz',
  '2.5 GHz',
  '2.4 GHz',
  '1.4 GHz',
  '900 MHz',
  '700 MHz',
  'UHF 450-470 MHz',
  'UHF 430-450 MHz',
  'UHF 406-430 MHz',
];
