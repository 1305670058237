import mainFrameReducer from './pages/mainframe/mainframe.reducer';
import socketReducer from './reducers/socket_reducer';
// imports below must come after main_frame_reducer in import order
import sitesReducer from './pages/sites/site.reducer';
import equipmentReducer from './pages/equipment/equipment.reducer';
import ptpReducer from './pages/ptp/ptp.reducer';
import pmpReducer from './pages/pmp/pmp.reducer';
import mapReducer from './pages/map/map.reducer';
import viewshedReducer from './pages/viewshed/viewshed.reducer';
import bestServerReducer from './pages/best_server/best_server.reducer';
import meshReducer from './pages/mesh/mesh.reducer';
import profileReducer from './components/profilechart/profile.reducer';
import calcTiltReducer from './reducers/calc_tilt.reducer';
import antennaReducer from './pages/antennas/antenna.reducer';
import headermenuReducer from './pages/mainframe/headermenu.reducer';

export const linkplannerReducers = (history) => {
  return {
    mainFrame: mainFrameReducer,
    sockets: socketReducer,
    sites: sitesReducer,
    ptp: ptpReducer,
    pmp: pmpReducer,
    equipment: equipmentReducer,
    map: mapReducer,
    viewsheds: viewshedReducer,
    bestServer: bestServerReducer,
    mesh: meshReducer,
    profile: profileReducer,
    calcTilt: calcTiltReducer,
    antenna: antennaReducer,
    headerMenu: headermenuReducer,
  };
};
