import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import MenuItem from './MenuItem';
import { nameSort } from 'src/utils/naming';

export const NetworkSiteMenuItem = ({ expandSidebar }) => {
  const networkSitesAll = useSelector((state) => state.mainFrame.networkSites);
  if (!networkSitesAll) {
    return null;
  }
  const networkSites = [...networkSitesAll.features];
  networkSites.sort(nameSort);
  const label = expandSidebar ? (
    <FormattedMessage
      id="mainMenu.networkSites"
      defaultMessage="Network Sites"
      description="Network Sites main menu command"
    />
  ) : null;

  return (
    <MenuItem
      label={label}
      expandSidebar={expandSidebar}
      kind="network_site"
      path="network_sites"
      headerIconClass="site-icon"
      listIconClass="network-site-menu-icon"
      listItems={networkSites}
    ></MenuItem>
  );
};
