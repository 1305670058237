import socket from '../../sockets';
import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  saveAccessPoint,
  deleteSelectedAps,
  fetchAccessPoints,
  getSubscriberGeometry,
  getMeshLinksGeometry,
} from '../pmp/pmp.reducer';
import {
  getLocalJSON,
  getSessionJSON,
  setSessionJSON,
} from '../../utils/useful_functions';
import { getCanvas } from '../../utils/mapUtils';
import { lineString, point, polygon } from '@turf/helpers';
import { getAPCoords } from '../../utils/useful_functions';
import {
  getWithAuth,
  linkFeatureCollection,
  siteFeatureCollection,
} from '../../api';
import { DEFAULT_CLUTTER_DETAILS } from '../../app.constants';
import { states } from '../map/leafletCanvas';
import { queryClient } from '../../query-client';

export const getProjects = createAction('mainFrame/getProjects');
export const tokenExpired = createAction('mainFrame/tokenExpired');

export const fetchSites = createAsyncThunk(
  'mainFrame/fetchSites',
  async (projectId: string) => {
    const network = getWithAuth(`project/${projectId}/sites/network/geometry`);
    const subscriber = getWithAuth(
      `project/${projectId}/sites/subscriber/geometry`
    );
    return Promise.allSettled([network, subscriber]);
  }
);

const DEFAULT_PREFS = {
  rangeUnits: 'km',
  heightUnits: 'm',
  latLngFormat: 'deg (ddd.dddddP)',
  macAddressFormat: '00:00:00:00:00:00',
  bom: {
    order: [
      'Argentina',
      'Australia',
      'Brazil',
      'China',
      'EU',
      'India',
      'Israel',
      'South Africa',
      'UK',
      'US',
    ],
    checked: {
      Argentina: false,
      Australia: false,
      Brazil: false,
      China: false,
      EU: false,
      India: false,
      Israel: false,
      'South Africa': false,
      UK: false,
      US: false,
    },
  },
};

const ACCOUNT_ATTRS = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'companyName',
  'language',
  'optIn',
];

const PREF_ATTRS = [
  'rangeUnits',
  'heightUnits',
  'latLngFormat',
  'macAddressFormat',
  'bom',
];

const getStoredPrefs = () => {
  return Object.fromEntries(
    PREF_ATTRS.map((k) => {
      const sessionValue = sessionStorage.getItem(`cn.lp.prefs.${k}`);
      const value = sessionValue || DEFAULT_PREFS[k];
      if (k === 'bom' && sessionValue != null) {
        // only try to json parse when value is not from default prefs
        return [k, JSON.parse(value)];
      }
      return [k, value];
    })
  );
};

/*
 * Save the user account and prefs info to the store and to the sessionstorage.
 *
 * The payload may contain the prefs as top level attributes or they could
 * be under a separate "prefs" level.
 */
const saveAccountState = (state, payload) => {
  ACCOUNT_ATTRS.forEach((k) => {
    const value = payload[k] && payload[k] !== 'null' ? payload[k] : '';
    if (k === 'optIn') {
      // optIn should be a bool so we don't want to use the stringified value
      // since this will cause problems on subsequent submissions
      state[k] = payload[k];
    } else {
      sessionStorage.setItem(`cn.lp.${k}`, value);
      state[k] = value;
    }
  });

  // login gives a separate attribute for prefs, but we don't have
  // that when the user updates the prefs in the session
  const prefs = payload.prefs ? payload.prefs : payload;
  PREF_ATTRS.forEach((k) => {
    let value = prefs[k] && prefs[k] !== 'null' ? prefs[k] : state.prefs[k];
    state.prefs[k] = value;
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(`cn.lp.prefs.${k}`, value);
  });
  return state;
};

const defaultGeneralProjectProps = {
  customerName: null,
  companyName: null,
  address: null,
  email: null,
  phone: null,
  cellPhone: null,
  description: null,
};

/*
 * Reconnect socket connections if the browser refreshes
 */
const storedUserId = sessionStorage.getItem('cn.lp.id') || null;
if (storedUserId) {
  // Listen for WebSocket messages specific to the user
  socket.enterUserRoom(storedUserId);
}

const storedProjectId = sessionStorage.getItem('cn.lp.projectId');
if (storedProjectId) {
  // Listen for WebSocket messages on the project
  socket.enterProjectRoom(storedProjectId);
}

function defaultPmpTab() {
  const pathname = window?.location?.pathname;
  if (pathname === '/aps' || pathname === '/mesh' || pathname === '/subscribers') {
    return pathname;
  }
  return '/aps';
}

const initialState: any = {
  apiVersion: undefined,
  hasUpdated: getLocalJSON('cn.lp.hasUpdated', false),
  isUpdating: false,
  permissionRead: true,
  permissionWrite: false,
  // this is a temporary hack to work-around another hack.
  // When a long running process is started, we set permissionWrite
  // to False. As a result, we don't know if the user really has write
  // permission on the file. This flag should only be modified
  // when the file is loaded and is nothing to do with the running tasks.
  hasWritePermission: false,
  permissionAdmin: false,
  projectLoaded: false,
  projectPermissions: null,
  userLimits: null,
  allowedFeatures: null,
  locked: false,
  expandSidebar: getSessionJSON(
    'cn.lp.expandSidebar',
    window.innerWidth >= 600
  ),
  filterOnMapBounds: false,
  mapState: states.MAP_SELECT,
  mapBounds: null,
  confirm: false,
  confirmPayload: null,
  projectModified: false,
  anpJobStatus: { status: 'new', message: '' },
  projectId: storedProjectId,
  projectRefId: null,
  projectName: sessionStorage.getItem('cn.lp.projectName'),
  loggedIn: sessionStorage.getItem('cn.lp.loggedIn') === 'true' || false,
  email: sessionStorage.getItem('cn.lp.email') || '',
  userId: storedUserId,
  firstName: sessionStorage.getItem('cn.lp.firstName') || '',
  lastName: sessionStorage.getItem('cn.lp.lastName') || '',
  companyName: sessionStorage.getItem('cn.lp.companyName') || '',
  phone: sessionStorage.getItem('cn.lp.phone') || '',
  language: sessionStorage.getItem('cn.lp.language') || 'en',
  optIn: true,
  prefs: getStoredPrefs(),
  projects: [],
  selectedPMPItems: null,
  selectedMeshItems: null,
  selectedPTPItems: null,
  // geometric representations only
  networkSites: null,
  subscriberSites: null,
  accessPoints: null,
  ptpLinks: null,
  pmpLinks: null,
  meshLinks: null,
  networkSiteCount: 0,
  subscriberSiteCount: 0,
  ptpLinksCount: 0,
  accessPointsCount: 0,
  pmpLinksCount: 0,
  meshLinksCount: 0,
  viewshedsCount: 0,
  useClutter: true,
  clutterDetails: DEFAULT_CLUTTER_DETAILS,
  predictionModel: null,
  generalProjectProps: defaultGeneralProjectProps,
  connected: true,
  showHelp: true,
  loadingProject: false,
  warning: null,
  showLPImportDialog: false,
  createMapTileIndex: null,
  needsRefresh: {
    eqAccessPoint: false,
    eqSubscriberList: false,
    accessPointPanel: false,
    subscriberPanel: false,
    pmpLinksPanel: false,
    meshLinksPanel: false,
    pmpNDPanel: false,
    subscriberSitePanel: false,
    networkSitePanel: false,
    meshLinkPanel: false,
    ptpLinkPanel: false,
    ptpLinksTable: false,
  },
  // keys look like <kind>-<id>, e.g. access_point-3
  dirtyObjects: {},
  termsVersion: undefined,
  superuser: getSessionJSON('cn.lp.superuser', false),
  navigate: () => {},
  bulkEditSelectedRows: [],
  accessPointName: null,
  subscriberName: null,
  importingAnp: false,
  preventDiscard: null,
  activePerformanceDetailsTabView: 0,
  pmpSelectedTableTab: defaultPmpTab(),
};

const doUnloadProject = (state) => {
  sessionStorage.removeItem('cn.lp.projectId');
  sessionStorage.removeItem('cn.lp.projectName');

  state.permissionRead = true;
  state.permissionWrite = false;
  state.hasWritePermission = false;
  state.permissionAdmin = false;
  state.warning = null;
  state.projectModified = false;
  state.projectId = null;
  state.projectRefId = null;
  state.projects = [];
  state.networkSites = null;
  state.subscriberSites = null;
  state.ptpLinks = null;
  state.pmpLinks = null;
  state.meshLinks = null;
  state.accessPoints = null;
  state.projectName = null;
  state.networkSiteCount = 0;
  state.subscriberSiteCount = 0;
  state.ptpLinksCount = 0;
  state.pmpLinksCount = 0;
  state.meshLinksCount = 0;
  state.accessPointsCount = 0;
  state.anpJobStatus = { status: 'new', message: '' };
};

const doLogout = (state) => {
  socket.disconnect(false);
  sessionStorage.clear();
  doUnloadProject(state);
  state.connected = false;
  sessionStorage.setItem('cn.lp.loggedIn', 'false');
  sessionStorage.removeItem('cn.lp.superuser');
  state.loggedIn = false;
  state.superuser = false;
  state.email = '';
  state.userId = '';
  state.mapState = states.MAP_SELECT;
};

const updateMap = (state) => {
  // This hack is required to convert the proxy feature objects
  // back into real feature instances so that the map tiles work
  // as expected.
  state.networkSites = {
    type: 'FeatureCollection',
    features: state.networkSites.features.map((f) => Object.assign({}, f)),
  };
  state.subscriberSites = {
    type: 'FeatureCollection',
    features: state.subscriberSites.features.map((f) => Object.assign({}, f)),
  };
  state.ptpLinks = {
    type: 'FeatureCollection',
    features: state.ptpLinks.features.map((f) => Object.assign({}, f)),
  };
  state.accessPoints = {
    type: 'FeatureCollection',
    features: state.accessPoints.features.map((f) => Object.assign({}, f)),
  };
  state.meshLinks = {
    type: 'FeatureCollection',
    features: state.meshLinks.features.map((f) => Object.assign({}, f)),
  };
  state.pmpLinks = {
    type: 'FeatureCollection',
    features: state.pmpLinks.features.map((f) => Object.assign({}, f)),
  };
  // display the features in the map
  console.debug('Redrawing map');
  getCanvas().display(
    state.networkSites,
    state.subscriberSites,
    state.ptpLinks,
    state.accessPoints,
    state.meshLinks,
    state.pmpLinks,
    false
  );
};

export const getProjectPermissions = createAsyncThunk(
  'mainFrame/getProjectPermissions',
  async (projectId) => {
    return await getWithAuth(`project/${projectId}/permissions/all`);
  }
);

export const getUserLimits = createAsyncThunk(
  'mainFrame/getUserLimits',
  async () => {
    return await getWithAuth(`user/limits`);
  }
);

export const getAllowedFeatures = createAsyncThunk(
  'mainFrame/getAllowedFeatures',
  async () => {
    return await getWithAuth(`user/features`);
  }
);

// NOTE some of the reducers may be better created as external actions
// using createAction and placed into extraReducers, so that we dont
// have to import mainFrame in standalone modules like useful_functions,
// sagas, etc.
const mainFrameSlice = createSlice({
  name: 'mainFrame',
  initialState,
  reducers: {
    uiSet: (state, action) => {
      Object.assign(state, action.payload);
    },
    updateProjectsList: (state) => {
      state.projects.filter(
        (prj) => prj.project.name === state.projectName
      )[0].project.modified = state.projectModified;
    },
    uiToggleSidebar: (state) => {
      state.expandSidebar = !state.expandSidebar;
      setSessionJSON('cn.lp.expandSidebar', state.expandSidebar);
    },
    uiCollapseSidebar: (state) => {
      state.expandSidebar = false;
      setSessionJSON('cn.lp.expandSidebar', state.expandSidebar);
    },
    uiExpandSidebar: (state) => {
      state.expandSidebar = true;
      setSessionJSON('cn.lp.expandSidebar', state.expandSidebar);
    },
    uiConfirmAction: (state, action) => {
      state.confirm = true;
      state.confirmPayload = action.payload;
    },
    uiConfirmCancel: (state) => {
      state.confirm = false;
      state.confirmPayload = null;
    },
    uiConfirmOk: (state) => {
      state.confirm = false;
      state.confirmPayload = null;
    },
    userLogin: (state, action) => {
      const { payload } = action;
      sessionStorage.setItem('cn.lp.loggedIn', 'true');
      sessionStorage.setItem('cn.lp.email', payload.email);
      sessionStorage.setItem('cn.lp.id', payload.id);
      state.optIn = payload.optIn;
      saveAccountState(state, payload);
      state.loggedIn = true;
      state.userId = payload.id;
      state.termsVersion = payload.termsVersion;
      state.superuser = payload.superuser;
      if (payload.superuser) {
        setSessionJSON('cn.lp.superuser', true);
      }
    },
    updateTermsNConditions: (state, action) => {
      const { payload } = action;
      state.optIn = payload.optIn;
      state.termsVersion = payload.termsVersion;
    },
    setTermsVersion: (state, action) => {
      state.termsVersion = action.payload;
    },
    updatePreferences: (state, action) => {
      const { payload } = action;
      saveAccountState(state, payload);
    },
    userLogout: (state) => doLogout(state),
    tokenRefresh: (state, action) => {
      // no-op, but sagas uses this to run pending callbacks
    },
    unloadProject: (state) => doUnloadProject(state),
    loadProject: (state, action) => {
      const { payload } = action;
      Object.assign(state, {
        permissionRead: true,
        permissionWrite: false,
        hasWritePermission: false,
        permissionAdmin: false,
        projectModified: false,
        projectRefId: payload.projectRefId,
        projectId: payload.projectId,
        projectName: payload.projectName,
        loadingProject: payload.projectId !== state.projectId,
      });

      const canvas = getCanvas();
      canvas?.removeAllViewsheds();
    },
    recalculateProject: (state, action) => {
      const { start, complete } = action.payload;
      if (start) {
        state.warning = {
          // TODO: Allow for translations
          heading: 'Updating your project',
          message:
            action.payload.message != null
              ? action.payload.message
              : 'Your project is not up to date with the latest version of LINKPlanner. Automatic project update in progress.',
          allowClose: false,
          warning: false,
        };
        state.locked = true;
      } else if (complete) {
        state.warning = null;
        state.locked = false;
      }
    },
    setProjectModified: (state, action) => {
      state.projectModified = action.payload;
    },
    updateAnpJobStatus: (state, action) => {
      state.anpJobStatus = {
        status: action.payload?.objects?.status,
        message: action.payload?.objects?.message,
      };
    },
    projectsLoaded: (state, action) => {
      state.projects = action.payload;
    },
    projectLoaded: (state, action) => {
      const {
        projectId,
        projectName,
        projectRefId,
        terragraphImport,
        clutterDetails,
        projectLoaded,
        useClutter,
        predictionModel,
        projectModified,
        permissionRead,
        permissionWrite,
        permissionAdmin,
        networkSites,
        subscriberSites,
        ptpLinks,
        pmpLinks,
        meshLinks,
        accessPoints,
        generalProjectProps,
      } = action.payload;

      sessionStorage.setItem('cn.lp.projectId', projectId);
      sessionStorage.setItem('cn.lp.projectName', projectName);

      Object.assign(state, {
        projectId,
        projectRefId,
        projectName,
        useClutter,
        predictionModel,
        clutterDetails,
        projectModified,
        permissionRead,
        permissionWrite,
        hasWritePermission: permissionWrite,
        permissionAdmin,
        projectLoaded,
        networkSites,
        subscriberSites,
        ptpLinks,
        accessPoints,
        pmpLinks,
        meshLinks,
        terragraphImport,
        networkSiteCount: networkSites.features.length,
        subscriberSiteCount: subscriberSites.features.length,
        ptpLinksCount: ptpLinks.features.length,
        pmpLinksCount: pmpLinks.features.length,
        meshLinksCount: meshLinks.features.length,
        accessPointsCount: accessPoints.features.length,
        loadingProject: false,
        mapState: states.MAP_SELECT,
        generalProjectProps: {
          ...defaultGeneralProjectProps,
          ...generalProjectProps,
        },
      });

      // display the features in the map
      const canvas = getCanvas();
      canvas.resetState();
      canvas.display(
        networkSites,
        subscriberSites,
        ptpLinks,
        accessPoints,
        meshLinks,
        pmpLinks
      );
      // Listen for WebSocket messages on the project
      socket.enterProjectRoom(projectId);
    },
    updateObjects: (state, action) => {
      const { payload } = action;

      state.projectModified = true;

      const containers = {
        network_site: state.networkSites,
        subscriber_site: state.subscriberSites,
        ptp_link: state.ptpLinks,
        access_point: state.accessPoints,
        mesh_link: state.meshLinks,
        pmp_link: state.pmpLinks,
      };
      console.log('UpdateObjects', action.payload);
      if (payload?.objects?.length) {
        // Remove the updated objects from the current feature lists
        payload.objects.forEach((obj) => {
          const kind = obj.kind;
          if (containers[kind] === null) {
            // If the UI updates and the project is receiving update object messages
            // then the containers can be null.
            // if this is the case then create the feature container on the fly
            console.warn(`No features found for ${kind}`);
            containers[kind] = {
              type: 'FeatureCollection',
              features: [],
            };
          }
          const container = containers[kind];
          const featureList = container?.features;
          const newFeatures = featureList?.filter(
            (feature) => `${kind}-${obj.id}` !== feature.id
          );
          if (containers[kind]?.hasOwnProperty('features')) {
            containers[kind].features = newFeatures;
          }
        });

        payload.objects.forEach((obj) => {
          const kind = obj.kind;
          if (kind === 'network_site' || kind === 'subscriber_site') {
            const geom = point([obj.longitude, obj.latitude], {
              kind: kind,
              name: obj.name,
              node_type: obj.node_type,
              id: obj.id,
            });
            geom.id = `${kind}-${obj.id}`;
            containers[kind].features.push(geom);
          } else if (kind === 'access_point') {
            for (const coords of getAPCoords(obj)) {
              const geom = polygon([coords], { kind: kind, ...obj });
              geom.id = `${kind}-${obj.id}`;
              containers[kind].features.push(geom);
            }
            queryClient.removeQueries({
              queryKey: [state.projectId, 'access_point', obj.id],
            });
          } else if (
            kind === 'pmp_link' ||
            kind === 'mesh_link' ||
            kind === 'ptp_link'
          ) {
            const geom = lineString(
              [
                [obj.loc_lng, obj.loc_lat],
                [obj.rem_lng, obj.rem_lat],
              ],
              { kind: kind, ...obj }
            );
            geom.id = `${kind}-${obj.id}`;
            containers[kind].features.push(geom);

            if (kind === 'ptp_link') {
              // This may be slower than "invalidateQueries", but it
              // should prevent certain refresh bugs for now.
              queryClient.removeQueries({
                queryKey: [state.projectId, 'ptp', obj.id],
              });
              queryClient.removeQueries({
                queryKey: [state.projectId, 'profile', obj.id],
              });
            } else if (kind === 'pmp_link') {
              queryClient.removeQueries({
                queryKey: [state.projectId, 'subscriber', obj.sm_id],
              });
              queryClient.removeQueries({
                queryKey: [state.projectId, 'profile', obj.sm_id],
              });
            }
          }
        });
      }

      state.networkSiteCount = state.networkSites.features.length;
      state.subscriberSiteCount = state.subscriberSites.features.length;
      state.ptpLinksCount = state.ptpLinks.features.length;
      state.accessPointsCount = state.accessPoints.features.length;
      state.meshLinksCount = state.meshLinks.features.length;
      state.pmpLinksCount = state.pmpLinks.features.length;

      updateMap(state);
    },
    deleteObjects: (state, action) => {
      const { payload } = action;

      state.projectModified = true;
      state.createMapTileIndex = true;

      const containers = {
        network_site: state.networkSites,
        subscriber_site: state.subscriberSites,
        access_point: state.accessPoints,
        mesh_link: state.meshLinks,
        pmp_link: state.pmpLinks,
        ptp_link: state.ptpLinks,
      };
      const kind = payload.kind;
      const container = containers[kind];
      const featureList = container.features;
      const newFeatures = featureList.filter(
        (feature) => payload.objects.indexOf(feature.properties.id) < 0
      );

      // if any object displayed or related to displayed object is deleted
      // we should navigate back to map to avoid issues
      if (kind === 'pmp_link') {
        const pmpLinksIds = state.pmpLinks.features.map((link) => {
          const { sm_id, id } = link.properties;
          return { [id]: sm_id };
        });
        const pmpLinksMap = Object.assign({}, ...pmpLinksIds);
        payload.objects.forEach((id) => {
          if (location.pathname.includes(pmpLinksMap[id])) {
            state.navigate('/');
          }
        });
      } else {
        payload.objects.forEach((id) => {
          if (location.pathname.includes(id)) {
            state.navigate('/');
          }
        });
      }

      if (kind === 'network_site') {
        state.networkSiteCount = newFeatures.length;
        state.networkSites = {
          type: 'FeatureCollection',
          features: newFeatures,
        };
      } else if (kind === 'subscriber_site') {
        state.subscriberSiteCount = newFeatures.length;
        state.subscriberSites = {
          type: 'FeatureCollection',
          features: newFeatures,
        };
      } else if (kind === 'access_point') {
        state.accessPointsCount = newFeatures.length;
        state.accessPoints = {
          type: 'FeatureCollection',
          features: newFeatures,
        };
      } else if (kind === 'mesh_link') {
        state.meshLinksCount = newFeatures.length;
        state.meshLinks = {
          type: 'FeatureCollection',
          features: newFeatures,
        };
      } else if (kind === 'pmp_link') {
        state.pmpLinksCount = newFeatures.length;
        state.pmpLinks = {
          type: 'FeatureCollection',
          features: newFeatures,
        };
      } else if (kind === 'ptp_link') {
        state.ptpLinksCount = newFeatures.length;
        state.ptpLinks = {
          type: 'FeatureCollection',
          features: newFeatures,
        };
      }
      updateMap(state);
    },
    warningMsg: (state, action) => {
      // Warning should have a "heading" and "message". It can
      // optionally include "allowClose", which, if true, will
      // allow the user to close the warning message
      state.warning = action.payload;
    },
    setSelectedPMPLinks: (state, action) => {
      state.selectedPMPItems = action.payload;
    },
    setSelectedMeshLinks: (state, action) => {
      state.selectedMeshItems = action.payload;
    },
    setSelectedPTPLinks: (state, action) => {
      state.selectedPTPItems = action.payload;
    },
    panelNeedsRefresh: (state, action) => {
      const { panels, status } = action.payload;
      for (const panel of panels) {
        state.needsRefresh[panel] = status;
      }
    },
    setBulkEditSelectedRows: (state, action) => {
      state.bulkEditSelectedRows = [...action.payload];
    },
    bulkEditSelectedRowsUpdate: (state, action) => {
      const updatedData = [];
      state.bulkEditSelectedRows.forEach((data) => {
        if (data.id === action.payload.id) {
          updatedData.push({ ...action.payload, updated: true });
        } else updatedData.push({ ...data });
      });
      state.bulkEditSelectedRows = updatedData;
    },
    bulkUpdateRowRemove: (state, action) => {
      const updatedData = [];
      state.bulkEditSelectedRows.forEach((data) => {
        if (action.payload?.id !== data.id) {
          updatedData.push({ ...data });
        }
      });
      state.bulkEditSelectedRows = updatedData;
    },
    setDirtyObject: (state, action) => {
      // Generally used for setting a single object to dirty/not dirty from the ui
      // i.e. when loading a panel for the first time or when submitting an
      // update api call
      const { kind, id, value } = action.payload;
      state.dirtyObjects[`${kind}-${id}`] = value;
    },
    setDirtyObjects: (state, action) => {
      // Used for setting multiple objects dirty/not dirty
      // Generally called by the socket code handling the websocket response
      // at the end of an update api call
      const { objects, value } = action.payload;
      for (const { kind, id } of objects) {
        state.dirtyObjects[`${kind}-${id}`] = value;
      }
    },
    longTaskStarted: (state, action) => {
      const { heading, message } = action.payload;
      state.permissionAdmin = false;
      state.permissionWrite = false;
      state.warning = {
        heading,
        message,
        allowClose: false,
        warning: false,
      };
      state.preventDiscard = state.projectId;
      state.mapState = states.MAP_SELECT;
      getCanvas().resetState();
    },
    longTaskComplete: (state) => {
      state.permissionAdmin = state.hasWritePermission;
      state.permissionWrite = state.hasWritePermission;
      state.warning = null;
      state.preventDiscard = null;
    },
    setNavigateFunc: (state, action) => {
      state.navigate = action.payload;
    },
    setAPNameSMName: (state, action) => {
      state.accessPointName = action.payload.access_point_name;
      state.subscriberName = action.payload.subscriber_name;
    },
    setImportingAnp: (state, action) => {
      const { payload } = action;
      state.importingAnp = payload;
    },
    setPmpSelectedTableTab: (state, action) => {
      const { tab } = action.payload;
      state.pmpSelectedTableTab = tab;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAccessPoint.fulfilled, (state, action) => {
      if (action.payload.status === 'success') {
        state.projectModified = true;
      }
    });

    builder.addCase(deleteSelectedAps.fulfilled, (state, action) => {
      if (action.payload.status === 'success') {
        state.projectModified = true;
      }
    });

    builder.addCase(getProjectPermissions.fulfilled, (state, action) => {
      state.projectPermissions = action.payload;
    });

    builder.addCase(getUserLimits.fulfilled, (state, action) => {
      state.userLimits = action.payload;
    });

    builder.addCase(getAllowedFeatures.fulfilled, (state, action) => {
      state.allowedFeatures = action.payload;
    });

    builder.addCase(fetchSites.fulfilled, (state, action: any) => {
      const [network, subscriber] = action.payload;
      state.networkSites = siteFeatureCollection('network_site', network.value);
      state.networkSiteCount = network.value.length;
      state.subscriberSites = siteFeatureCollection(
        'subscriber_site',
        subscriber.value
      );
      state.subscriberSiteCount = subscriber.value.length;
    });

    builder.addCase(fetchSites.rejected, (state, action) => {
      console.error(action.error);
    });

    builder.addCase(fetchAccessPoints.fulfilled, (state, action) => {
      const accessPoints: any = action.payload;
      state.accessPoints = accessPoints;
      state.accessPointsCount = accessPoints.features.length;
    });

    builder.addCase(getSubscriberGeometry.fulfilled, (state, action) => {
      state.pmpLinks = linkFeatureCollection('pmp_link', action.payload);
      state.pmpLinksCount = state.pmpLinks.features.length;
    });

    builder.addCase(getMeshLinksGeometry.fulfilled, (state, action) => {
      state.meshLinks = linkFeatureCollection('mesh_link', action.payload);
      state.meshLinksCount = state.meshLinks.features.length;
    });

    return builder;
  },
});

export const {
  uiSet,
  uiToggleSidebar,
  uiCollapseSidebar,
  uiExpandSidebar,
  uiConfirmAction,
  uiConfirmCancel,
  uiConfirmOk,
  userLogin,
  updatePreferences,
  userLogout,
  tokenRefresh,
  unloadProject,
  loadProject,
  setProjectModified,
  updateAnpJobStatus,
  projectsLoaded,
  projectLoaded,
  recalculateProject,
  updateObjects,
  deleteObjects,
  warningMsg,
  setSelectedPMPLinks,
  setSelectedMeshLinks,
  setSelectedPTPLinks,
  panelNeedsRefresh,
  setBulkEditSelectedRows,
  bulkEditSelectedRowsUpdate,
  bulkUpdateRowRemove,
  setDirtyObject,
  setDirtyObjects,
  longTaskStarted,
  longTaskComplete,
  updateTermsNConditions,
  setTermsVersion,
  setNavigateFunc,
  setAPNameSMName,
  setImportingAnp,
  updateProjectsList,
  setPmpSelectedTableTab,
} = mainFrameSlice.actions;
export default mainFrameSlice.reducer;
