import React from 'react';
import { sortBy } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector, batch } from 'react-redux';
import {
  Divider,
  Button,
  Segment,
  Popup,
  Grid,
  Header,
} from 'semantic-ui-react';
import { postWithAuth } from 'src/api';
import { store } from 'src/store';
import CreateViewshedModal from './CreateViewshedModal';
import {
  anyVisibleViewsheds,
  anyHiddenViewsheds,
  createViewshedsFromConfig,
  deleteViewshedConfig,
  finishLocationSelection,
  hideAllViewsheds,
  showAllViewsheds,
  hideViewshed,
  removeFailedViewshed,
  removeViewshed,
  setCreateModalVisibility,
  showViewshed,
  toggleSelectionPause,
} from './viewshed.reducer';
import { KILOMETERS_TO_MILES, METER_TO_FEET } from 'src/app.constants';

function getHeightsLabel(viewshed, heightUnits) {
  let result = [];

  const multiplier = heightUnits === 'ft' ? METER_TO_FEET : 1;
  const {
    tower_height,
    tower_height_type,
    remote_height_type,
    remote_height_clutter,
    remote_height,
    is_lidar,
  } = viewshed;

  const towerHeightStr = (tower_height * multiplier).toFixed(1) + heightUnits;
  if (is_lidar && (tower_height_type === 0 || tower_height_type === 3)) {
    // ABOVE_GROUND or SITE_MAX_HEIGHT
    result.push(`${towerHeightStr} AGL`);
  } else if (is_lidar && tower_height_type === 1) {
    // ABOVE_CLUTTER
    result.push(`${towerHeightStr} AC`);
  } else {
    result.push(towerHeightStr);
  }

  const remoteHeightStr = (remote_height * multiplier).toFixed(1) + heightUnits;
  if (remote_height_type === 0 || remote_height_type === 2) {
    // ABOVE_GROUND or MIN_ABOVE_CLUTTER
    result.push(`${remoteHeightStr} AGL`);
  }

  if (remote_height_type !== 0) {
    // not ABOVE_GROUND
    const remoteHeightClutterStr =
      (remote_height_clutter * multiplier).toFixed(1) + heightUnits;

    result.push(`${remoteHeightClutterStr} AC`);
  }

  return result.join(' / ');
}

function getViewshedLabel(viewshed, prefs) {
  const { radius, radio_los, band, is_lidar, use_clutter, include_clutter } =
    viewshed;
  const { rangeUnits, heightUnits } = prefs;

  let result = [];
  const multiplier = rangeUnits === 'mi' ? KILOMETERS_TO_MILES : 1;

  result.push(getHeightsLabel(viewshed, heightUnits));

  //Radius Format
  result.push((radius * multiplier).toFixed(2) + rangeUnits);

  if (radio_los) {
    result.push(band);
  }

  if (is_lidar) {
    result.push('High Res');
  } else if (include_clutter || use_clutter) {
    result.push('Clutter');
  }

  return result.join(' / ');
}

function PendingViewsheds(props) {
  const { pending, failed } = props;
  const failedStyle = { backgroundColor: '#fff', border: 'none', color: 'red' };
  const { prefs } = useSelector((state) => state.mainFrame);

  let result = [];
  for (const [key, group] of Object.entries(pending)) {
    result.push(
      <div key={group.data.name}>
        <h4>{getViewshedLabel(group.data, prefs)}</h4>
        <ul>
          {group.viewsheds.map((vs) => {
            if (failed.includes(vs.id)) {
              return (
                <li key={vs.id}>
                  <Button
                    icon="trash alternate"
                    style={failedStyle}
                    content={`${vs.site_name}`}
                    onClick={() => {
                      store.dispatch(removeFailedViewshed({ key, id: vs.id }));
                    }}
                  />
                </li>
              );
            } else {
              return <li key={vs.id}>{vs.site_name}</li>;
            }
          })}
        </ul>
      </div>
    );
  }

  return <div>{result}</div>;
}

function ViewshedList(props) {
  const { viewsheds } = props;
  const { projectId, prefs } = useSelector((state) => state.mainFrame);
  const keys = Object.keys(viewsheds).sort();
  let result = [];
  for (const key of keys) {
    const { color } = viewsheds[key][Object.keys(viewsheds[key])[0]];
    let nameFormat = getViewshedLabel(
      viewsheds[key][Object.keys(viewsheds[key])[0]],
      prefs
    );
    result.push(
      <Grid.Row key={key} style={{ alignItems: 'center' }}>
        <Grid.Column>
          <h3>{nameFormat}</h3>
        </Grid.Column>
        <Grid.Column style={{ textAlign: 'right' }}>
          <Button.Group>
            <Button
              style={{ backgroundColor: `#${color}`, cursor: 'default' }}
            />
            <Button
              icon="plus"
              title="Create viewshed with this config"
              onClick={() => {
                store.dispatch(createViewshedsFromConfig({ projectId, key }));
              }}
            />
            <Button
              icon="eye"
              title="Show all viewsheds with this config"
              onClick={() => {
                batch(() => {
                  for (const [id] of Object.entries(viewsheds[key])) {
                    store.dispatch(showViewshed({ id, key }));
                  }
                });
              }}
            />
            <Button
              icon="eye slash"
              title="Hide all viewsheds with this config"
              onClick={() => {
                batch(() => {
                  for (const [id] of Object.entries(viewsheds[key])) {
                    store.dispatch(hideViewshed({ id, key }));
                  }
                });
              }}
            />
            <Button
              icon="trash alternate"
              title="Delete all viewsheds with this config"
              onClick={() => {
                for (const id of Object.keys(viewsheds[key])) {
                  postWithAuth(
                    `project/${projectId}/viewsheds/${id}`,
                    {},
                    'DELETE'
                  );
                }
                store.dispatch(deleteViewshedConfig({ key }));
              }}
            />
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
    );

    for (const [id, viewshed] of sortBy(Object.entries(viewsheds[key]), [
      (e) => e[1].site_name,
    ])) {
      const { site_name, visible } = viewshed;
      result.push(
        <Grid.Row
          key={id}
          style={{ borderBottom: '1px #eaeaea solid', alignItems: 'center' }}
        >
          <Grid.Column>{site_name}</Grid.Column>
          <Grid.Column style={{ textAlign: 'right' }}>
            <Button.Group>
              {visible ? (
                <Button
                  icon="eye slash"
                  title="Hide Viewshed"
                  onClick={() => {
                    store.dispatch(hideViewshed({ id, key }));
                  }}
                />
              ) : (
                <Button
                  icon="eye"
                  title="Show Viewshed"
                  onClick={() => {
                    store.dispatch(showViewshed({ id, key }));
                  }}
                />
              )}
              <Button
                icon="trash alternate"
                title="Delete Viewshed"
                onClick={() => {
                  postWithAuth(
                    `project/${projectId}/viewsheds/${id}`,
                    {},
                    'DELETE'
                  );
                  store.dispatch(removeViewshed({ id, key }));
                }}
              />
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
      );
    }
  }

  return <Grid columns={2}>{result}</Grid>;
}

function ViewshedModePanel(props) {
  const {
    viewsheds,
    creating,
    selectingLocations,
    selectingPaused,
    pendingViewsheds,
    failedViewsheds,
  } = useSelector((state) => state.viewsheds);

  return (
    <>
      <CreateViewshedModal />
      <Segment className="map-mode-panel">
        <Segment basic textAlign="center">
          <Header as="h2">
            <FormattedMessage
              id="mapToolbar.ViewshedMode"
              defaultMessage="Viewshed Mode"
            />
          </Header>

          <Divider hidden />
          <Button.Group vertical labeled icon>
            {!(creating && selectingLocations) ? (
              <Button
                color="blue"
                content="Create Viewsheds"
                icon="plus"
                onClick={() => {
                  store.dispatch(setCreateModalVisibility(true));
                }}
                primary
              />
            ) : (
              <>
                {selectingPaused ? (
                  <Popup
                    basic
                    content="Resume selection to continue placing new viewshed locations"
                    trigger={
                      <Button
                        content="Resume Selection"
                        icon="play"
                        onClick={() => {
                          store.dispatch(toggleSelectionPause());
                        }}
                      />
                    }
                  />
                ) : (
                  <Popup
                    basic
                    content="Press pause if you wish you control the map"
                    trigger={
                      <Button
                        content="Pause Selection"
                        icon="pause"
                        onClick={() => {
                          store.dispatch(toggleSelectionPause());
                        }}
                      />
                    }
                  />
                )}
                <Button
                  content="Finish Selection"
                  icon="right chevron"
                  onClick={() => {
                    store.dispatch(finishLocationSelection());
                  }}
                  color="green"
                />
              </>
            )}
            {anyVisibleViewsheds(viewsheds) && (
              <Button
                content="Hide All Viewsheds"
                icon="eye slash"
                onClick={() => {
                  store.dispatch(hideAllViewsheds());
                }}
              />
            )}
            {anyHiddenViewsheds(viewsheds) && (
              <Button
                content="Show All Viewsheds"
                icon="eye"
                onClick={() => {
                  store.dispatch(showAllViewsheds());
                }}
              />
            )}
          </Button.Group>
        </Segment>
        <Divider hidden />
        {Object.keys(viewsheds).length === 0 &&
        Object.keys(pendingViewsheds).length === 0 ? (
          <p>There are currently no viewsheds in this project</p>
        ) : (
          <ViewshedList viewsheds={viewsheds} />
        )}
        {Object.keys(pendingViewsheds).length > 0 ? (
          <>
            <Divider hidden />
            <h3>Pending Viewsheds</h3>
            <PendingViewsheds
              pending={pendingViewsheds}
              failed={failedViewsheds}
            />
          </>
        ) : null}
      </Segment>
    </>
  );
}

export default injectIntl(ViewshedModePanel);
