import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { Header, Segment } from 'semantic-ui-react';
import PTPLinksTable from './PTPLinksTable';

function PTPLinksPanel({ intl }) {
  const { ptpLinks, ptpLinksCount } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  let items = [];
  if (ptpLinks) {
    items = ptpLinks.features.map((link) => {
      return link.properties;
    });
  }

  const listView = <PTPLinksTable />;
  return (
    <Segment basic>
      <Header>
        <FormattedMessage id="common.ptpLinks" defaultMessage="PTP Links" />
        {` (${ptpLinksCount})`}
      </Header>
      <div className="detailWrapper">{listView}</div>
    </Segment>
  );
}

export default injectIntl(PTPLinksPanel);
