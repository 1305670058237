import React, { useState, createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dropdown, Loader } from 'semantic-ui-react';
import {
  downloadProject,
  downloadProjectKmz,
  exportKmz,
  exportProject,
} from './reportHelpers';
import { RootStateOrAny, store } from '../../store';
import { useQuery } from '@tanstack/react-query';
import { getWithAuth } from 'src/api';
import googleEarthIcon from '../../../src/images/Google_Earth_icon.svg';
import linkPlannerIcon from '../../../src/images/linkplanner-coloured.svg';
import { setStatusRefetch } from './headermenu.reducer';

const ReportMenuContext = createContext(null);

type Status = '' | 'in_progress' | 'error' | 'ready';

type Report = {
  id: string;
  object_kind: string;
  report_kind: string;
  status: Status;
};

export const ExportMenu = (props) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  return (
    <ReportMenuContext.Provider value={setOpen}>
      <Dropdown
        title=""
        text={formatMessage({
          id: 'export.export',
          defaultMessage: 'Export',
        })}
        onMouseDown={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        floating
        labeled
        className="mr-2"
        icon={{ name: '' }}
      >
        <Dropdown.Menu>
          {open ? <ExportMenuList setOpen={setOpen} {...props} /> : null}
        </Dropdown.Menu>
      </Dropdown>
    </ReportMenuContext.Provider>
  );
};

const ExportMenuList = (props) => {
  const { projectId } = useSelector((state: RootStateOrAny) => state.mainFrame);
  const { statusRefetch } = useSelector(
    (state: RootStateOrAny) => state.headerMenu.export
  );
  const query = useQuery(
    ['ReportMenu', projectId],
    async () => {
      return await getWithAuth(`project/${projectId}/reports/available`);
    },
    { retry: false }
  );

  if (query.isLoading) {
    return <Loader active />;
  }

  const availableReports: Report[] = query.data;

  const getStatus = (reportKind) => {
    for (let idx in availableReports) {
      const report = availableReports[idx];
      if (report.report_kind === reportKind) {
        return report.status;
      }
    }
    return '';
  };

  if (statusRefetch) {
    query.refetch();
    store.dispatch(setStatusRefetch(false));
  }

  return (
    <>
      <ExportProject status={getStatus('lpp')} {...props} />
      <GoogleEarthExport status={getStatus('kmz')} {...props} />
      {/* <Dropdown.Divider />
        <ReportsPanelItem /> */}
    </>
  );
};

const ExportMenuItem = ({
  status,
  progressTitle,
  progressLabel,
  downloadTitle,
  downloadLabel,
  createTitle,
  createLabel,
  downloadFunction,
  createFunction,
  image,
}) => {
  if (status === 'in_progress') {
    return <InProgress title={progressTitle} label={progressLabel} />;
  } else if (status === 'ready') {
    return (
      <ExportDownload
        title={downloadTitle}
        label={downloadLabel}
        clickFunction={downloadFunction}
      />
    );
  } else {
    return (
      <CreateExport
        title={createTitle}
        label={createLabel}
        clickFunction={createFunction}
        image={image}
      />
    );
  }
};

const InProgress = ({ title, label }) => {
  return (
    <Dropdown.Item
      title={title}
      icon={{ name: 'spinner', loading: true }}
      text={label}
    />
  );
};

const ExportDownload = ({ title, label, clickFunction }) => {
  const { projectId, projectName } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const setOpen = useContext(ReportMenuContext);
  return (
    <Dropdown.Item
      title={title}
      icon={{
        name: 'download',
      }}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
        clickFunction(projectId, projectName);
      }}
      text={label}
    />
  );
};

const CreateExport = ({ title, label, clickFunction, image }) => {
  const { formatMessage } = useIntl();
  const { projectId, projectName } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const setOpen = useContext(ReportMenuContext);
  return (
    <Dropdown.Item
      onClick={() => {
        setOpen(false);
        clickFunction(projectId, projectName, formatMessage);
      }}
      title={title}
      style={{ display: 'flex', minWidth: '220px' }}
      text={label}
      image={image}
    ></Dropdown.Item>
  );
};

const ExportProject = ({ status }) => {
  const { formatMessage } = useIntl();
  const progressTitle = formatMessage({
    id: 'export.lpprogressTitle',
    defaultMessage: 'Project export in progress',
  });
  const progresslabel = formatMessage({
    id: 'export.kmzprogresslabel',
    defaultMessage: 'Processing LINKPlanner v5 File',
  });
  const downloadTitle = formatMessage({
    id: 'export.lpDownloadTitle',
    defaultMessage: 'Download the LINKPlanner v5 project file',
  });
  const downloadLabel = formatMessage({
    id: 'export.lpDownloadLabel',
    defaultMessage: 'Download Project File',
  });
  const createTitle = formatMessage({
    id: 'export.lpCreateTitle',
    defaultMessage: 'Export as a LINKPlanner v5 project file',
  });
  const createLabel = formatMessage({
    id: 'export.lpCreateLabel',
    defaultMessage: 'Export LINKPlanner Project File',
  });

  return (
    <ExportMenuItem
      status={status}
      progressTitle={progressTitle}
      progressLabel={progresslabel}
      downloadTitle={downloadTitle}
      downloadLabel={downloadLabel}
      createTitle={createTitle}
      createLabel={createLabel}
      downloadFunction={downloadProject}
      createFunction={exportProject}
      image={{
        src: linkPlannerIcon,
        style: { height: '13px' },
      }}
    />
  );
};

const GoogleEarthExport = ({ status }) => {
  const { formatMessage } = useIntl();
  const progressTitle = formatMessage({
    id: 'export.kmzprogressTitle',
    defaultMessage: 'Google Earth™ export in progress',
  });
  const progresslabel = formatMessage({
    id: 'export.kmzprogresslabel',
    defaultMessage: 'Processing Google Earth™ File',
  });
  const downloadTitle = formatMessage({
    id: 'export.kmzDownloadTitle',
    defaultMessage: 'Download the Google Earth™ KMZ file',
  });
  const downloadLabel = formatMessage({
    id: 'export.kmzDownloadLabel',
    defaultMessage: 'Download Google Earth™ File',
  });
  const createTitle = formatMessage({
    id: 'export.kmzCreateTitle',
    defaultMessage: 'Create a Google Earth™ KMZ file from the project',
  });
  const createLabel = formatMessage({
    id: 'export.kmzCreateLabel',
    defaultMessage: 'Export to Google Earth™',
  });

  return (
    <ExportMenuItem
      status={status}
      progressTitle={progressTitle}
      progressLabel={progresslabel}
      downloadTitle={downloadTitle}
      downloadLabel={downloadLabel}
      createTitle={createTitle}
      createLabel={createLabel}
      downloadFunction={downloadProjectKmz}
      createFunction={exportKmz}
      image={{
        src: googleEarthIcon,
        style: { height: '13px' },
      }}
    />
  );
};

/*
const ReportsPanelItem = () => {
  const { formatMessage } = useIntl();

  return (
    <Dropdown.Item
      as={Link}
      to="/reports"
      title={formatMessage({
        id: 'reports.viewReportsTitle',
        defaultMessage: 'View the reports and exports for this project',
      })}
    >
      {formatMessage({
        id: 'reports.viewReports',
        defaultMessage: 'View Reports and Exports',
      })}
    </Dropdown.Item>
  );
};
*/
