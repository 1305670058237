import React from 'react';
import eskape from 'eskape';
import { set } from 'lodash';
import { bearingToAzimuth, point } from '@turf/helpers';
import destination from '@turf/destination';
import { store } from '../store';
import { uiConfirmAction } from '../pages/mainframe/mainframe.reducer';
import produce from 'immer';
import { getName } from './naming';
import {
  FEET_TO_METER,
  GHZ_TO_MHZ,
  GREY_COLOR,
  GRID_PAGE_SIZE,
  KHZ_TO_MHZ,
  LAT_LNG_OPT_VALUES,
  LatLngFormats,
  MAC_ADDRESS_OPT_VALUES,
  MAX_MUX_GAIN,
  METER_TO_FEET,
  MILES_TO_KILOMETERS,
  PERCENT,
  SECONDS_PER_YEAR,
  THROUGHPUT,
} from '../app.constants';
import LocalStorageUtils from './LocalStorageUtils';
import { postWithAuth } from 'src/api';
import { DragStoppedEvent, DragStartedEvent } from 'ag-grid-community';
import bearing from '@turf/bearing';

// Duplicate code from index.js
// Hard-code locale to "en" for now,
// but in the future we will probably want
// to allow the user to choose their locale
// navigator.language
// const locale = 'en';

// const intlProvider = new IntlProvider({ locale, additionalMessages });
// const { intl } = intlProvider.getChildContext();

export const kindToPath = (kind) => {
  return {
    network_site: 'network_sites',
    subscriber_site: 'sps',
    ptp_link: 'ptp',
    access_point: 'aps',
    mesh_link: 'mesh',
    pmp_link: 'pmp',
  }[kind];
};

// TODO: language translations for the labels
export const kindToLabel = (kind, plural = false) => {
  if (plural) {
    return {
      network_site: 'Network Sites',
      subscriber_site: 'Subscriber Sites',
      ptp_link: 'PTP Links',
      access_point: 'Network Devices',
      mesh_link: 'Mesh Links',
      pmp_link: 'PMP Links',
      network_site_label: 'Show Network Site Labels',
      subscriber_site_label: 'Show Subscriber Site Labels',
    }[kind];
  } else {
    return {
      network_site: 'Network Site',
      subscriber_site: 'Subscriber Site',
      ptp_link: 'PTP Link',
      access_point: 'Network Device',
      mesh_link: 'Mesh Link',
      pmp_link: 'PMP Link',
      network_site_label: 'Show Network Site Label',
      subscriber_site_label: 'Show Subscriber Site Label',
    }[kind];
  }
};

export const pathToKind = (path) => {
  return {
    network_sites: 'network_site',
    sps: 'subscriber_site',
    ptp: 'ptp_link',
    aps: 'access_point',
    mesh: 'mesh_link',
    pmp: 'pmp_link',
  }[path];
};

export const href = (o, prefix = null) => {
  if (o) {
    if (prefix) {
      return eskape`${prefix}#${o.id}`;
    } else {
      return eskape`#${o.id}`;
    }
  }
};

export const roundTo = (number, precision) => {
  var factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

/*
 * Return True if the dict/object is empty
 */
export const isEmptyObject = (obj) => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
};

export const isValidFloat = (value) => {
  const trimmedValue = value.trim();
  let parsed = null;
  if (trimmedValue) {
    parsed = parseFloat(trimmedValue);
  }
  if (isNaN(parsed) && trimmedValue !== '.') {
    return [false, trimmedValue];
  } else if (trimmedValue.endsWith('.')) {
    parsed = (parsed || 0) + 0.0;
  }
  return [true, parsed];
};

/*
 * Convert a list of objects
 * to a dictonary object using the _id
 * as the key
 */
export const toDict = (objects) => {
  return objects.reduce((res, obj) => {
    res[obj._id] = obj;
    return res;
  }, {});
};

export const getattrpath = (obj, path) => {
  if (obj) {
    path.split('.').forEach((part) => {
      obj = obj[part] ? obj[part] : {};
    });
    return obj;
  }
};

/*
 * Display a confirmation dialog
 * before dispatching an action or
 * executing a callback
 */
export const runWithConfirmation = (options) => {
  // TODO: Use formatMessage
  const header = options.header ? options.header : null;
  const message = options.message
    ? options.message
    : // : intl.formatMessage(additionalMessages.confirm);
      'Are you sure?';
  const size = options.size ? options.size : 'mini';
  const onConfirm = options.onConfirm ? options.onConfirm : null;
  const onCancel = options.onCancel ? options.onCancel : null;

  store.dispatch(
    uiConfirmAction({
      header,
      message,
      size,
      onConfirm,
      onCancel,
    })
  );
};

export const onSelectItem = (selectedItems, callback, redux = true) => {
  return (i, checkAll) => {
    // force copy
    let selected = [...selectedItems];

    if (checkAll === false) {
      selected = [];
    } else if (Array.isArray(i) && checkAll) {
      // select all
      selected = i.map((e) => e.id);
    } else {
      const index = selectedItems.indexOf(i.id);
      // toggle individual selection
      if (index >= 0) {
        selected.splice(index, 1);
      } else if (i.id) {
        selected.push(i.id);
      }
    }
    if (redux) {
      store.dispatch(callback(selected));
    } else {
      callback(selected);
    }
  };
};

//trims the string values in object
export function trimObject(obj) {
  for (var key in obj) {
    if (typeof obj[key] === 'string') obj[key] = obj[key].trim();
  }
}

/**
 * Returns a function which updates an attribute within an object using immer
 * Expects obj/attr structure to be one of:
 *
 * a) `{ attr: <value>, ... }`
 * b) `{ attr: { value: <value>, ... }}`
 */
export const updateAttr = (fn, obj) => {
  return (attr, value) => {
    fn(
      produce(obj, (next) => {
        if (next[attr]?.hasOwnProperty('value')) {
          next[attr].value = value;
        } else {
          next[attr] = value;
        }
      })
    );
  };
};

export function getDropdownOptions(items, key = 'id', value = 'name') {
  return items.map((item) => {
    const text = value === 'name' ? getName(item) : item[value];
    return (
      <option key={item[key]} value={item[key]}>
        {text}
      </option>
    );
  });
}

export function getSelectOptions(items, key = 'id', value = 'name') {
  const options = [];
  items.forEach((item) => {
    options.push({
      key: item[key],
      text: item[value],
      value: item[key],
    });
  });
  return options;
}

const _getFromStorage = (storage, name, defaultValue) => {
  if (storage) {
    const value = storage.getItem(name);
    if (value) {
      try {
        return JSON.parse(value);
      } catch {
        return defaultValue;
      }
    } else {
      return defaultValue;
    }
  }
};

export const getSessionJSON = (name, defaultValue) => {
  return _getFromStorage(sessionStorage, name, defaultValue);
};

export const getLocalJSON = (name, defaultValue) => {
  return _getFromStorage(localStorage, name, defaultValue);
};

const _setToStorage = (storage, name, value) => {
  if (storage) {
    storage.setItem(name, JSON.stringify(value));
  }
};

export const setSessionJSON = (name, value) => {
  _setToStorage(sessionStorage, name, value);
};

export const setLocalJSON = (name, value) => {
  _setToStorage(localStorage, name, value);
};

export const getAPCoords = (ap, smRange = null, rangeUnits = null) => {
  let result = [];
  const base_ap_lat = ap.latitude;
  const base_ap_lng = ap.longitude;
  // Only consider the first radio and the first antenna
  // for the geometry. This will need to change for cnRanger
  const radio = ap.radios[0];
  const sm_range = smRange ?? parseFloat(radio.sm_range);
  let range_units = rangeUnits ?? radio.range_units;
  if (range_units === 'km') {
    range_units = 'kilometers';
  }
  for (const antenna of radio.antennas) {
    const ap_lat = antenna.latitude ? antenna.latitude : base_ap_lat;
    const ap_lng = antenna.longitude ? antenna.longitude : base_ap_lng;
    const beamwidth = antenna.beamwidth;
    // mod 360 just for safety to avoid infinite loops
    const azimuth = antenna.azimuth % 360;

    let start_a = 360;
    let end_a = 0;
    let coords = [];
    if (beamwidth !== 360) {
      // Right-hand rule. Outer ring must be anticlockwise
      start_a = (azimuth + beamwidth / 2 + 360) % 360;
      end_a = (azimuth - beamwidth / 2 + 360) % 360;
      coords.push([ap_lng, ap_lat]);
    }

    let angle = start_a;
    const step = 2;
    const centrePoint = point([ap_lng, ap_lat]);
    let counter = 0;
    //   const errorLog = [];

    while (true) {
      let [tmp_lng, tmp_lat] = destination(centrePoint, sm_range, angle, {
        units: range_units,
      }).geometry.coordinates;
      coords.push([tmp_lng, tmp_lat]);
      // errorLog.push([start_a, angle, end_a]);

      if (Math.abs(angle - end_a) < step) {
        if (angle !== end_a) {
          const p = destination(centrePoint, sm_range, end_a, {
            units: range_units,
          }).geometry.coordinates;
          tmp_lat = p[1];
          tmp_lng = p[0];
          coords.push([tmp_lng, tmp_lat]);
        }
        break;
      }

      counter += 1;
      if (counter > 181) {
        console.error('Infinite loop');
        //   console.error(errorLog);
        break;
      }

      angle = (angle - step + 360) % 360;
    }

    if (beamwidth !== 360) {
      coords.push([ap_lng, ap_lat]);
    }

    result.push(coords);
  }

  return result;
};

/**
 * Convert a value in km to the length preference units
 */
export const km_to_length = (value_km) => {
  if (store.getState().mainFrame.prefs.rangeUnits === 'km') {
    return value_km;
  } else {
    // return in miles
    return value_km / MILES_TO_KILOMETERS;
  }
};

/**
 * Convert a value in km to the height preference units
 */
export const km_to_height = (value_km) => {
  if (store.getState().mainFrame.prefs.heightUnits === 'm') {
    return value_km * 1000;
  } else {
    // return in ft
    return value_km * 1609.344;
  }
};

/*
 * Convert a height in metres to the display units
 */
export const metresToHeightUnits = (
  height: number | 'N/A',
  heightUnits: 'ft' | 'm',
  precision: number = 2
): string => {
  if (height === 'N/A') {
    return 'N/A';
  }
  let scaledValue = height;
  let units = 'm';
  if (heightUnits === 'ft') {
    scaledValue = height * METER_TO_FEET;
    units = 'ft';
  }
  return `${scaledValue.toFixed(precision)} ${units}`;
};

/**
 * Return the short height unit label
 */
export const shortHeightUnits = () => {
  if (store.getState().mainFrame.prefs.heightUnits === 'm') {
    return 'm';
  } else {
    return 'ft';
  }
};

export const convertRange = (range, units) => {
  if (units === 'miles') {
    range = range / MILES_TO_KILOMETERS;
  }
  let multiplier;
  if (range > 1) {
    multiplier = 10;
  } else if (range > 0.25) {
    multiplier = 100;
  } else {
    multiplier = 1000;
  }
  return Math.round(range * multiplier) / multiplier;
};

export const encodeLatLong = (value, format, lat = true) => {
  if (format === LatLngFormats.signedDeg) {
    if (isNaN(parseFloat(value))) {
      value = 0;
    }
    return parseFloat(value).toFixed(5);
  }
  const degMinSec = [
    [6, 10, 6, 10, 10, 10],
    [
      [0, ':'],
      [2, ':'],
      [4, '.'],
    ],
  ];
  const degMin = [
    [6, 10, 10, 10, 10],
    [
      [0, ':'],
      [2, '.'],
    ],
  ];
  const deg = [[10, 10, 10, 10, 10], [[0, '.']]];
  const NS: [[string, string], number] = [['N', 'S'], 2];
  const EW: [[string, string], number] = [['E', 'W'], 3];
  // From LatLngFormats...
  //   deg = 'deg (ddd.dddddP)',
  //   degMin = 'deg:min (ddd:mm.mmmP)',
  //   degMinSec = 'deg:min:sec (ddd:mm:ss.ssP)',

  const digSepar = {
    [LatLngFormats.deg]: deg,
    [LatLngFormats.degMin]: degMin,
    [LatLngFormats.degMinSec]: degMinSec,
  }[format || LatLngFormats.deg];
  const pointFill: [string[], number] = lat ? NS : EW;
  const [point, fill] = pointFill;
  const dig = digSepar[0];
  const separ = digSepar[1];
  let val = Math.abs(value);
  dig.reverse();
  const i = dig.reduce((acc, current) => acc * current);
  val = Math.abs(Math.round(val * i));

  const st = dig.map(function (j) {
    const valsmaller = Math.floor(val / j);
    const res = (val - valsmaller * j).toString();
    val = valsmaller;
    return res;
  });

  st.reverse();
  separ.reverse();

  separ.forEach(function (item, index) {
    st.splice(item[0], 0, item[1]);
  });
  const nsStr = value < 0 ? point[1] : point[0];
  const retVal = `${val.toString().padStart(fill, '0')}${st.join('')}${nsStr}`;
  return retVal;
};

export const checkLimits = (r, lat) => {
  r = parseFloat(r.toFixed(5));
  if (lat && r < 90 && r > -90) {
    return [r, true];
  }
  if (!lat && r < 180 && r > -180) {
    return [r, true];
  }
  return [r, false];
};

export const decodeLatLong = (value, lat = true) => {
  let dir = '[NSns]';
  if (!lat) {
    dir = '[EWew]';
  }

  if (/^[+-]?[01]?[0-9]{1,2}[.]?[0-9]*$/.test(value)) {
    return checkLimits(parseFloat(value), lat);
  }

  if (
    new RegExp('^[01]?[0-9]{1,2}[.]{0,1}[0-9]*' + dir + '{0,1}$').test(value)
  ) {
    if (/[SsWw]$/.test(value)) {
      return checkLimits(parseFloat(value) * -1, lat);
    }
    return checkLimits(parseFloat(value), lat);
  }

  if (
    new RegExp('^[0-9]{1,3}[:][0-5]?[0-9]([.][0-9]*)?' + dir + '{0,1}$').test(
      value
    )
  ) {
    let splStr = value.split(':');
    let decodedVal = parseInt(splStr[0]) + parseFloat(splStr[1]) / 60;
    if (/[SsWw]$/.test(value)) {
      decodedVal *= -1;
    }
    return checkLimits(decodedVal, lat);
  }

  if (
    new RegExp(
      '^[0-9]{1,3}[:][0-5]?[0-9][:][0-5]?[0-9]([.][0-9]*)?' + dir + '{0,1}$'
    ).test(value)
  ) {
    let splStr = value.split(':');
    let val =
      parseInt(splStr[0]) +
      parseFloat(splStr[1]) / 60 +
      parseFloat(splStr[2]) / 3600;
    if (/[SsWw]$/.test(value)) {
      val *= -1;
    }
    return checkLimits(val, lat);
  }
  return [value, false];
};

export function debounce(fn, time) {
  let timeoutId;
  return wrapper;
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}
export const formatByUnits = (value, unit) => {
  if (value) {
    if (unit === 'dB') {
      value = parseFloat(value).toFixed(2);
    } else if (unit === '%') {
      value = parseFloat(value).toFixed(4);
    }
  }
  return value;
};

export const adjustPrecision = (
  value: number,
  type: string,
  precision?: number
): string => {
  let result;
  if (type === THROUGHPUT) {
    result = parseFloat(value.toString()).toFixed(precision ? precision : 2);
  } else if (type === PERCENT) {
    result = parseFloat(value.toString()).toFixed(precision ? precision : 1);
  } else if (type === MAX_MUX_GAIN) {
    result = parseFloat(value.toString()).toFixed(precision ? precision : 2);
  } else {
    result = value;
  }
  return result;
};

// This function accepts the value in [km,ft,mi] and converts
// according to meter
export const anyToMeter = (
  value: number,
  fromUnit?: string,
  precision: number = 1,
  format: boolean = true
): number => {
  if (fromUnit === 'm') {
    return value;
  }
  let retVal = value;
  if (fromUnit === 'km') {
    retVal = value * 1000;
  } else if (fromUnit === 'ft') {
    retVal = value / METER_TO_FEET;
  } else if (fromUnit === 'mi') {
    retVal = value * 1609.344;
  }
  if (!format) {
    return retVal;
  } else {
    return round(retVal, precision);
  }
};

export const getLocale = (language?: string): string => {
  // take a stab at guessing the locale
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language || 'en';
  let locale;
  if (locale === 'en' || locale === 'en-GB') {
    locale = userLocale;
  } else {
    locale = 'en';
  }
  if (language) {
    locale = language;
  }
  return locale;
};

export const getGridPageSize = (): number => {
  return LocalStorageUtils.getItem(GRID_PAGE_SIZE) || 10;
};

export const fixedPrecision = (value: number, precision: number): string => {
  return value.toFixed(precision);
};

export const round = (value: number, precision: number = 0): number => {
  return parseFloat(Number(value).toFixed(precision));
};

// This function accepts the value to convert in meters, toUnit
// and converts accordingly
export const meterToAny = (
  value: number,
  toUnit?: string,
  precision: number = 1,
  format: boolean = true
): number => {
  let retVal = value;
  if (toUnit === 'km') {
    retVal = value / 1000;
  } else if (toUnit === 'ft') {
    retVal = value * METER_TO_FEET;
  } else if (toUnit === 'mi') {
    retVal = value / 1609.344;
  }
  if (!format) {
    return retVal;
  } else {
    return round(retVal, precision);
  }
};

/*
 * These functions below (rad, pyround and dist) are
 * used to provide the same link length calculations as
 * the Python code.
 */
function rad(deg: number): number {
  return (deg * Math.PI) / 180;
}

export function pyround(n: number, d: number): number {
  const x = n * Math.pow(10, d);
  const r = Math.round(x);
  const br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
  return br / Math.pow(10, d);
}

export const desktopDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
): number => {
  const r = 298.257223563;
  const e2 = (2 * r - 1) / Math.pow(r - 1, 2);
  const a = 6378137;
  lat1 = rad(pyround(lat1, 5));
  lng1 = rad(pyround(lng1, 5));
  lat2 = rad(pyround(lat2, 5));
  lng2 = rad(pyround(lng2, 5));
  const d_lat = lat2 - lat1;
  const A = Math.sqrt(1 + e2 * Math.pow(Math.cos(lat1), 4));
  const B = Math.sqrt(1 + e2 * Math.pow(Math.cos(lat1), 2));
  const C = Math.sqrt(1 + e2);
  const D =
    (d_lat / (2.0 * B)) *
    (1 +
      ((3 * e2) / (4 * Math.pow(B, 2))) *
        d_lat *
        Math.sin(2 * lat1 + (2.0 / 3) * d_lat));
  let d_lng;
  if (lng2 - lng1 > Math.PI) {
    d_lng = lng2 - lng1 - 2 * Math.PI;
  } else if (lng2 - lng1 < -Math.PI) {
    d_lng = lng2 - lng1 + 2 * Math.PI;
  } else {
    d_lng = lng2 - lng1;
  }
  const w = (A * d_lng) / 2.0;
  const E = Math.sin(D) * Math.cos(w);
  const F =
    (1.0 / A) *
    Math.sin(w) *
    (B * Math.cos(lat1) * Math.cos(D) - Math.sin(lat1) * Math.sin(D));
  const sigma = Math.asin(Math.sqrt(Math.pow(E, 2) + Math.pow(F, 2))) * 2;
  const distance = (a * C * sigma) / Math.pow(B, 2);
  return distance;
};

//Returns last element of an array
export function last(array: any[]) {
  return array[array.length - 1];
}

export function isDirty(
  dirtyObjects: Record<string, boolean>,
  kind: string,
  id: string
) {
  return dirtyObjects[`${kind}-${id}`] || false;
}

/*
 * Returns promise, resolve, reject useful in cases
 * where we need to resolve/reject out side the promise
 * scope;
 */
export function flatPromise() {
  let resolve, reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return { promise, resolve, reject };
}

export function filterObject(objectArray, key, value) {
  return objectArray.filter((obj) => obj[key] === value)[0];
}

function contiguous(elem, index, arr) {
  return index === 0 || elem - 1 === arr[index - 1];
}

export function isContiguousSelection(selection, choices) {
  if (!selection || !choices) {
    return false;
  }

  const choiceIndexes = Object.fromEntries(
    choices.map((item, i) => [
      item.hasOwnProperty('value') ? item.value : item,
      i,
    ])
  );
  const selectionIndexes = selection.map((s) => choiceIndexes[s]);
  return selectionIndexes.every(contiguous);
}

export const getUnitsLabels = (key) => {
  let units = '';
  switch (key) {
    case 'mi':
      units = 'miles';
      break;
    case 'km':
      units = 'kilometers';
      break;
    case 'm':
      units = 'meters';
      break;
    case 'ft':
      units = 'feet';
      break;
    default:
      return key;
  }
  return units;
};

export const getStatusColor = (color) => {
  let uiColor = '';
  switch (color) {
    case GREY_COLOR:
      uiColor = '#AAAAAA';
      break;
    default:
      return color;
  }
  return uiColor;
};

export const getStatusBackGroundColor = (color) => {
  let uiColor = '';
  switch (color) {
    case GREY_COLOR:
      uiColor = '#AAAAAA';
      break;
    case '#ff000096':
      uiColor = '#F7D0D0';
      break;
    case '#ff0000':
      uiColor = '#F7D0D0';
      break;
    default:
      return color;
  }
  return uiColor;
};

export const getCellStyle = (params) => {
  if (params.data && params.data.strokeColor) {
    return {
      color: getStatusColor(params.data.strokeColor),
    };
  }
  //return the default color else it wont reset in case
  // of bulk edit rows changing from invalid (red color rows) to valid (black color rows) data
  return { color: 'rgba(0, 0, 0, 0.87)' };
};

export function downloadReport(reqFn, projectId, repId, filename) {
  // reqFn => getWithAuth (but avoiding circular deps)
  return reqFn(`project/${projectId}/reports/download/${repId}`, 'GET', true)
    .then((res) => {
      const blobObj = new Blob([res], { type: 'application/pdf' });
      const blobUrl = window.URL.createObjectURL(blobObj);
      let a = document.createElement('a');
      a.href = blobUrl;
      a.download = `${filename}.pdf`;
      a.click();
    })
    .catch((err) => {
      console.log('err', err);
    });
}

export const sortObjectsByKey = (
  objectsArr,
  key,
  sort = 'asc',
  caseSensitive = true
) => {
  objectsArr.sort((a, b) => {
    let aKey = a[key];
    let bKey = b[key];
    if (!caseSensitive) {
      aKey = aKey.toLowerCase();
      bKey = bKey.toLowerCase();
    }
    if (aKey > bKey) {
      if (sort === 'asc') {
        return 1;
      } else {
        return -1;
      }
    } else if (aKey < bKey) {
      if (sort === 'dsc') {
        return 1;
      } else {
        return -1;
      }
    } else {
      return 0;
    }
  });
  return objectsArr;
};

export function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

const macAddressFormats = {
  '00:00:00:00:00:00': [2, ':'],
  '00-00-00-00-00-00': [2, '-'],
  '000.000.000.000': [3, '.'],
  //   '000000000000': [],
};

export const formatMacAddress = (value, format: string): string => {
  if (!value) {
    return '';
  }
  const cleanValue = value.replaceAll(/[:\-\\.]/gi, '').toUpperCase();
  const formatSequence = macAddressFormats[format];
  if (!formatSequence) {
    // '000000000000'
    return cleanValue;
  }
  const [separation, divider] = formatSequence;
  const valArray = [...cleanValue];
  if (valArray.length !== 12) {
    return value;
  }
  return valArray
    .map((el, index) => {
      if (index % separation === 0 && index > 0) {
        return divider + el;
      } else {
        return el;
      }
    })
    .join('');
};

export function isMacAddress(units: string) {
  return MAC_ADDRESS_OPT_VALUES.includes(units);
}
export function isLatLng(units: string) {
  return LAT_LNG_OPT_VALUES.includes(units);
}
export const postBulkUpdates = (
  projectId,
  ids,
  objectPath,
  value,
  gridApi,
  type,
  heightUnits,
  fetchSites?
) => {
  let url = '';
  if (type === 'nd') {
    url = `project/${projectId}/access_points/bulk`;
  } else if (type === 'site') {
    url = `project/${projectId}/sites/bulk`;
  } else if (type === 'pmpLink') {
    url = `project/${projectId}/subscribers/bulk`;
  } else if (type === 'mesh') {
    url = `project/${projectId}/mesh_links/bulk`;
  } else if (type === 'ptp') {
    url = `project/${projectId}/ptp/bulk`;
  }
  if (objectPath.includes('height') && heightUnits == 'ft') {
    value = value * FEET_TO_METER;
  }
  const postObj = {
    ids: ids,
    data: set({}, objectPath, value),
    bulk: 'edit',
  };
  gridApi.showLoadingOverlay();
  postWithAuth(url, postObj, 'PATCH')
    .then(() => {})
    .catch((err) => {})
    .finally(() => {
      gridApi.hideOverlay();
      if (fetchSites) {
        store.dispatch(fetchSites(projectId));
      }
    });
};

export function makePlural(count: number, single: string, plural: string) {
  if (count === 1) {
    return single;
  } else {
    return plural;
  }
}
// This is for firefox, since firefox allows characters even though
// the input type is number
export function preventNonNumericalInput(e) {
  e = e || window.event;
  var charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^-?\d*(\.\d{0,3})?$/)) e.preventDefault();
}

export const isValidNumber = (value, min, max, precision) => {
  const numberRegex = `^-?\\d*(\\.\\d{0,${precision}})?$`;
  const floatValue = parseFloat(value);
  let valid = false;
  const regex = new RegExp(numberRegex, 'g');
  if (
    regex.test(Math.abs(value).toString()) &&
    floatValue >= min &&
    floatValue <= max
  ) {
    valid = true;
  }
  return valid;
};

export function useDragColumnChange(
  cb: (e: DragStoppedEvent, newColumnOrder: any) => void
) {
  const columnOrderRef = React.useRef<string[]>([]);
  const onDragStarted = (e: DragStartedEvent) => {
    columnOrderRef.current = e.columnApi.getColumnState().map((c) => c.colId);
  };
  const onDragStopped = (e: DragStoppedEvent) => {
    const newColumnOrder = e.columnApi.getColumnState().map((c) => c.colId);
    const sameOrder = columnOrderRef.current.every(
      (c, i) => c === newColumnOrder[i]
    );

    if (!sameOrder) {
      cb(e, newColumnOrder);
    }
  };

  return { onDragStarted, onDragStopped };
}

export function availabilityAsTimeString(percentage: number): string {
  const outage = (100 - percentage) / 100.0;
  const seconds_outage = outage * SECONDS_PER_YEAR;
  if (seconds_outage < 60) {
    return `${seconds_outage.toFixed(0)} secs/year`;
  } else if (seconds_outage < 60 * 60) {
    return `${(seconds_outage / 60).toFixed(1)} mins/year`;
  } else if (seconds_outage < 60 * 60 * 24) {
    return `${(seconds_outage / (60 * 60)).toFixed(1)} hrs/year`;
  } else {
    return `${(seconds_outage / (60 * 60 * 24)).toFixed(1)} days/year`;
  }
}

export const withLineBreaks = (str: string) => {
  const lines = str.split('\n');
  const lineCount = lines.length - 1;
  return lines.map((line, idx) => (
    <>
      {line}
      {idx < lineCount ? <br /> : null}
    </>
  ));
};

/*
 * Convert a list of strings to a human-readable string. All entries
 * except the last should be joined by a comma, and the last should
 * joined with ' and '.
 */
export const listToString = (lst, conjunction = 'and') => {
  const spacedConjunction = ` ${conjunction} `;
  if (lst.length <= 1) {
    return lst.join('');
  } else {
    const commaLst = [lst.slice(0, -1).join(', '), lst.slice(-1)];
    return commaLst.join(spacedConjunction);
  }
};

/*
 * Convert a temperature in kelvin to fahrenheit.
 *
 * Copied from the Python code
 * kelvinToFahrenheit(256) === 1.13
 * kelvinToFahrenheit(279.2) === 42.89
 */
function kelvinToFahrenheit(k: number): number {
  return (k - 273.15) * 1.8 + 32;
}

/*
 * Convert a temperature from Kelvin to the appropriate display format.
 *
 * When the height units are metric display in deg Celsius
 * else display in deg Farenheit
 */
export function temperatureFromKelvin(
  temp: number | 'N/A',
  heightUnits: 'ft' | 'm'
): string {
  if (temp === 'N/A') {
    return 'N/A';
  }
  let scaledValue = temp - 273.15;
  let units = 'C';
  if (heightUnits === 'ft') {
    scaledValue = kelvinToFahrenheit(temp);
    units = 'F';
  }
  return `${scaledValue.toFixed(1)}° ${units}`;
}

// Multiplier to scale the unavailability values
// based on the time string.
// These don't represent the actual scale for the unit,
// but they do provide sufficient scaling to sort the
// values correctly
const unavailabilityMultiplier = {
  'secs/year': 1,
  'mins/year': 60,
  'hrs/year': 3600,
  'days/year': 86400,
};

/**
 * Return an unavailability string as a numeric time value
 */
function unavailabilityValue(unavailability: string): number {
  const [numberStr, units] = unavailability.split(' ');
  return parseFloat(numberStr) * unavailabilityMultiplier[units];
}

/**
 * Compare 2 unavailability strings (such as "2.2 mins/year")
 *
 * Used for sorting the rows in a column in the grid control
 */
export function compareUnavailability(str1: string, str2: string): number {
  const num1 = unavailabilityValue(str1);
  const num2 = unavailabilityValue(str2);
  return num1 - num2;
}

/**
 * Compare 2 strings which may contain numbers.
 *
 * Used for sorting the rows in a column in the grid control
 */
export function compareNumbers(str1: string, str2: string): number {
  const val1 = Number(str1);
  const val2 = Number(str2);
  const val1IsNaN = isNaN(val1);
  const val2IsNaN = isNaN(val2);
  if (val1IsNaN && val2IsNaN) {
    return str1.localeCompare(str2);
  }
  if (val1IsNaN && !val2IsNaN) {
    return -1;
  }
  if (!val1IsNaN && val2IsNaN) {
    return 1;
  }
  return val1 - val2;
}

export function otherEndName(name: string): string {
  if (name === 'local') {
    return 'remote';
  } else {
    return 'local';
  }
}

export function displayMhzAsGhz(value: number): string {
  return `${(value / 1000).toFixed(2)} GHz`;
}

export function insideAntennaSector(
  beamdwidth,
  azimuth,
  locLat,
  locLng,
  remLat,
  remLng
) {
  const result = bearingToAzimuth(
    bearing(point([locLng, locLat]), point([remLng, remLat]))
  );
  const halfBeamwidth = beamdwidth / 2.0;
  const minBw = azimuth - halfBeamwidth;
  const maxBw = azimuth + halfBeamwidth;
  const minAngle = (360 + minBw) % 360;
  const maxAngle = (360 + maxBw) % 360;
  if (minAngle > maxAngle) {
    return result >= minAngle || result <= maxAngle;
  } else {
    return result >= minAngle && result <= maxAngle;
  }
}

export function checkValueInChoices(choices, value) {
  for (const choice of choices) {
    if (value === choice.value) {
      return true;
    }
  }
  return false;
}

export const findIndexAtRange = (rng, ranges) => {
  let left = 0;
  let right = ranges.length - 1;
  let closestIndex = -1;
  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midValue = ranges[mid];
    if (midValue === rng) {
      return mid; // Exact match found
    }
    if (
      closestIndex === -1 ||
      Math.abs(midValue - rng) < Math.abs(ranges[closestIndex] - rng)
    ) {
      closestIndex = mid;
    }
    if (midValue < rng) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }
  return closestIndex;
};

export function zip(a, b) {
  let result = [];
  for (let i = 0; i < a.length; ++i) {
    result.push([a[i], b[i]]);
  }
  return result;
}

export function range(n) {
  return [...Array(n).keys()];
}

export function identity(a) {
  return a;
}

/*
 * Return a list of {key, text value} choices
 * for a FormSelect control from a flat list
 * of values.
 */
export const makeChoices = (choices: any[]) => {
  if (choices == null) {
    return [];
  }
  return choices.map((v: any) => {
    return { key: v, text: v, value: v };
  });
};

export function supportsMesh(aps) {
  return aps.features
    .map((f) => f.properties)
    .filter((ap) => {
      try {
        const antennaId = ap.radios[0].antennas[0].lp_antenna_id;
        // look for a V5000 antenna
        return antennaId === 'd289296b-f730-41a4-b2c0-0410fb26d76d';
      } catch (e) {
        return false;
      }
    });
}

export function getDisplayValue(field: any, level: any) {
  const choice = level.choices.find((c: any) => c.value === level.value);
  if (choice != null) {
    return choice.text;
  }

  if (field.units != null) {
    return `${level.value} ${field.units}`;
  }

  return level.value;
}

export function extractBandwidth(bw: string) {
  const [freq, units] = bw.split(' ');
  if (units === 'kHz') {
    return parseFloat(freq) * KHZ_TO_MHZ;
  } else if (units === 'GHz') {
    return parseFloat(freq) * GHZ_TO_MHZ;
  } else {
    return parseFloat(freq);
  }
}
/**
 * Split an array based on a predicate function
 */
export function splitBy(arr, predicate) {
  const result = [];
  let curr = [];
  for (const elem of arr) {
    if (!predicate(elem)) {
      curr.push(elem);
    } else {
      result.push(curr);
      curr = [];
    }
  }
  if (curr.length > 0) {
    result.push(curr);
  }
  return result;
}

/**
 * Join an array with a given separator, but return an array
 * instead of a string (like the built-in js version does)
 */
export function joinObj(arrs, sep) {
  const result = [];
  for (const i in arrs) {
    result.push(...arrs[i]);
    if (i < arrs.length - 1) {
      result.push(sep);
    }
  }
  return result;
}

const applyFilters = (items, filter, featureSet, mapBounds) => {
  let filterFunc = null;
  if (filter.length >= 1 && featureSet) {
    try {
      const exp = new RegExp(filter, 'i');
      filterFunc = (i) =>
        exp.test(getName(i)) &&
        featureSet.has(i.id) &&
        mapContains(i, mapBounds);
    } catch (e) {
      console.error(e);
    }
  } else if (filter.length >= 1) {
    try {
      const exp = new RegExp(filter, 'i');
      filterFunc = (i) => exp.test(getName(i)) && mapContains(i, mapBounds);
    } catch (e) {
      console.error(e);
    }
  } else if (featureSet) {
    filterFunc = (i) => featureSet.has(i.id) && mapContains(i, mapBounds);
  }

  if (filterFunc) {
    return items.filter(filterFunc);
  } else {
    return items;
  }
};

export const filterItems = function (items, filter, mapBounds, drawnFeatures) {
  let kind = null;
  if (items && items.length >= 1) {
    kind = items[0].kind;
  }
  let featureSet = null;
  if (kind && drawnFeatures) {
    featureSet = new Set(
      drawnFeatures
        .map((i) => {
          const k = i.split('-', 1)[0];
          const id = i.slice(k.length + 1);
          return k === kind ? id : null;
        })
        .filter(Boolean)
    );
  }
  const filteredItems = applyFilters(items, filter, featureSet, mapBounds);

  // const start = Math.min(offset, filteredItems.length - LIMIT);
  let sortedItems;
  try {
    sortedItems = filteredItems.slice().sort(nameSort);
  } catch (e) {
    console.log(e);
    if (filteredItems && filteredItems.hasOwnProperty('features')) {
      sortedItems = filteredItems.features.slice().sort(nameSort);
    } else {
      return [];
    }
  }

  return sortedItems;
};

export function joinObjArr(arrs, seps, defaultSep) {
  const result = [];
  let n = 0;
  for (const i in arrs) {
    result.push(...arrs[i]);
    if (i < arrs.length - 1) {
      if (n < seps.length) {
        result.push(seps[n]);
        n += 1;
      } else {
        result.push(defaultSep);
      }
    }
  }
  return result;
}
