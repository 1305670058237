import React, { useCallback, Fragment, useEffect, useState } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { store } from 'src/store';
import messages from 'src/messages';
import { postWithAuth } from 'src/api';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { Form, Message } from 'semantic-ui-react';
import { setMapSiteName } from './map.reducer';
import {
  setSiteNameTemplate,
  DEFAULT_SITE_NAME_TEMPLATE,
} from 'src/pages/map/map.reducer';
import {
  generateSiteNameFromTemplate,
  validateSiteNameTemplate,
} from 'src/utils/mapUtils';

type SiteNameTemplateProps = {
  isPTPMode: boolean;
  isNetwork: boolean;
  intl: IntlShape;
};

const SiteNameTemplateInfoBox = ({ isNetwork }) => {
  const templates = [
    { template: 'Site#', example: 'Site1, Site2, Site3' },
    { template: '## Site', example: '01 Site, 02 Site, 03 Site' },
    {
      template: 'Site ### Tower',
      example: 'Site 001 Tower, Site 002 Tower, Site 003 Tower',
    },
  ];

  return (
    <Message info>
      <p>
        Use <strong>#</strong> as a number placeholder which will automatically
        increment for each new site.
      </p>
      <div>
        <p>Examples:</p>
        <ul style={{ paddingLeft: '20px' }}>
          {templates.map(({ template, example }) => (
            <li key={template}>
              <strong>{template}</strong> - {example}
            </li>
          ))}
        </ul>
      </div>
    </Message>
  );
};

const SiteNameTemplate = ({
  isPTPMode,
  isNetwork,
  intl,
}: SiteNameTemplateProps) => {
  const { formatMessage } = intl;

  const { projectId, networkSiteCount, subscriberSiteCount } = useSelector(
    (state) => state.mainFrame
  );
  const { mapSiteName, networkSiteNameTemplate, subscriberSiteNameTemplate } =
    useSelector((state) => state.map);

  const siteNameTemplate = isNetwork
    ? networkSiteNameTemplate
    : subscriberSiteNameTemplate;

  // Validate Site Name Template
  const siteNameTemplateError = validateSiteNameTemplate(siteNameTemplate);

  // We need to use useEffect here because we need to generate the site Name based from site name template.
  // We are also updating the site name on input change that's why we cannot put this logic without useEffect
  useEffect(() => {
    let siteName = '';
    if (siteNameTemplate) {
      siteName = generateSiteNameFromTemplate({
        isNetwork,
        siteNameTemplate,
        networkSiteCount,
        subscriberSiteCount,
      });
    }
    // Update site name based from site name template
    store.dispatch(setMapSiteName(siteName));
  }, [isNetwork, networkSiteCount, siteNameTemplate, subscriberSiteCount]);

  const saveSiteNameTemplate = (siteNameTemplate) => {
    const kind = isNetwork ? 'network_site' : 'subscriber_site';
    const template =
      siteNameTemplate.trim() ||
      (isNetwork
        ? DEFAULT_SITE_NAME_TEMPLATE.NETWORK
        : DEFAULT_SITE_NAME_TEMPLATE.SUBSCRIBER);

    postWithAuth(`project/${projectId}/sites/name`, { kind, template }, 'POST')
      .then(() => {
        console.log(`Successfully saved the site name template for ${kind}`);
      })
      .catch((err) => {
        console.error(
          `Failed to saved the site name template for ${kind}`,
          err
        );
      });
  };

  const debounceSaveSiteNameTemplate = useCallback(
    debounce(saveSiteNameTemplate, 500),
    []
  );

  return !isPTPMode ? (
    <Fragment>
      <Form.Input
        label={formatMessage(messages.siteNameTemplate)}
        name="siteNameTemplate"
        value={siteNameTemplate}
        error={
          siteNameTemplateError
            ? {
                content: siteNameTemplateError,
                pointing: 'above',
              }
            : null
        }
        onChange={(_, { value }) => {
          // Update Site Name Template in the store which will update this input value
          store.dispatch(setSiteNameTemplate({ isNetwork, value }));

          // Validate Site Name Template
          const siteNameTemplateError = validateSiteNameTemplate(value);
          if (siteNameTemplateError) return;

          // Save Site Name Template to the API
          debounceSaveSiteNameTemplate(value);
        }}
      />
      <SiteNameTemplateInfoBox isNetwork={isNetwork} />
      <Form.Input
        label={formatMessage(messages.siteName)}
        name="siteName"
        value={mapSiteName}
        error={!mapSiteName}
        onChange={(_, { value }) => {
          store.dispatch(setMapSiteName(value));
        }}
      />
    </Fragment>
  ) : null;
};

export default injectIntl(SiteNameTemplate);
