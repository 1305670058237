import { Field, Formik, Form as FormFormik } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Grid, Message, Modal } from 'semantic-ui-react';
import additionalMessages from '../../messages';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import useDebounce from 'src/hooks/useDebounce';
import filterWorker from '../../workers/filter.worker';
import { listToString, meterToAny } from 'src/utils/useful_functions';
import SemanticField from 'src/components/controls/SemanticField';
import { distanceBetweenTwoPoints } from '../pmp/aplayout/AccessPointProperties';
import { getWithAuth, postWithAuth } from 'src/api';
import { store } from 'src/store';

const MIN_PTP_LINK_LENGTH_METERS = 1;
const MAX_PTP_LINK_LENGTH_METERS = 250 * 1000;
function CreatePTPLinkModal({ intl, onClose }) {
  const [open, setOpen] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [remoteSites, setRemoteSites] = useState([]);
  const [selectedRemoteIds, setSelectedRemoteIds] = useState([]);
  const [filteredRemoteSites, setFilteredRemoteSites] = useState([]);
  const { formatMessage } = intl;
  const { projectId, networkSites, prefs } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const networkSitesSorted = networkSites.features
    .slice()
    .sort((site1, site2) =>
      site1.properties.name > site2.properties.name ? 1 : -1
    );
  const networkSitesMap = networkSites.features
    .slice()
    .reduce((map, siteObj) => {
      map[siteObj.properties.id] = siteObj;
      return map;
    }, {});
  const [remoteFilter, setRemoteFilter] = useState('');
  const remoteDebounceFilter = useDebounce(remoteFilter, 500);
  const initialValues = {
    localIds: get(networkSitesSorted, '0.properties.id', ''),
    remoteIds: [],
  };
  const [localId, setLocalId] = useState(
    get(networkSitesSorted, '0.properties.id', '')
  );
  const [ptpLinksMap, setPTPLinksMap] = useState({});
  const [warning, setWarning] = useState('');
  const constructDropdownSelectOptions = (
    networkSitesList,
    fromSiteId,
    prefs
  ) => {
    const siteDropdownData = [],
      siteSelectionArr = [];
    let siteSelectOptions = [];
    const { rangeUnits } = prefs;
    const fromSiteObj = networkSitesMap[fromSiteId];
    const { geometry } = fromSiteObj;
    const { coordinates } = geometry;
    const [fromLng, fromLat] = coordinates;
    networkSitesList.forEach((siteObj) => {
      const { properties, geometry } = siteObj;
      const { coordinates } = geometry;
      const [lng, lat] = coordinates;
      const { id, name } = properties;
      siteDropdownData.push({ key: id, text: name, value: id });
      const distanceInMeters = distanceBetweenTwoPoints(
        { longitude: fromLng, latitude: fromLat },
        { longitude: lng, latitude: lat }
      );
      if (
        distanceInMeters >= MIN_PTP_LINK_LENGTH_METERS &&
        distanceInMeters <= MAX_PTP_LINK_LENGTH_METERS
      ) {
        siteSelectionArr.push({
          id: id,
          name: name,
          distance: meterToAny(distanceInMeters, rangeUnits, 0, false),
        });
      }
    });
    //local sites, sort by name
    siteDropdownData.sort((site1, site2) => (site1.text > site2.text ? 1 : -1));
    //remote sites, sort by distance
    siteSelectionArr.sort((site1, site2) =>
      site1.distance > site2.distance ? 1 : -1
    );
    siteSelectOptions = siteSelectionArr.map((obj) => {
      const { id, name, distance } = obj;
      return (
        <option key={id} value={id}>
          {name} ({distance.toFixed(3)} {rangeUnits})
        </option>
      );
    });
    return { dropdown: siteDropdownData, select: siteSelectOptions };
  };

  const calculateLimits = (linkedSites: any[]) => {
    const mainFrame = store.getState().mainFrame;
    const currentPtpLinks = mainFrame.ptpLinks.features.length;
    const ptpLinksLimit = mainFrame.userLimits.ptp_links;
    if (linkedSites.length + currentPtpLinks > ptpLinksLimit) {
      setError(
        formatMessage(additionalMessages.limitExceededError, {
          entityName: 'PTP Link',
          limit: ptpLinksLimit,
        })
      );
    }
  };

  const networkSiteDropdownOptions = useMemo(() => {
    const { dropdown, select } = constructDropdownSelectOptions(
      networkSites.features,
      networkSitesSorted[0]['properties']['id'],
      prefs
    );
    setRemoteSites(select);
    setFilteredRemoteSites(select);
    return dropdown;
  }, [networkSites]);

  const filterFunction = useCallback((filter, remoteSites, localId) => {
    const worker = new filterWorker();
    const toSiteList = [];
    remoteSites.forEach((siteObj) => {
      toSiteList.push(networkSitesMap[siteObj.key]);
    });
    worker.onmessage = (m) => {
      const results = JSON.parse(m.data);
      const { select } = constructDropdownSelectOptions(
        results,
        localId,
        prefs
      );
      setFilteredRemoteSites(select);
    };
    worker.postMessage(
      JSON.stringify({
        command: 'filter',
        items: toSiteList,
        filter: filter,
      })
    );
    return () => {
      if (worker) {
        worker.terminate();
      }
    };
  }, []);

  useEffect(() => {
    filterFunction(remoteDebounceFilter, remoteSites, localId);
  }, [remoteDebounceFilter]);

  useEffect(() => {
    getWithAuth(`project/${projectId}/site/${localId}/graph/ptp`).then(
      ({ paths }) => {
        const linksMap = {};
        paths.forEach((path) => {
          const { local_site_id, remote_site_id } = path;
          if (localId === local_site_id) {
            linksMap[remote_site_id] = {
              name: get(networkSitesMap, `${remote_site_id}.properties.name`),
            };
          } else if (localId === remote_site_id) {
            linksMap[local_site_id] = {
              name: get(networkSitesMap, `${local_site_id}.properties.name`),
            };
          }
        });
        setPTPLinksMap(linksMap);
        if (!filteredRemoteSites.length) {
          setWarning('');
          setError('No network sites in range');
        } else {
          handleLocalIdChange(localId, selectedRemoteIds, linksMap);
        }
      }
    );
  }, [localId, projectId]);

  const validationSchema = Yup.object({
    localIds: Yup.string().required(
      formatMessage(additionalMessages.requiredSelectError, {
        fieldName: 'From network sites',
      })
    ),
  });

  const validateRemoteIds = (value) => {
    if (!value || !value.length) {
      setError('Please select network sites');
    }
    const ptpLinksLimit = store.getState().mainFrame.userLimits.ptp_links;
    const currentPtpLinksCount = store.getState().mainFrame.ptpLinksCount;
    if (ptpLinksLimit < currentPtpLinksCount + value?.length) {
      setError(
        formatMessage(additionalMessages.limitExceededError, {
          entityName: 'PTP Link',
          limit: ptpLinksLimit,
        })
      );
    }
  };

  function setNewWarning(linkedSites, selectedValueName) {
    let newWarning = '';
    if (linkedSites.length) {
      newWarning = `${selectedValueName} is already linked to ${listToString(
        linkedSites
      )}`;
    }
    setWarning(newWarning);
  }

  function handleLocalIdChange(value, remoteIds, linksMap) {
    const selectedValueName = networkSites.features.filter(
      (el) => el.properties.id === value
    )[0].properties.name;
    let linkedSites = [];
    for (let option of remoteIds) {
      if (!isEmpty(linksMap)) {
        if (linksMap[option] && option !== value) {
          const remoteSiteName = linksMap[option]['name'];
          linkedSites.push(remoteSiteName);
        }
      }
    }
    setNewWarning(linkedSites, selectedValueName);
  }

  return (
    <Modal
      onClose={onClose}
      size="tiny"
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>
        <FormattedMessage
          id="ptp.createPTP"
          defaultMessage="Create PTP Links"
        />
        <Button
          circular
          icon="close"
          title={formatMessage(additionalMessages.close)}
          floated="right"
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const { localIds, remoteIds } = values;
            const postObj = {
              local_site_id: localIds,
              remote_site_ids: remoteIds,
            };
            postWithAuth(`project/${projectId}/ptp`, postObj)
              .then((res) => {
                onClose();
              })
              .catch(({ detail }) => {
                setError(detail);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            return (
              <FormFormik className="ui form">
                <Grid>
                  <Grid.Row style={{ paddingTop: '0.5rem' }}>
                    <Grid.Column>
                      <SemanticField
                        fluid
                        search
                        selection
                        label={formatMessage(additionalMessages.from)}
                        component={Form.Dropdown}
                        options={networkSiteDropdownOptions}
                        onChange={(e, { value }) => {
                          setLocalId(value);
                          const { select } = constructDropdownSelectOptions(
                            networkSites.features,
                            value,
                            prefs
                          );
                          setRemoteSites(select);
                          setFilteredRemoteSites(select);
                        }}
                        name="localIds"
                        id="localIds"
                      ></SemanticField>
                      {formik.errors.localIds ? (
                        <Message className="flex-grow-0" negative>
                          <p>{formik.errors.localIds}</p>
                        </Message>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ paddingTop: '0.3rem' }}>
                    <Grid.Column style={{ height: '55vh' }}>
                      <Form.Input
                        className="flex-grow-0"
                        label={formatMessage(additionalMessages.to)}
                        name="remoteFilter"
                        placeholder={formatMessage(additionalMessages.filter)}
                        fluid
                        icon={{
                          name: 'cancel',
                          link: true,
                          onClick: () => {
                            formik.setFieldValue('remoteIds', []);
                            setRemoteFilter('');
                          },
                          title: formatMessage(additionalMessages.clearFilter),
                        }}
                        value={remoteFilter}
                        onChange={(e) => {
                          setRemoteFilter(e.target.value);
                        }}
                        autoFocus
                      ></Form.Input>
                      <Field
                        name="remoteIds"
                        as="select"
                        multiple
                        style={{ height: '77%' }}
                        validate={validateRemoteIds}
                        onChange={(e) => {
                          const { options } = e.target;
                          const localSiteName = get(
                            networkSitesMap,
                            `${localId}.properties.name`
                          );
                          const selected = [];
                          let linkedSites = [];
                          for (let option of options) {
                            if (option.selected) {
                              const selectedValue = option.value;
                              selected.push(selectedValue);
                              if (!isEmpty(ptpLinksMap)) {
                                if (ptpLinksMap[selectedValue]) {
                                  const remoteSiteName =
                                    ptpLinksMap[selectedValue]['name'];
                                  linkedSites.push(remoteSiteName);
                                }
                              }
                            }
                          }
                          setSelectedRemoteIds(selected);
                          setNewWarning(linkedSites, localSiteName);
                          calculateLimits(selected);
                          formik.setFieldValue('remoteIds', selected);
                          setError('');
                          validateRemoteIds(selected);
                        }}
                      >
                        {filteredRemoteSites}
                      </Field>
                      {formik.errors.remoteIds &&
                      formik.values.remoteIds.length === 0 ? (
                        <Message className="flex-grow-0" negative>
                          <p>{formik.errors.remoteIds}</p>
                        </Message>
                      ) : null}
                      {error !== '' && (
                        <Message error className="create-ptp-warning">
                          <p>{error}</p>
                        </Message>
                      )}
                      {isEmpty(formik.errors) &&
                        error === '' &&
                        warning != '' && (
                          <Message warning className="create-ptp-warning">
                            <p>{warning}</p>
                          </Message>
                        )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div className="modal-actions">
                  <Button onClick={onClose}>
                    {formatMessage(additionalMessages.cancel)}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!formik.isValid || error !== ''}
                    loading={formik.isSubmitting}
                    color="blue"
                  >
                    {formatMessage(additionalMessages.ok)}
                  </Button>
                </div>
              </FormFormik>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}

export default CreatePTPLinkModal;
