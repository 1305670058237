import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { RootStateOrAny, store } from '../../store';
import { discardProjectChanges, getWithAuth, sortProjects } from 'src/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { SaveProjectDialog, doSave } from './SaveProjectDialog';
import { projectsLoaded, uiSet, unloadProject } from './mainframe.reducer';
import additionalMessages from '../../messages';
import { runWithConfirmation } from 'src/utils/useful_functions';
import NewProjectDialog from '../project/NewProjectDialog';

export const FileMenu = (props) => {
  const [openSaveAs, setOpenSaveAs] = useState(false);
  const [openNewProjectPopup, setOpenNewProjectPopup] = useState(false);
  return (
    <>
      <Dropdown
        title=""
        text="Projects"
        floating
        labeled
        className="mr-2"
        icon={{ name: '' }}
      >
        <Dropdown.Menu>
          <FileMenuList
            setOpenSaveAs={setOpenSaveAs}
            setOpenNewProjectPopup={setOpenNewProjectPopup}
            {...props}
          />
        </Dropdown.Menu>
      </Dropdown>
      <SaveProjectDialog
        openSaveAs={openSaveAs}
        setOpenSaveAs={setOpenSaveAs}
      />
      <NewProjectDialog
        isOpen={openNewProjectPopup}
        onClose={() => setOpenNewProjectPopup(false)}
      />
    </>
  );
};

const FileMenuList = (props) => {
  const { projectId, projectName, projectModified, permissionWrite, loggedIn } =
    useSelector((state: RootStateOrAny) => state.mainFrame);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  const { setOpenSaveAs, setOpenNewProjectPopup } = props;

  async function getProjectsList() {
    const projects = await getWithAuth('projects');
    return projects;
  }
  let iconColour = 'grey';

  if (permissionWrite && projectId) {
    if (projectModified) {
      // TODO: Handle when the project has been modified
      iconColour = 'red';
    } else if (loggedIn) {
      iconColour = 'black';
    }
  }

  const openNewProjectPopup = () => {
    setOpenNewProjectPopup(true);
  };

  const discardChanges = (e) => {
    //const { projectId } = props;
    runWithConfirmation({
      header: `${formatMessage(
        additionalMessages.discardChanges
      )}: ${projectName}`,
      onConfirm: () => {
        store.dispatch(uiSet({ locked: true }));
        discardProjectChanges(projectId)
          .then(() => {
            store.dispatch(unloadProject());
            //fix for LPWEB-420, we need explicitly load the projects
            // when we discard changes from /projects page
            if (pathname === '/projects') {
              getProjectsList().then((projects) => {
                if (projects) {
                  // Sort the projects so that the recently accessed projects
                  // are at the start of the list
                  projects.sort(sortProjects);
                  store.dispatch(projectsLoaded(projects));
                }
              });
            } else {
              navigate('/projects');
            }
          })
          .catch((err) => {
            console.error(err);
            store.dispatch(uiSet({ locked: false }));
          });
      },
    });
  };

  return (
    <>
      <Dropdown.Item
        text="New"
        icon={{ name: 'plus square outline' }}
        onClick={() => openNewProjectPopup()}
        title="Create a new project"
      ></Dropdown.Item>
      <Dropdown.Item
        text="Project List"
        icon={{ name: 'folder open outline' }}
        onClick={() => navigate('/projects')}
        title="Open an existing project"
      ></Dropdown.Item>
      {/* <Dropdown.Divider /> */}
      <Dropdown.Item
        title="Save changes to the current project"
        icon={{
          name: 'save',
          color: iconColour,
        }}
        onClick={() => doSave(projectName, projectId)}
        text="Save"
        disabled={!(loggedIn && permissionWrite) || !projectModified}
      />
      <Dropdown.Item
        title="Save the current project with a new name"
        icon={{
          name: 'save',
        }}
        onClick={() => setOpenSaveAs(true)}
        text="Save As..."
        disabled={!(loggedIn && permissionWrite)}
      />
      <Dropdown.Item
        title="Discard changes made to the current project"
        icon={{
          name: 'history',
          color: iconColour,
        }}
        onClick={discardChanges}
        text="Discard Changes"
        disabled={!(loggedIn && permissionWrite) || !projectModified}
      />
    </>
  );
};
