import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import subscriberSiteIcon from '../../../src/images/menu/subscriber-site.svg';
import networkSiteIcon from '../../../src/images/menu/network-site.svg';
import messages from 'src/messages';
function ImportMenu(props) {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  return (
    <Dropdown
      title=""
      text={formatMessage({
        id: 'export.export',
        defaultMessage: 'Import',
      })}
      onMouseDown={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      floating
      labeled
      className="mr-2"
      icon={{ name: '' }}
    >
      <Dropdown.Menu>
        {open ? <ImportMenuList setOpen={setOpen} {...props} /> : null}
      </Dropdown.Menu>
    </Dropdown>
  );
}
const ImportMenuList = (props) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Dropdown.Item
        image={{
          src: networkSiteIcon,
          style: { height: '13px', marginTop: '0px' },
        }}
        text={formatMessage(messages.networkSites)}
        onClick={() => props.setImportType('network_sites')}
      ></Dropdown.Item>
      <Dropdown.Item
        image={{
          src: subscriberSiteIcon,
          style: { height: '13px', marginTop: '0px' },
        }}
        text={formatMessage(messages.subscriberSites)}
        onClick={() => props.setImportType('subscriber_sites')}
      ></Dropdown.Item>
    </>
  );
};

export default ImportMenu;
