import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Input, Modal } from 'semantic-ui-react';
import additionalMessages from '../../messages';
import { useSelector } from 'react-redux';
import { RootStateOrAny, store } from 'src/store';
import { saveAsProject } from 'src/api';
import { uiSet, updateProjectsList } from './mainframe.reducer';

export const doSave = (name, projectId) => {
  store.dispatch(uiSet({ locked: true }));
  saveAsProject(projectId, name)
    .then((res) => {
      store.dispatch(
        uiSet({
          projectId: res.id,
          projectName: res.name,
          projectModified: false,
        })
      );
      store.dispatch(updateProjectsList());
    })
    .catch((err) => {
      console.error(err);
      store.dispatch(uiSet({ locked: false }));
    });
};

export const SaveProjectDialog = (props) => {
  const { formatMessage } = useIntl();

  const { projectName, projectId } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  const [modifiedName, setModifiedName] = useState(null);
  const displayProjectName = modifiedName === null ? projectName : modifiedName;
  const { openSaveAs, setOpenSaveAs } = props;

  const onNameChange = (e, { value }) => {
    setModifiedName(value);
  };

  const onNameKey = (e) => {
    const name = modifiedName || projectName;
    if (e.key === 'Enter' && name) {
      doSave(name, projectId);
    }
  };

  const cancelSave = () => {
    setModifiedName(null);
    setOpenSaveAs(false);
  };

  const save = () => {
    doSave(modifiedName || projectName, projectId);
    cancelSave();
  };

  return (
    <Modal open={openSaveAs} onClose={cancelSave} size="mini">
      <Modal.Header>
        {formatMessage(additionalMessages.saveProject)}
      </Modal.Header>
      <Modal.Content>
        <Input
          label="Name"
          value={displayProjectName}
          onChange={onNameChange}
          onKeyPress={onNameKey}
          fluid
          autoFocus
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cancelSave}>
          {formatMessage(additionalMessages.cancel)}
        </Button>
        <Button onClick={save} color="blue" disabled={!displayProjectName}>
          {formatMessage(additionalMessages.ok)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
