import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  export: {
    statusRefetch: false,
  },
};

const headerMenuSlice = createSlice({
  name: 'headermenu',
  initialState,
  reducers: {
    setStatusRefetch: (state, action) => {
      state.export.statusRefetch = action.payload;
    },
  },
});

export const { setStatusRefetch } = headerMenuSlice.actions;
export default headerMenuSlice.reducer;
