import React from 'react';
import { toast } from 'react-toastify';
import { Message } from 'semantic-ui-react';
import { getWithAuth } from '../../api';
import additionalMessages from '../../messages';
import { runWithConfirmation } from '../../utils/useful_functions';
import Download from '../../components/Download';
import { store } from 'src/store';
import { setStatusRefetch } from './headermenu.reducer';

const getDownloadFunction = (extension) => {
  return { lpp: downloadProject, kmz: downloadProjectKmz }[extension];
};

export const reportSocketHandler = (socketMsg) => {
  const { id, payload, message, status } = socketMsg;
  const { name, extension } = payload;

  const downloadFunc = getDownloadFunction(extension);
  if (status === 'ready') {
    store.dispatch(setStatusRefetch(true));
    toast(
      <Download
        clickHandler={() => downloadFunc(id, name)}
        text={`${name}.${extension} is ready for download!`}
      />,
      { autoClose: false }
    );
  } else if (message) {
    console.error('Export error:', status, message);
  }
};

export function downloadProject(id: string, name: string) {
  getWithAuth(`project/${id}/download`, 'GET', true)
    .then((res) => {
      const blobObj = new Blob([res], { type: 'text/lpp' });
      const blobUrl = window.URL.createObjectURL(blobObj);
      let a = document.createElement('a');
      a.href = blobUrl;
      a.download = `${name}.lpp`;
      a.click();
    })
    .catch(console.error);
}

export function downloadProjectKmz(id: string, name: string) {
  getWithAuth(`project/${id}/download_kmz`, 'GET', true)
    .then((res) => {
      const blobObj = new Blob([res], { type: 'text/kmz' });
      const blobUrl = window.URL.createObjectURL(blobObj);
      let a = document.createElement('a');
      a.href = blobUrl;
      a.download = `${name}.kmz`;
      a.click();
    })
    .catch(console.error);
}

export function downloadReport(projectId: string, id: string, name: string, kind: string) {
  return new Promise((resolve, reject) => {
    getWithAuth(`project/${projectId}/reports/download/${id}`, 'GET', true)
      .then((resp) => {
        const blobObj = new Blob([resp], { type: kind });
        const blobUrl = window.URL.createObjectURL(blobObj);
        let a = document.createElement('a');
        a.href = blobUrl;
        a.download = `${name}.${kind}`;
        a.click();
        resolve(true);
      })
      .catch((err) => {
        console.error(err);
        reject(true);
      });
  });
}


export const exportProject = (
  id: string,
  name: string,
  formatMessage: Function
) => {
  runWithConfirmation({
    header: formatMessage(additionalMessages.exportProject),
    message: `Are you sure you want to export ${name}`,
    onConfirm: () => {
      getWithAuth(`project/${id}/export`, 'GET')
        .then(() => {
          toast(<Message>{name} export started</Message>);
        })
        .catch((err) => {
          toast(<Message>{name} export failed to start</Message>);
          console.log('err', err);
        });
    },
  });
};

export const exportKmz = (
  id: string,
  name: string,
  formatMessage: Function
) => {
  runWithConfirmation({
    header: formatMessage(additionalMessages.exportKmz),
    message: `Are you sure you want to export ${name}`,
    onConfirm: () => {
      getWithAuth(`project/${id}/export_kmz`, 'GET')
        .then(() => {
          toast(<Message>{name} export started</Message>);
        })
        .catch((err) => {
          toast(<Message>{name} export failed to start</Message>);
          console.log('err', err);
        });
    },
  });
};
