import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Header, Icon, Loader } from 'semantic-ui-react';
import { ReportDownloadButton } from '../ptp/PTPLinkPanel';
import BomModal from 'src/components/BomModal';
import AccessPointBomModal from 'src/pages/equipment/accesspoint/BomModal';
import messages from 'src/messages';
import { isEmpty } from 'lodash';
import { store } from 'src/store';
import {
  setSelectedPMPLinks,
  uiConfirmAction,
} from '../mainframe/mainframe.reducer';
import { postWithAuth } from 'src/api';
import { useSelector } from 'react-redux';

function TitleAndActions({
  projectId,
  id,
  kind,
  name,
  dirty,
  powerFromSwitch,
  siteId,
  children,
  formatMessage,
  localProduct,
  modified,
  isSubmitting,
  errors,
}) {
  const chevronLink = kind === 'access_point' ? '/aps' : '/subscribers';
  const installationReportURL: `/${string}` = `/${kind}/${id}/installation`;
  const proposalReportURL: `/${string}` = `/${kind}/${id}/proposal`;
  let disableReportButton = modified || dirty;
  const navigate = useNavigate();
  const permissionWrite = useSelector(
    (state) => state.mainFrame.permissionWrite
  );

  const message =
    kind === 'access_point'
      ? formatMessage(messages.deleteAccessPoints)
      : formatMessage(messages.deletePMPLinks);
  const type = kind === 'access_point' ? 'access_points' : 'subscribers';
  const deleteEntity = async () => {
    console.log(siteId);
    store.dispatch(
      uiConfirmAction({
        header: message,
        message: formatMessage(messages.confirm),
        size: 'mini',
        onConfirm: () => {
          postWithAuth(`project/${projectId}/${type}`, [id], 'DELETE')
            .then((el) => {
              console.log(el);
              store.dispatch(setSelectedPMPLinks([]));
              navigate(`${chevronLink}`);
            })
            .catch(console.error);
        },
      })
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <Header floated="left" style={{ flexGrow: 1 }}>
        {children}
      </Header>
      <Form.Group
        style={{
          flexDirection: 'row-reverse',
          alignItems: 'center',
          marginLeft: 'auto',
        }}
      >
        <Form.Button
          compact
          type="submit"
          className="save"
          primary
          disabled={!modified || isSubmitting || !isEmpty(errors)}
        >
          {formatMessage(messages.apply)}
        </Form.Button>
        <ReportDownloadButton
          projectId={projectId}
          name={name}
          kind="Installation"
          endpoint={installationReportURL}
          title={formatMessage(messages.installationReport)}
          disabled={disableReportButton}
        >
          <Icon className="installation-report-icon" size="large" />
        </ReportDownloadButton>
        {kind === 'access_point' && (
          <ReportDownloadButton
            projectId={projectId}
            name={name}
            kind="Proposal"
            endpoint={proposalReportURL}
            title={formatMessage(messages.salesReport)}
            disabled={disableReportButton}
          >
            <Icon className="proposal-report-icon" size="large" />
          </ReportDownloadButton>
        )}
        {kind === 'access_point' ? (
          <AccessPointBomModal
            objId={id}
            kind="access_point"
            localProduct={localProduct}
            powerFromSwitch={powerFromSwitch}
            siteId={siteId}
            url={`project/${projectId}/access_point/${id}/bom`}
            modified={modified}
            name={name}
          />
        ) : (
          <BomModal
            objId={id}
            kind="subscriber"
            localProduct={localProduct}
            url={`project/${projectId}/subscriber/${id}/bom`}
            modified={modified}
            name={name}
          />
        )}
        {permissionWrite && (
          <div
            style={{
              paddingRight: '0.5rem',
              marginRight: '0.5rem',
              borderRight: '1px #eaeced solid',
            }}
          >
            <Button
              compact
              basic
              type="button"
              title={'Delete'}
              onClick={() => deleteEntity()}
              icon="trash alternate"
              color="red"
              style={{ margin: '0' }}
            ></Button>
          </div>
        )}
        {dirty && (
          <Header style={{ margin: '0' }}>
            <Loader active inline size="tiny" />{' '}
            {formatMessage(messages.calculatingResults)}
          </Header>
        )}
      </Form.Group>
    </div>
  );
}

export default TitleAndActions;
