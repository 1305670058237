import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { RootStateOrAny } from '../../store';
import { FCCLicenseCoordinationPopup } from './FCCLicenseCoordinationPopup';
import { useQuery } from '@tanstack/react-query';
import { getWithAuth } from 'src/api';
import { downloadReport } from './reportHelpers';

const ReportMenu = () => {
  const { formatMessage } = useIntl();
  const { projectId, projectName, permissionWrite } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  const { data, refetch } = useQuery(
    ['ReportMenu', projectId],
    async () => {
      return await getWithAuth(`project/${projectId}/reports/enabled`);
    },
    { retry: false }
  );

  const enable60Ghz = data?.cnwave_cnm;

  const [openFccModal, setOpenFccModal] = useState(false);
  const fccLicenseLinksQuery = useQuery(
    ['FCCLicense', projectId],
    async () => {
      return await getWithAuth(`project/${projectId}/reports/fcc_license`);
    },
    { retry: false }
  );
  const fccLinksData: Report[] = fccLicenseLinksQuery.data;
  const fccLinksAvailable = fccLinksData?.length > 0;

  const download60GHzReport = (type) => {
    getWithAuth(`project/${projectId}/reports/cnm_export/${type}`).then(
      (taskId) => {
        downloadReport(
          projectId,
          taskId,
          projectName + '_cnMaestro' + type,
          'csv'
        )
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    );
  };
  const downloadProjectProposalReport = async () => {
    getWithAuth(`project/${projectId}/reports/project_proposal_report/`)
      .then((taskId) => {
        downloadReport(projectId, taskId, `${projectName}proposal`, 'pdf')
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Dropdown
        title=""
        text={formatMessage({
          id: 'reports.report',
          defaultMessage: 'Reports',
        })}
        floating
        labeled
        className="mr-2"
        icon={{ name: '' }}
        onOpen={() => refetch()}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            icon={{ name: 'file pdf outline' }}
            text="Project Proposal Report"
            className="projproposal-reports-menu"
            onClick={() => {
              downloadProjectProposalReport();
            }}
          ></Dropdown.Item>
          <Dropdown.Item
            icon={{ name: 'file excel outline' }}
            text="FCC License Coordination"
            onClick={() => setOpenFccModal(true)}
            disabled={!fccLinksAvailable}
          ></Dropdown.Item>

          <Dropdown
            text="60GHz cnWave cnMaestro Export"
            item
            simple
            direction="left"
            className="sixtyghz-reports-menu"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  download60GHzReport('nodes');
                }}
                disabled={!enable60Ghz || !permissionWrite}
              >
                Nodes
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  download60GHzReport('links');
                }}
                disabled={!enable60Ghz || !permissionWrite}
              >
                Links
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Dropdown.Menu>
      </Dropdown>
      {fccLinksAvailable ? (
        <FCCLicenseCoordinationPopup
          openFccModal={openFccModal}
          setOpenFccModal={setOpenFccModal}
          rowData={fccLinksData}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default ReportMenu;
