import destination from '@turf/destination';
import { point, points, lineString, polygon } from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';
import pointsWithinPolygon from '@turf/points-within-polygon';
const applyFilters = (items, filter, featureSet, mapBounds) => {
  let filterFunc = null;
  if (filter.length >= 1 && featureSet) {
    try {
      const exp = new RegExp(filter, 'i');
      filterFunc = (i) =>
        exp.test(getName(i)) &&
        featureSet.has(i.id) &&
        mapContains(i, mapBounds);
    } catch (e) {
      console.error(e);
    }
  } else if (filter.length >= 1) {
    try {
      const exp = new RegExp(filter, 'i');
      filterFunc = (i) => exp.test(getName(i)) && mapContains(i, mapBounds);
    } catch (e) {
      console.error(e);
    }
  } else if (featureSet) {
    filterFunc = (i) => featureSet.has(i.id) && mapContains(i, mapBounds);
  }

  if (filterFunc) {
    return items.filter(filterFunc);
  } else {
    return items;
  }
};

export const filterItems = function (items, filter, mapBounds, drawnFeatures) {
  let kind = null;
  if (items && items.length >= 1) {
    kind = items[0].kind;
  }
  let featureSet = null;
  if (kind && drawnFeatures) {
    featureSet = new Set(
      drawnFeatures
        .map((i) => {
          const k = i.split('-', 1)[0];
          const id = i.slice(k.length + 1);
          return k === kind ? id : null;
        })
        .filter(Boolean)
    );
  }
  const filteredItems = applyFilters(items, filter, featureSet, mapBounds);

  // const start = Math.min(offset, filteredItems.length - LIMIT);
  let sortedItems;
  try {
    sortedItems = filteredItems.slice().sort(nameSort);
  } catch (e) {
    console.log(e);
    if (filteredItems && filteredItems.hasOwnProperty('features')) {
      sortedItems = filteredItems.features.slice().sort(nameSort);
    } else {
      return [];
    }
  }

  return sortedItems;
};

/*
 * Return true if the item is contained inside the map bounds.
 */
const mapContains = (item, mapBounds) => {
  if (mapBounds) {
    if (item.kind === 'network_site' || item.kind === 'subscriber_site') {
      return mapContainsSite(item, mapBounds);
    } else if (item.kind.indexOf('link') >= 0) {
      return mapContainsLink(item, mapBounds);
    } else if (item.kind === 'access_point') {
      return mapContainsAccessPoint(item, mapBounds);
    }
  }
  return true;
};

/*
 * Return true if the access point is contained inside or intersects the map bounds.
 */
const mapContainsAccessPoint = (item, mapBounds) => {
  // we need to test all of the antennas for cnRanger
  return (
    pointInside(item.latitude, item.longitude, mapBounds) ||
    item.radios.some((radio) =>
      radio.antennas.some((antenna) =>
        sectorInBounds(
          item.latitude,
          item.longitude,
          radio.sm_range,
          radio.range_units,
          antenna,
          mapBounds
        )
      )
    )
  );
};

/*
 * Return true if the antenna sector is inside the map bounds
 */
const sectorInBounds = (
  siteLat,
  siteLng,
  sm_range,
  range_units,
  antenna,
  mapBounds
) => {
  const { azimuth, beamwidth, latitude, longitude } = antenna;
  // One of the following will be true
  // for the sector to be visible in the map:
  // - The sector lat/long will be inside the bounds
  // - The corner points of the bounds will be inside the sector
  // - the sector edges will intersect the bounds
  const halfBeamwidth = beamwidth / 2;
  const centre = point([longitude || siteLng, latitude || siteLat]);
  const sectorP1 = destination(centre, sm_range, azimuth - halfBeamwidth, {
    units: range_units,
  });
  const sectorP2 = destination(centre, sm_range, azimuth, {
    units: range_units,
  });
  const sectorP3 = destination(centre, sm_range, azimuth + halfBeamwidth, {
    units: range_units,
  });

  const boundsLine = polygon([
    [
      [mapBounds.west, mapBounds.south],
      [mapBounds.west, mapBounds.north],
      [mapBounds.east, mapBounds.north],
      [mapBounds.east, mapBounds.south],
      [mapBounds.west, mapBounds.south],
    ],
  ]);

  const sector = polygon([
    [
      [longitude || siteLng, latitude || siteLat],
      sectorP1.geometry.coordinates,
      sectorP2.geometry.coordinates,
      sectorP3.geometry.coordinates,
      [longitude || siteLng, latitude || siteLat],
    ],
  ]);
  const boundaryPoints = points([
    [mapBounds.west, mapBounds.south],
    [mapBounds.west, mapBounds.north],
    [mapBounds.east, mapBounds.north],
    [mapBounds.east, mapBounds.south],
  ]);
  return (
    pointInside(latitude || siteLat, longitude || siteLng, mapBounds) ||
    booleanIntersects(sector, boundsLine) ||
    pointsWithinPolygon(boundaryPoints, sector).features.length > 0
  );
};

/*
 * Return true if the site is contained inside the map bounds.
 */
const mapContainsSite = (item, mapBounds) => {
  return pointInside(item.latitude, item.longitude, mapBounds);
};

/*
 * Return true if the link is contained inside or intersects the map bounds.
 */
const mapContainsLink = (item, mapBounds) => {
  const linkLine = lineString([
    [item.loc_lng, item.loc_lat],
    [item.rem_lng, item.rem_lat],
  ]);
  const boundsLine = polygon([
    [
      [mapBounds.west, mapBounds.south],
      [mapBounds.west, mapBounds.north],
      [mapBounds.east, mapBounds.north],
      [mapBounds.east, mapBounds.south],
      [mapBounds.west, mapBounds.south],
    ],
  ]);
  return (
    pointInside(item.loc_lat, item.loc_lng, mapBounds) ||
    pointInside(item.rem_lat, item.rem_lng, mapBounds) ||
    booleanIntersects(linkLine, boundsLine)
  );
};

/**
 * Simple name sort
 *
 * The sorting method used in LINKPlanner is too slow
 */
const nameSort = (s1, s2) => {
  const name1 = getName(s1).toLowerCase();
  const name2 = getName(s2).toLowerCase();

  if (name1 > name2) {
    return 1;
  } else if (name1 < name2) {
    return -1;
  } else {
    return 0;
  }
};

export const getName = (o) => {
  if (o) {
    if (o.username) {
      return o.username;
    } else if (o.kind === 'access_point') {
      return o.default_name ? o.default_name : o.name;
    } else if (o.type === 'Feature') {
      return o.properties.name;
    }
    return o.name;
  }
  return 'Undefined';
};
/*
 * Return true if the point is inside the mapBounds
 */
const pointInside = (lat, long, mapBounds) => {
  return (
    long >= mapBounds.west &&
    long <= mapBounds.east &&
    lat >= mapBounds.south &&
    lat <= mapBounds.north
  );
};
