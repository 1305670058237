import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { postWithAuth } from 'src/api';
import { Button, Checkbox, Message, Modal } from 'semantic-ui-react';
import additionalMessages from '../../messages';
import { useIntl } from 'react-intl';
import { downloadReport } from './reportHelpers';
import { useMutation } from '@tanstack/react-query';
import messages from '../../messages';

export const FCCLicenseCoordinationPopup = (props) => {
  const { projectId, projectName } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const { formatMessage } = useIntl();
  const { openFccModal, setOpenFccModal, rowData } = props;

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [errMessage, setErrMessage] = useState(null);
  const [isChecked, setIsChecked] = useState(
    Array(rowData?.length).fill(false)
  );
  const [selected, setSelected] = useState([]);

  const createReport = async () => {
    postWithAuth(`project/${projectId}/reports/fcc_license`, {
      links: selected,
    });
  };

  useEffect(() => {
    setErrMessage(null);
  }, [openFccModal]);

  const { mutate, isLoading } = useMutation(createReport, {
    onSuccess: (taskId: any) => {
      if (taskId) {
        downloadReport(
          projectId,
          taskId,
          projectName + ' Coordination',
          'csv'
        ).then((res) => {
          handleCancel();
        });
      } else {
        setErrMessage('Error downloading report');
      }
    },
    onError: (err: any) => {
      setErrMessage(err.detail);
    },
  });

  const handleOk = () => {
    mutate();
  };

  const selectionChange = (data, id, i) => {
    const newChecked = [...isChecked];
    newChecked[i] = data.checked;
    if (data.checked) {
      selected.push(id);
      setSelected(selected);
    } else {
      const newSelected = selected.filter(function (item) {
        return item !== id;
      });
      setSelected(newSelected);
    }
    setIsChecked(newChecked);
    if (newChecked.every((value) => value === true)) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }
  };

  const checkAll = () => {
    setIsCheckedAll(true);
    setIsChecked(Array(rowData?.length).fill(true));
    setSelected(rowData.map((el: any) => el.id));
  };

  const unCheckAll = () => {
    setIsCheckedAll(false);
    setIsChecked(Array(rowData?.length).fill(false));
    setSelected([]);
  };

  const handleCancel = () => {
    unCheckAll();
    setOpenFccModal(false);
  };

  return (
    <Modal open={openFccModal} size="mini">
      <Modal.Header>
        {'FCC License Coordination'}
        <Button
          circular
          icon="close"
          title={formatMessage(messages.close)}
          floated="right"
          onClick={() => {
            setOpenFccModal(false);
          }}
        />
      </Modal.Header>
      <Modal.Content>
        {errMessage ? <Message error>{errMessage}</Message> : ''}

        <Message info>
          Which links would you like to request licenses for: <br />
          (List contains licensed band links only)
        </Message>
        <div className="fcc-license-links">
          <b>Link Name</b>
          {rowData.map((el: any, i) => (
            <Checkbox
              label={el.identifier}
              onChange={(e, data) => selectionChange(data, el.id, i)}
              checked={isChecked[i]}
            ></Checkbox>
          ))}
        </div>
        <div className="d-flex">
          <Button disabled={isCheckedAll} onClick={checkAll}>
            Check All
          </Button>
          <Button disabled={!isCheckedAll} onClick={unCheckAll}>
            Uncheck All
          </Button>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancel}>
          {formatMessage(additionalMessages.cancel)}
        </Button>
        <Button
          color="blue"
          onClick={handleOk}
          disabled={!selected.length}
          loading={isLoading}
        >
          {formatMessage(additionalMessages.ok)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
