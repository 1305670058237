import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import MenuItem from './MenuItem';
import { nameSort } from 'src/utils/naming';

const PTPLinksMenuItem = ({ expandSidebar }) => {
  const ptpLinksAll = useSelector((state) => state.mainFrame.ptpLinks);
  if (!ptpLinksAll) {
    return null;
  }
  const ptpLinks = [...ptpLinksAll.features];
  ptpLinks.sort(nameSort);
  const label = expandSidebar ? (
    <FormattedMessage
      id="mainMenu.ptpLinks"
      defaultMessage="PTP Links"
      description="PTP Links main menu command"
    />
  ) : null;
  return (
    <MenuItem
      label={label}
      expandSidebar={expandSidebar}
      listItems={ptpLinks}
      path={'ptp'}
      kind={'ptp_link'}
      headerIconClass="ptp-link-icon"
      listIconClass={(item) => {
        const icon = item.icon;
        if (icon === 'link' || icon == null) {
          return 'ptp-link-menu-icon';
        }
        return `ptp-${icon.replaceAll('_', '-')}-icon`;
      }}
    ></MenuItem>
  );
};
export default PTPLinksMenuItem;
