export const getName = (o) => {
  if (o) {
    if (o.username) {
      return o.username;
    } else if (o.data && o.data.username) {
      return o.data.username;
    } else if (o.kind === 'access_point') {
      return o.default_name ? o.default_name : o.name;
    }
    return o.name;
  }
  return 'Undefined';
};

export const accessPointName = (access_point, site, template) => {
  return `${getName(site)} - ${template.equipment.band.name} - ${
    access_point.azimuth
  }\xB0`;
};

const getNameForListInMenu = (o) => {
  if (o && o.properties) {
    if (o.username) {
      return o.username;
    } else if (o.kind === 'access_point') {
      return o.default_name ? o.default_name : o.name;
    } else if (o.properties.kind === 'pmp_link') {
      return o.properties.name.split(' to ')[1];
    } else if (o.type === 'Feature') {
      return o.properties.name;
    }
    return o.name;
  } else {
    if (o.username) {
      return o.username;
    } else if (o.kind === 'access_point') {
      return o.default_name ? o.default_name : o.name;
    } else if (o.kind === 'pmp_link') {
      return o.name.split(' to ')[1];
    } else if (o.type === 'Feature') {
      return o.name;
    }
  }
  return 'Undefined';
};
/**
 * Simple name sort
 *
 * The sorting method used in LINKPlanner is too slow
 */
export const nameSort = (s1, s2) => {
  const name1 = getNameForListInMenu(s1).toLowerCase();
  const name2 = getNameForListInMenu(s2).toLowerCase();

  if (name1 > name2) {
    return 1;
  } else if (name1 < name2) {
    return -1;
  } else {
    return 0;
  }
};
