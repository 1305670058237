import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Grid,
  Message,
  Modal,
  Segment,
  Dropdown,
} from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { postWithAuth } from 'src/api';
import ProfileEditor from './ProfileEditor';
import messages from 'src/messages';
import { setProfile, updateSelection } from './profile.reducer';
import ProfileChart, {
  ProfileChartProps,
  getProfileTitle,
  getProfileType,
} from './ProfileChart';
import StoredAccordion from '../StoredAccordion';
import { last } from 'lodash';
import { store } from 'src/store';
import { uiConfirmAction } from 'src/pages/mainframe/mainframe.reducer';
import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import ReflectionPanel, { calculateDiversity } from './ReflectionPanel';
import { useLinkKind } from 'src/pages/ptp/hooks';

const revertProfileHandler = (
  projectId,
  profileData,
  formatMessage,
  parentFormSubmitHandler,
  modified,
  setOpen
) => {
  const deleteObj = {
    project_id: projectId,
    loc_lat: profileData.loc_lat,
    loc_lng: profileData.loc_lng,
    rem_lat: profileData.rem_lat,
    rem_lng: profileData.rem_lng,
  };
  store.dispatch(
    uiConfirmAction({
      header: formatMessage(messages.revertProfile),
      message: formatMessage(messages.revertProfileConfirmMessage),
      size: 'mini',
      onConfirm: () => {
        if (modified) {
          // parentFormSubmitHandler is used as ref for PMP links, since it's implementation is different with respect to PTP and Mesh Links,
          // we have declared ref in SubscriberPanel, which is parent to SMViewSelector and SubscriberDetailsPanel
          // ref value setted in SMViewSelector and passed same into SubscriberDetailsPanel, which internally pass to profileEditorModal
          // checking is it ref ot not?
          if (parentFormSubmitHandler?.current) {
            parentFormSubmitHandler.current();
            parentFormSubmitHandler.current = null;
          } else {
            parentFormSubmitHandler();
          }
        }
        postWithAuth(`profile/${projectId}`, deleteObj, 'DELETE')
          .then((res) => {})
          .catch((err) => {
            console.error('Failed to revert profile');
          });
        setOpen(false);
      },
    })
  );
};

// onSubmit -- along with profile modify if any parent page is already modified,
// then while saving profile edit, parent page changes also saving for PTP Link, Mesh Link.
interface ProfileEditorProps extends ProfileChartProps {
  path?: any;
  sm?: any;
  accordionName: string;
  kind?: string;
  parentFormSubmitHandler?: Function | null;
  modified?: boolean;
  dirty?: boolean;
  lk?: any;
  supportsDiverseProfiles?: boolean;
  diverseHeights?: any;
}

const submitProfile = (
  projectId,
  profile,
  parentFormSubmitHandler,
  modified
) => {
  let profileBody = {
    loc_lat: profile.loc_lat,
    loc_lng: profile.loc_lng,
    rem_lat: profile.rem_lat,
    rem_lng: profile.rem_lng,
    ranges: profile.ranges,
    heights: profile.heights,
    obstructions: profile.obstructions,
    use_clutter: profile.use_clutter,
    is_lidar: profile.is_lidar,
  };
  if (profile.use_clutter) {
    profileBody['clutter_types'] = profile.clutter_types;
  }
  if (modified) {
    // parentFormSubmitHandler is used as ref for PMP links, since it's implementation is different with respect to PTP and Mesh Links,
    // we have declared ref in SubscriberPanel, which is parent to SMViewSelector and SubscriberDetailsPanel
    // ref value setted in SMViewSelector and passed same into SubscriberDetailsPanel, which internally pass to profileEditorModal
    // checking is it ref ot not?
    if (parentFormSubmitHandler?.current) {
      parentFormSubmitHandler.current();
      parentFormSubmitHandler.current = null;
    } else {
      parentFormSubmitHandler();
    }
  }
  postWithAuth(`profile/${projectId}`, profileBody, 'PUT').catch((err) => {});
};

function ProfileEditorModal(props: ProfileEditorProps) {
  const {
    profileData,
    accordionName,
    setModified,
    parentFormSubmitHandler,
    modified,
    kind,
    path,
    sm,
  } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [updated, setUpdated] = useState({ status: false });
  const { formatMessage } = useIntl();
  const { projectId, projectName, prefs, clutterDetails } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  // Get the profile from the store. This will be a blank profile
  // until the user clicks the edit button
  const storeProfile = useSelector((state: RootStateOrAny) => state.profile);
  useEffect(() => {
    if (!open) {
      setUpdated({ status: false });
      dispatch(
        updateSelection({
          startIndex: null,
          endIndex: null,
        })
      );
    }
  }, [open]);

  const modalOpenHandler = (event) => {
    setOpen(true);
    event.preventDefault();
    dispatch(setProfile(profileData));
  };

  const { control } = useFormContext() || {};
  const dirty = kind === 'pmp' ? sm.dirty : path.dirty;
  return (
    <>
      {accordionName === 'ptp_profile_panel' && (
        <Controller
          name="reflection.enable_reflection"
          control={control}
          render={({ field }) => (
            <Checkbox
              className="profileReflectionEditButton"
              label={formatMessage(messages.enableReflectionMitigation)}
              checked={field.value}
              onChange={(e, { checked }) => {
                field.onChange(checked);
                setModified(true);
              }}
            />
          )}
        />
      )}
      <Button
        data-testid="profileEdit"
        primary
        className="profileEditButton"
        disabled={dirty}
        onClick={(event) => modalOpenHandler(event)}
      >
        {formatMessage(messages.edit)}
      </Button>
      <span onDoubleClick={modalOpenHandler}>
        <ProfileChart {...props} />
      </span>

      {getModal(
        _.omit(props, ['reflectionLine']),
        updated,
        setUpdated,
        open,
        setOpen,
        projectId,
        projectName,
        prefs,
        clutterDetails,
        storeProfile,
        parentFormSubmitHandler,
        modified
      )}
    </>
  );
}

const getModal = (
  props: ProfileEditorProps,
  updated,
  setUpdated,
  open,
  setOpen,
  projectId,
  projectName,
  prefs,
  clutterDetails,
  storeProfile,
  parentFormSubmitHandler,
  modified
) => {
  const { profileData, localHeight, remoteHeight, frequencyGHz } = props;
  const { formatMessage } = useIntl();

  let csvFilename = `${projectName}_profile.csv`;
  if (profileData) {
    const coords = [
      `${profileData.loc_lat.toFixed(5)}, ${profileData.loc_lng.toFixed(5)}`,
      'to',
      `${profileData.rem_lat.toFixed(5)}, ${profileData.rem_lng.toFixed(5)}`,
    ].join('_');
    csvFilename = `${projectName}_${coords}.csv`;
  }

  let profileLength = 0;
  let title = 'Loading profile...';

  if (storeProfile.ranges.length) {
    profileLength = last(storeProfile.ranges);
    const profileType = getProfileType(
      localHeight,
      remoteHeight,
      frequencyGHz,
      storeProfile,
      clutterDetails
    );
    title = getProfileTitle(profileLength, profileType, prefs);
  }

  const chartProps = { ...props, isEditing: true, profileData: storeProfile };
  const onCloseHandler = () => {
    setOpen(false);
  };

  return (
    <Modal
      className="profileEditorModal"
      size={'fullscreen'}
      onClose={onCloseHandler}
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {formatMessage(messages.profileEditor)}
        <Button
          circular
          icon="close"
          title={formatMessage(messages.close)}
          floated="right"
          onClick={onCloseHandler}
        />
      </Modal.Header>
      <Modal.Content>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={5}>
              <div
                className="ag-theme-alpine generic-grid"
                style={{ width: '100%', height: '95%' }}
              >
                <ProfileEditor
                  setUpdated={setUpdated}
                  csvFilename={csvFilename}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={11}>
              <Container basic>
                <Message color="grey">{title}</Message>
                <div style={{ width: '100%', height: '30vh' }}>
                  <ProfileChart {...chartProps} />
                </div>
              </Container>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign="center">
            <Grid.Column as={Message} info width={5}>
              Shift + left mouse click to select multiple rows in the table.
              <br />
              Double-click Terrain Height, Clutter Type or Obstruction Height to
              edit.
            </Grid.Column>
            <Grid.Column as={Message} info width={11}>
              Left mouse click + drag to select a range in the chart.
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          floated="left"
          negative
          onClick={() => {
            revertProfileHandler(
              projectId,
              profileData,
              formatMessage,
              parentFormSubmitHandler,
              modified,
              setOpen
            );
          }}
        >
          Revert Profile
        </Button>
        <Button onClick={onCloseHandler}>
          {formatMessage(messages.cancel)}
        </Button>
        <Button
          color="blue"
          disabled={!updated.status}
          onClick={() => {
            submitProfile(
              projectId,
              storeProfile,
              parentFormSubmitHandler,
              modified
            );
            setOpen(false);
          }}
        >
          {formatMessage(messages.apply)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const MAIN_MAIN = 0;
const MAIN_DIVERSE = 1;
const DIVERSE_MAIN = 2;
const DIVERSE_DIVERSE = 3;

function PathSwapper(props) {
  const { selectedPath, setSelectedPath } = props;
  const { ptp700DiverseOptions, hotStandbyDiverseOptions } = useLinkKind();

  let options;
  if (ptp700DiverseOptions != null) {
    options = ptp700DiverseOptions;
  } else if (hotStandbyDiverseOptions != null) {
    options = hotStandbyDiverseOptions;
  } else {
    options = [
      { text: 'Main to Main', key: 0, value: MAIN_MAIN },
      { text: 'Main to Diverse', key: 1, value: MAIN_DIVERSE },
      { text: 'Diverse to Main', key: 2, value: DIVERSE_MAIN },
      { text: 'Diverse to Diverse', key: 3, value: DIVERSE_DIVERSE },
    ];
  }

  return (
    <Dropdown
      selection
      defaultValue={0}
      value={selectedPath}
      options={options}
      onChange={(e, data) => {
        const { value } = data;
        setSelectedPath(value);
      }}
    />
  );
}

/*
 * Ensure that read-only users cannot see the profile editing functionality
 *
 * If the user is read-only then they see the non-editable profile chart component
 * without the modal code, etc.
 */
const ChartWrapper = (props: ProfileEditorProps) => {
  const { prefs, permissionWrite, clutterDetails } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const { watch, setValue } = useFormContext() || {};
  const {
    accordionName,
    localHeight: mainLocalHeight,
    remoteHeight: mainRemoteHeight,
    frequencyGHz,
    profileData,
    path,
    sm,
    setModified,
    kind,
    supportsDiverseProfiles,
    diverseHeights,
  } = props;

  const [selectedPath, setSelectedPath] = useState(MAIN_MAIN);

  useEffect(() => {
    // Reset heights to the main path when link kind changes, otherwise
    // the user can change them on SD and it won't reset when changing
    // the link kind, etc.
    if (props.lk != null) {
      setSelectedPath(MAIN_MAIN);
    }
  }, [props.lk]);

  let localHeight;
  let remoteHeight;

  if (diverseHeights) {
    if (selectedPath === MAIN_DIVERSE) {
      localHeight = diverseHeights[0][0];
      remoteHeight = diverseHeights[1][1];
    } else if (selectedPath === DIVERSE_MAIN) {
      localHeight = diverseHeights[0][1];
      remoteHeight = diverseHeights[1][0];
    } else if (selectedPath === DIVERSE_DIVERSE) {
      localHeight = diverseHeights[0][1];
      remoteHeight = diverseHeights[1][1];
    } else {
      localHeight = mainLocalHeight;
      remoteHeight = mainRemoteHeight;
    }
  } else {
    localHeight = mainLocalHeight;
    remoteHeight = mainRemoteHeight;
  }

  let profileLength = 0;
  let title = 'Loading profile...';
  if (profileData) {
    profileLength = last(profileData.ranges);
    const profileType = getProfileType(
      localHeight,
      remoteHeight,
      frequencyGHz,
      profileData,
      clutterDetails
    );
    title = getProfileTitle(profileLength, profileType, prefs);
  }

  const [
    enableReflection,
    heightASL,
    localFrequency,
    remoteFrequency,
    localAntennasHeight,
    remoteAntennasHeight,
    localProduct,
    localTxFrequency,
    remoteTxFrequency,
  ] = watch
    ? watch([
        'reflection.enable_reflection',
        'reflection.height_asl',
        'local.radios.0.frequency.frequency_mhz',
        'remote.radios.0.frequency.frequency_mhz',
        'local.radios.0.antennas.0.height',
        'remote.radios.0.antennas.0.height',
        'local.radios.0.equipment.product',
        'local.radios.0.frequency.tx_frequency',
        'remote.radios.0.frequency.tx_frequency',
      ])
    : [];

  if (!path.reflection && profileData && kind === 'ptp') {
    path.reflection = {
      enable_reflection: false,
      height_asl: Math.ceil(_.min(profileData.heights)),
      diversity_end: 'local',
      multiplier: 1,
    };
    setValue('reflection.enable_reflection', path.reflection.enable_reflection);
    setValue('reflection.height_asl', path.reflection.height_asl);
    setValue('reflection.diversity_end', path.reflection.diversity_end);
    setValue('reflection.multiplier', path.reflection.multiplier);
  }

  let reflectionLine = null;
  if (enableReflection) {
    reflectionLine = calculateDiversity(
      heightASL,
      localTxFrequency ?? localFrequency,
      remoteTxFrequency ?? remoteFrequency,
      localAntennasHeight,
      remoteAntennasHeight,
      profileData,
      clutterDetails
    );
  }

  let chart = null;
  const chartProps = { ...props, localHeight, remoteHeight };
  if (permissionWrite) {
    chart = (
      <ProfileEditorModal {...chartProps} reflectionLine={reflectionLine} />
    );
  } else {
    chart = <ProfileChart {...chartProps} />;
  }

  return (
    <>
      <StoredAccordion
        name={accordionName}
        title={title}
        contentProps={{
          style: {
            height: '40vh',
            paddingBottom: supportsDiverseProfiles ? '45px' : '25px',
          },
          loading: kind === 'pmp' ? sm.dirty : path.dirty,
        }}
      >
        {supportsDiverseProfiles ? (
          <PathSwapper
            selectedPath={selectedPath}
            setSelectedPath={setSelectedPath}
          />
        ) : null}
        {chart}
      </StoredAccordion>
      {enableReflection && (
        <StoredAccordion name="reflection_editor" title="Reflection Editor">
          <ReflectionPanel
            path={path}
            product={localProduct}
            reflectionLine={reflectionLine}
            setModified={setModified}
          />
          {/* some cases optimumDiversityLocal and  optimumDiversityRemote comes as NaN where reflectionLine is not null*/}
          {reflectionLine === null ||
          reflectionLine.optimumDiversityLocal === '' ||
          reflectionLine.optimumDiversityRemote === '' ? (
            <Segment inverted color="red" tertiary>
              Unable to calculate the reflection point with the current antenna
              heights.
            </Segment>
          ) : null}
        </StoredAccordion>
      )}
    </>
  );
};

export default ChartWrapper;
