export default class LocalStorageUtils {
  static getItem(key: string, defaultValue?) {
    return JSON.parse(localStorage.getItem(key)) ?? defaultValue;
  }
  //

  static setItem(key: string, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      // If the localstorage is full, call clearProjectState
      const cleaned = this.clearProjectState();
      if (cleaned) {
        localStorage.setItem(key, JSON.stringify(value));
      }
    }
  }

  static removeItem(key) {
    localStorage.removeItem(key);
  }

  static setItems(items: { key: string; value: any }[]) {
    items.forEach((item) => {
      this.setItem(item.key, item.key);
    });
  }

  static clearProjectState() {
    // get all key starts with 'cn.lp.menu_'
    const keys = Object.keys(localStorage).filter((key) =>
      key.startsWith('cn.lp.menu_')
    );
    const items = [];
    for (let i in keys) {
      const state = this.getItem(keys[i]);
      items.push({ ...state, key: keys[i] });
    }
    items.sort((a, b) => a.timeStamp - b.timeStamp);
    const countToRemove = items.length / 2;
    for (let i = 0; i < countToRemove; i++) {
      const item = items[i];
      localStorage.removeItem(item.key);
      console.log(`Removed key: ${item.key}`);
    }
    return countToRemove > 0;
  }
}
