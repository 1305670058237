import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postWithAuth } from '../../api';

const initialState = {
  tabIndexes: {
    ends: 0,
    performance: 0,
  },
  currentFrequencyEnd: {
    endName: null,
    pathIndex: null,
  },
};

export const deletePTPLinks = createAsyncThunk(
  'ptp/delete',
  async ({ projectId, ids }) => {
    return await postWithAuth(`project/${projectId}/ptp`, ids, 'DELETE');
  }
);

const ptpSlice = createSlice({
  name: 'ptp',
  initialState,
  reducers: {
    syncPtpTabs: (state, action) => {
      const { key, value } = action.payload;
      state.tabIndexes[key] = value;
    },
    openFrequencyModal: (state, action) => {
      const { endName, pathIndex } = action.payload;
      state.currentFrequencyEnd = { endName, pathIndex };
    },
  },
});

export const {
  syncPtpTabs,
  openFrequencyModal,
} = ptpSlice.actions;
export default ptpSlice.reducer;
