import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, List } from 'semantic-ui-react';
import { kindToLabel } from '../../utils/useful_functions';
import { getLayers, setLayers } from '../../utils/mapUtils';

const lineBreak = '---';
const allLayers = [
  'network_site',
  'subscriber_site',
  'ptp_link',
  'access_point',
  'mesh_link',
  'pmp_link',
  lineBreak,
  'network_site_label',
  'subscriber_site_label',
];

class LayerListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.modified = false;
  }

  componentDidMount() {
    const { visibleLayers } = this.props;
    this.setState({ ...visibleLayers });
  }

  handleLayerChange = (i) => {
    return (e) => {
      this.modified = true;
      let newState = { ...this.state };
      newState[i] = !this.state[i];
      this.setState({ ...newState });
      setLayers(newState);
    };
  };

  render() {
    let checkItems = [];
    allLayers.forEach((i) => {
      if (i === lineBreak) {
        checkItems.push(<Divider />);
      } else {
        const label = kindToLabel(i, true);
        const checked = this.state[i];
        checkItems.push(
          <List.Item key={i} onClick={this.handleLayerChange(i)}>
            <List.Icon name={checked ? 'checkmark box' : 'square outline'} />
            <List.Content>{label}</List.Content>
          </List.Item>
        );
      }
    });

    return (
      <List selection className="layers">
        {checkItems}
      </List>
    );
  }
}

const LayerList = connect((state, ownProps) => {
  const visibleLayers = getLayers();

  return {
    ...ownProps,
    visibleLayers,
  };
})(LayerListContainer);

export default LayerList;
