import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import MenuItem from './MenuItem';
import { nameSort } from 'src/utils/naming';

export const SubscriberSiteMenuItem = ({ expandSidebar }) => {
  const subscriberSitesAll = useSelector(
    (state) => state.mainFrame.subscriberSites
  );
  if (!subscriberSitesAll) {
    return null;
  }
  const subscriberSites = [...subscriberSitesAll.features];
  subscriberSites.sort(nameSort);
  const label = expandSidebar ? (
    <FormattedMessage
      id="mainMenu.subscriberSites"
      defaultMessage="Subscriber Sites"
      description="Subscriber Sites main menu command"
    />
  ) : null;

  return (
    <MenuItem
      label={label}
      expandSidebar={expandSidebar}
      kind="subscriber_site"
      path="subscriber_sites"
      headerIconClass="subscriber-site-icon"
      listIconClass="subscriber-site-menu-icon"
      listItems={subscriberSites}
    ></MenuItem>
  );
};
