import React from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  Image,
  Popup,
  PopupContent,
} from 'semantic-ui-react';
import networkSiteImage from './../../images/help/tower_image.png';
import subscriberSiteImage from './../../images/help/subscriber_site.png';
import ptpLinkImage from './../../images/help/ptp_link.png';
import networkDeviceImage from './../../images/help/network_device.png';
import meshNetworkImage from './../../images/help/mesh_network.png';
import pmpLinkImage from './../../images/help/pmp_link.jpg';
import viewshedImage from './../../images/help/viewshed.jpg';
import { useIntl } from 'react-intl';
import messages from 'src/messages';

const MapIconPopup = (props) => {
  const { trigger, kind } = props;
  const { formatMessage } = useIntl();
  let imageSrc = '';
  let title = '';
  let content = '';
  if (kind === 'network_site') {
    imageSrc = networkSiteImage;
    title = formatMessage(messages.networkSites);
    content = formatMessage(messages.networkSitesHelp);
  } else if (kind === 'subscriber_site') {
    imageSrc = subscriberSiteImage;
    title = formatMessage(messages.subscriberSites);
    content = formatMessage(messages.subscriberSitesHelp);
  } else if (kind === 'ptp') {
    imageSrc = ptpLinkImage;
    title = formatMessage(messages.ptpLinks);
    content = formatMessage(messages.ptpLinksHelp);
  } else if (kind === 'access_point') {
    imageSrc = networkDeviceImage;
    title = formatMessage(messages.networkDevices);
    content = formatMessage(messages.networkDevicesHelp);
  } else if (kind === 'mesh') {
    imageSrc = meshNetworkImage;
    title = formatMessage(messages.meshLinks);
    content = formatMessage(messages.meshLinksHelp);
  } else if (kind === 'pmp') {
    imageSrc = pmpLinkImage;
    title = formatMessage(messages.pmpLinks);
    content = formatMessage(messages.pmpLinksHelp);
  } else if (kind === 'viewshed') {
    imageSrc = viewshedImage;
    title = formatMessage(messages.viewsheds);
    content = formatMessage(messages.viewshedHelp);
  }
  return (
    <>
      <Popup
        className="map-items-help-text"
        position="left center"
        trigger={trigger}
        offset={[50, 0]}
        basic
      >
        <PopupContent>
          <Card>
            <Image src={imageSrc} />
            <CardContent>
              <CardHeader>{title}</CardHeader>
              <CardDescription>{content}</CardDescription>
            </CardContent>
          </Card>
        </PopupContent>
      </Popup>
    </>
  );
};

export default MapIconPopup;
