import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { SpatialDiversityField } from 'src/components/controls/rhf/SpatialDiversityField';
import FormCheckbox from 'src/pages/equipment/common/controls/FormCheckbox';
import FormSelect from 'src/pages/equipment/common/controls/FormSelect';
import {
  availabilityAsTimeString,
  checkValueInChoices,
} from 'src/utils/useful_functions';
import {
  type PTPShowProps,
  type PTPFormPanel,
  type PTPParams,
  type PTPChoices,
  type PTPFormField,
  txFrequencySupportedDevices,
  getEndFromChoices,
  getAntennasFromChoices,
  getEquipmentFromChoices,
  getCablingFromChoices,
  defaultLkShared,
} from '../utils';
import additionalMessages from 'src/messages';
import { ERROR_COLOR, WARNING_COLOR } from 'src/app.constants';
import {
  genericAntennaControl,
  genericAntennaHeight,
  genericInterference,
  genericMacAddress,
  genericMaxEirp,
  genericMaxPower,
  makeAntennaNextValue,
  genericLkRadioGetters,
  genericLkAntennaGetters,
} from './end-config-utils';
import { CableChoicesField } from 'src/components/controls/rhf/CableChoicesField';
import FeederLossField, {
  SecondaryFeederLossField,
} from '../FeederLossField';
import TxFrequencyField from '../TxFrequencyField';
import merge from 'lodash/merge';
import ScanLoss from './ScanLoss';

function isAntennaInternal(endName: string, pathIndex: number = 0): any {
  return ({ choices, formGetter }: PTPShowProps) => {
    const antennaId = formGetter(
      `${endName}.radios.${pathIndex}.antennas.0.lp_antenna_id`
    );

    for (const antenna of getAntennasFromChoices(choices, endName, pathIndex)) {
      if (antenna.id === antennaId) {
        return antenna.data.internal;
      }
    }

    return false;
  };
}

export function not(fn: any) {
  return (args: PTPShowProps) => {
    return !fn(args);
  };
}

const templatePathRe = /@/;

/**
 * Fill out attr boilerplate for various link kinds at the same time
 */
function attrs(key, templatePath) {
  if (!templatePathRe.test(templatePath)) {
    // if not templated, return original path
    return {
      [key]: templatePath,
    };
  } else {
    return {
      [key]: templatePath.replace(templatePathRe, 0),
      lk: {
        // add more link kinds when they are supported
        // array length includes aggregate
        unlicensed_1plus1: {
          [key]: [...Array(2).keys()].map((i) =>
            templatePath.replace(templatePathRe, i)
          ),
        },
        '1plus1': {
          [key]: [...Array(4).keys()].map((i) =>
            templatePath.replace(templatePathRe, i)
          ),
        },
        '2plus0': {
          [key]: [...Array(3).keys()].map((i) =>
            templatePath.replace(templatePathRe, i)
          ),
        },
        '2plus2': {
          [key]: [...Array(3).keys()].map((i) =>
            templatePath.replace(templatePathRe, i)
          ),
        },
        '4plus0': {
          [key]: [...Array(5).keys()].map((i) =>
            templatePath.replace(templatePathRe, i)
          ),
        },
      },
    };
  }
}

function getters(templatePath) {
  return attrs('getter', templatePath);
}

type LinkPerformanceOptions = {
  showAggregateThroughput?: boolean;
  showE1T1?: boolean;
  popupAttr?: string;
  showMinPayloadCapacity?: boolean;
  showTdm?: boolean;
};

const linkPerformanceDefaultOptions = {
  showAggregateThroughput: true,
  showE1T1: false,
  popupAttr: undefined,
  showMinPayloadCapacity: false,
  showTdm: false,
};

export function genericLinkSummary(
  opts?: LinkPerformanceOptions
): PTPFormPanel<any> {
  opts = { ...linkPerformanceDefaultOptions, ...opts };

  let fields = [];

  if (opts.showAggregateThroughput) {
    fields.push({
      label: 'Aggregate IP Throughput',
      ...getters('summary.@.link.mean_aggregate_data_rate'),
      units: 'Mbps',
      calculateUnits: true,
      precision: 2,
      bold: true,
    });
  }

  fields.push(
    merge(
      {
        label: 'Lowest Mode Availability',
        units: '%',
        precision: 4,
        bold: true,
      },
      attrs('getter', 'summary.@.link.system_reliability'),
      attrs('popup', opts.popupAttr)
    )
  );

  if (opts.showMinPayloadCapacity) {
    fields.push({
      label: ({ path }) => {
        return path.summary[0].link.min_payload_mode_label;
      },
      ...getters('summary.@.link.min_payload_capacity'),
      show({ path }) {
        return path.summary[0].link.min_payload_capacity != null;
      },
      warning({ path }) {
        return path.summary[0].link.min_payload_capacity < 99.95;
      },
      units: '%',
      precision: 4,
      bold: true,
    });
  }

  fields.push(
    ...[
      {
        label: 'System Gain Margin',
        ...getters('summary.@.link.system_fade_margin'),
        units: 'dB',
        precision: 2,
      },
      {
        label: 'Free Space Path Loss',
        ...getters('summary.@.link.free_space_path_loss'),
        units: 'dB',
        precision: 2,
      },
      {
        label: 'Gaseous Absorption Loss',
        ...getters('summary.@.link.mean_atmospheric_loss'),
        units: 'dB',
        precision: 2,
      },
      {
        label: 'Excess Path Loss',
        ...getters('summary.@.link.excess_path_loss'),
        units: 'dB',
        precision: 2,
      },
      {
        label: 'Total Path Loss',
        ...getters('summary.@.link.total_path_loss'),
        units: 'dB',
        precision: 2,
      },
    ]
  );

  if (opts.showE1T1) {
    fields.push(
      ...[
        {
          label: 'E1/T1 Availability Required',
          ...getters('summary.@.link.e1t1_reliability_requirement'),
          units: '%',
          min: 0,
          max: 100,
          precision: 4,
          show({ choices }) {
            const eq = getEquipmentFromChoices(choices);
            return (
              eq.precise_network_timing?.value === false &&
              eq.hasOwnProperty('tdm') &&
              eq.tdm.value !== 'NONE'
            );
          },
          tooltip({ formGetter, pathIndex }) {
            const availabilityStr = availabilityAsTimeString(
              formGetter(
                `summary.${pathIndex}.link.e1t1_reliability_requirement`
              )
            );
            return `Unavailable for ${availabilityStr}`;
          },
          editable: true,
        },
        {
          label: 'E1/T1 Availability',
          ...getters('summary.@.link.e1t1_reliability'),
          units: '%',
          precision: 4,
          show({ choices }) {
            const eq = getEquipmentFromChoices(choices);
            return (
              eq.precise_network_timing?.value === false &&
              eq.hasOwnProperty('tdm') &&
              eq.tdm.value !== 'NONE'
            );
          },
          tooltip({ path, pathIndex }) {
            const availabilityStr = availabilityAsTimeString(
              path.summary[pathIndex].link.e1t1_reliability
            );
            return `Unavailable for ${availabilityStr}`;
          },
          warning({ path }) {
            const requirement =
              path.summary[0].link.e1t1_reliability_requirement;
            const predicted = path.summary[0].link.e1t1_reliability;
            return predicted < requirement;
          },
        },
        {
          label: 'E1/T1 1-way latency',
          ...getters('summary.@.link.e1t1_latency'),
          units: 'ms',
          precision: 2,
          show({ choices }) {
            const eq = getEquipmentFromChoices(choices);
            return (
              eq.precise_network_timing?.value === false &&
              eq.hasOwnProperty('tdm') &&
              eq.tdm.value !== 'NONE'
            );
          },
        },
      ]
    );
  }

  if (opts.showTdm) {
    fields.push(
      ...[
        {
          label({ choices }) {
            const eq = getEquipmentFromChoices(choices);
            if (eq.tdm_type?.value === 'E1') {
              return 'E1 Carried';
            }
            return 'T1 Carried';
          },
          ...getters('summary.@.link.licensed_tdm'),
          show: hasLicensedTDM,
        },
        {
          label: 'TDM Availability Required',
          ...getters('summary.@.link.licensed_tdm_availability_req'),
          editable: true,
          units: '%',
          precision: 4,
          min: 0,
          max: 100,
          show: hasLicensedTDM,
          tooltip: ({ formGetter, pathIndex }) => {
            return `Unavailable for ${availabilityAsTimeString(
              formGetter(
                `summary.${pathIndex}.link.licensed_tdm_availability_req`
              )
            )}`;
          },
        },
        {
          label: 'TDM Availability Predicted',
          ...getters('summary.@.link.licensed_tdm_availability_pred'),
          units: '%',
          precision: 4,
          show: hasLicensedTDM,
          warning({ path }) {
            const requirement =
              path.summary[0].link.licensed_tdm_availability_req;
            const predicted =
              path.summary[0].link.licensed_tdm_availability_pred;
            return predicted < requirement;
          },
          tooltip: ({ formGetter, pathIndex }) => {
            return `Unavailable for ${availabilityAsTimeString(
              formGetter(
                `summary.${pathIndex}.link.licensed_tdm_availability_pred`
              )
            )}`;
          },
        },
      ]
    );
  }

  return {
    name: 'equipment_link_summary',
    title: additionalMessages.linkSummary,
    fields,
  };
}

function hasLicensedTDM({ path, pathIndex }) {
  // pathIndex => 0, 1, 2
  let tdm;

  if (pathIndex != null && pathIndex !== 0) {
    // path of a complex link
    const eq = path.local.radios[pathIndex - 1].equipment;
    tdm = [eq?.licensed_tdm];
  } else if (pathIndex === 0) {
    // aggregate
    tdm = path.local.radios.map((r) => r.equipment?.licensed_tdm);
  } else {
    // 1+0
    const eq = path.local.radios[0].equipment;
    tdm = [eq?.licensed_tdm];
  }

  return tdm.some((t) => t != null && t > 0);
}

type EndPerformanceOptions = {
  dataRateUnits?: string;
  receivePowerLabel?: string;
  meanIpPredictedPrecision?: number;
  meanIpRequiredPrecision?: number;
  minIpRequiredPrecision?: number;
  showE1T1?: boolean;
  aggregateReadonly?: string[];
  aggregateHidden?: string[];
  helpUrl?: string;
};

const endPerformanceDefaultOptions: EndPerformanceOptions = {
  dataRateUnits: 'Mbps',
  receivePowerLabel: 'Predicted Receive Power',
  meanIpPredictedPrecision: 2,
  meanIpRequiredPrecision: 1,
  minIpRequiredPrecision: 1,
  aggregateReadonly: [],
  aggregateHidden: [],
  showE1T1: false,
};

const validateIPRequired = (value) => {
  return value > 0;
};

export function genericEndPerformanceSummary(
  endName: string,
  opts?: EndPerformanceOptions
): PTPFormPanel<PTPParams> {
  opts = { ...endPerformanceDefaultOptions, ...opts };

  const fields: PTPFormField<PTPShowProps>[] = [
    {
      label: ({ choices }) => {
        const eq = getEquipmentFromChoices(choices);
        if (eq?.atpc?.value) {
          return 'Operational Power';
        } else {
          return opts.receivePowerLabel;
        }
      },
      helpUrl: ({ choices }) => {
        const eq = getEquipmentFromChoices(choices);
        if (eq?.atpc?.value) {
          return '/doc/performance_summary.html#performance-to-each-site';
        } else {
          return opts.helpUrl;
        }
      },
      ...getters(`summary.@.${endName}.predicted_receive_power`),
      warning: ({ path, pathIndex }) => {
        return (
          path.summary[pathIndex][endName].warnings?.receive_level_warning
            ?.length > 0
        );
      },
      warningPopup: ({ path, pathIndex }) => {
        const isError =
          path.summary[pathIndex][endName].warnings
            ?.receive_level_warning_is_error;
        let color = WARNING_COLOR;
        if (isError) {
          color = ERROR_COLOR;
        }

        return {
          message:
            path.summary[pathIndex][endName].warnings?.receive_level_warning,
          color,
          isError,
        };
      },
    },
    {
      label: 'Mean IP Predicted',
      ...getters(`summary.@.${endName}.mean_data_rate_predicted`),
      units: opts.dataRateUnits,
      calculateUnits: true,
      precision: opts.meanIpPredictedPrecision,
      warning: ({ path, pathIndex }) => {
        return !path.summary[pathIndex][endName].warnings?.data_rate_ok;
      },
    },
    {
      label: 'Mean IP Required',
      ...getters(`summary.@.${endName}.mean_data_rate_requirement`),
      editable: true,
      validate: validateIPRequired,
      units: opts.dataRateUnits,
      precision: opts.meanIpRequiredPrecision,
      calculateUnits: true,
    },
    {
      label: '% of Required IP',
      ...getters(`summary.@.${endName}.percentage_of_required_data_rate`),
      units: '%',
      precision: 0,
      warning: ({ path, pathIndex }) => {
        return !path.summary[pathIndex][endName].warnings?.data_rate_ok;
      },
    },
    {
      label: 'Min IP Required',
      ...getters(`summary.@.${endName}.minimum_data_rate_requirement`),
      editable: true,
      validate: validateIPRequired,
      units: opts.dataRateUnits,
      precision: opts.minIpRequiredPrecision,
      calculateUnits: true,
    },
    {
      label: 'Min IP Availability Required',
      ...getters(`summary.@.${endName}.minimum_reliability_requirement`),
      editable: true,
      units: '%',
      precision: 4,
      min: 0,
      max: 100,
      tooltip: ({ path, pathIndex }) => {
        return path.summary[pathIndex][endName].tooltips
          .minimum_reliability_requirement;
      },
    },
    {
      label: 'Min IP Availability Predicted',
      ...getters(`summary.@.${endName}.throughput_reliability`),
      units: '%',
      precision: 4,
      tooltip: ({ path, pathIndex }) => {
        return path.summary[pathIndex][endName].tooltips.throughput_reliability;
      },
      warning: ({ path, pathIndex }) => {
        return !path.summary[pathIndex][endName].warnings?.rel_ok;
      },
    },
  ];

  if (opts.showE1T1) {
    fields.push({
      label: 'E1/T1 Availability Predicted',
      ...getters(`summary.@.${endName}.e1t1_reliability`),
      units: '%',
      precision: 4,
      show({ choices }) {
        const eq = getEquipmentFromChoices(choices);
        return (
          eq.precise_network_timing?.value === false &&
          eq.hasOwnProperty('tdm') &&
          eq.tdm.value !== 'NONE'
        );
      },
      tooltip({ path, pathIndex }) {
        const availabilityStr = availabilityAsTimeString(
          path.summary[pathIndex][endName].e1t1_reliability
        );
        return `Unavailable for ${availabilityStr}`;
      },
      warning({ path }) {
        return path.summary[0][endName].warnings?.e1t1_rel_ok === false;
      },
    });
  }

  // LPWEB-868: for PTP-700, some fields are readonly and some are hidden
  // only on the aggregate path
  for (const readonlyField of opts.aggregateReadonly) {
    for (const field of fields) {
      if (field.getter.includes(readonlyField)) {
        // editable if we're not on the aggregate path
        field.editable = (params) => !pathIsAggregate(params);
      }
    }
  }

  for (const hiddenField of opts.aggregateHidden) {
    for (const field of fields) {
      if (field.getter.includes(hiddenField)) {
        // shown if we're not on the aggregate path
        field.show = (params) => !pathIsAggregate(params);
      }
    }
  }

  return {
    name: `${endName}_end_summary`,
    fields,
  };
}

function pathIsAggregate({ pathIndex, summaryLabels }) {
  if (summaryLabels.includes('Aggregate')) {
    return pathIndex === 0;
  }
  return false;
}

function isLicensedSD(choices) {
  const eq = getEquipmentFromChoices(choices);
  const lk = eq.link_protection?.value;
  return lk === 'sd_2plus0' || lk === 'sd_2plus2';
}

function diversitySpacingDecl(endName) {
  const computedValue = (_, formGetter) => {
    const lk = formGetter(`${endName}.radios.0.equipment.link_protection`);

    let main, diverse;
    if (lk === 'unlicensed_1plus1' || lk === 'unlicensed_2plus0') {
      [main, diverse] = formGetter([
        `${endName}.radios.0.antennas.0.height`,
        `${endName}.radios.1.antennas.0.height`,
      ]);
    } else if (lk === 'hot_standby') {
      if (endName === 'local') {
        [main, diverse] = formGetter([
          `${endName}.radios.0.antennas.0.height`,
          `${endName}.radios.2.antennas.0.height`,
        ]);
      } else {
        [main, diverse] = formGetter([
          `${endName}.radios.0.antennas.0.height`,
          `${endName}.radios.1.antennas.0.height`,
        ]);
      }
    } else {
      [main, diverse] = formGetter([
        `${endName}.radios.0.antennas.0.height`,
        `${endName}.radios.0.antennas.0.diverse_antenna.height`,
      ]);
    }

    return Math.abs(main - diverse);
  };

  return {
    attrName: `${endName}_diversity_spacing`,
    getter: `${endName}.radios.0.antennas.0.config.diversity_spacing`,
    component: GenericScaledField,
    useHeightPrefs: true,
    precision: 1,
    label: 'Diversity Spacing',
    editable: false,
    reCalculate: true,
    computedValue,
    componentProps({ choices, formGetter }) {
      const eq = getEquipmentFromChoices(choices);
      const lk = eq.link_protection?.value;
      if (lk !== 'unlicensed_2plus0' && lk !== 'unlicensed_1plus1') {
        return {
          textColor: computedValue(null, formGetter) > 18 ? 'red' : null,
        };
      }
      return {};
    },
    lk: {
      shared: defaultLkShared,
      unlicensed_1plus1: {
        getter: [
          // NOTE these are intentionally the same
          `${endName}.radios.0.antennas.0.config.diversity_spacing`,
          `${endName}.radios.0.antennas.0.config.diversity_spacing`,
        ],
      },
      '2plus0': {
        getter: [
          // NOTE these are intentionally the same
          `${endName}.radios.0.antennas.0.config.diversity_spacing`,
          `${endName}.radios.0.antennas.0.config.diversity_spacing`,
        ],
      },
    },
    show({ formGetter, choices }) {
      if (isLicensedSD(choices)) {
        return true;
      }

      const end = getEndFromChoices(choices, endName);
      // NOTE this is probably the wrong condition to check but it works
      // for now as the functionality is only required for PTP-700
      const hasChoices = end.antenna_protection_choices?.length > 1;
      const protection = formGetter(
        `${endName}.radios.0.antennas.0.config.antenna_protection`
      );
      return hasChoices && protection === 'Spatial Diversity';
    },
  };
}

type EndConfigOptions = {
  spatialDiversity?: boolean;
  supportsLicensedSD?: boolean;
  cableChoices?: boolean;
  powerSource?: boolean;
  ethernetCable?: boolean;
  scanLoss?: boolean;
  couplerLoss?: boolean;
  secondaryFeederLoss?: boolean;
  diversitySpacing?: boolean;
  reflectionMitigation?: boolean;
};

const endConfigDefaultOptions: EndConfigOptions = {
  spatialDiversity: false,
  supportsLicensedSD: false,
  cableChoices: false,
  powerSource: false,
  ethernetCable: false,
  scanLoss: false,
  couplerLoss: false,
  secondaryFeederLoss: false,
  diversitySpacing: false,
  reflectionMitigation: false,
};

export function genericEndConfig(endName: string, opts?: EndConfigOptions) {
  opts = { ...endConfigDefaultOptions, ...opts };

  let fields: any = [
    genericAntennaControl(endName, {
      getter: `${endName}.radios.0.antennas.0.lp_antenna_id`,
      label: !opts.supportsLicensedSD
        ? 'Antenna'
        : ({ choices }: PTPShowProps) => {
            if (isLicensedSD(choices)) {
              return 'Main Antenna';
            } else {
              return 'Antenna';
            }
          },
    }),
    genericAntennaHeight(endName),
  ];

  if (opts.couplerLoss) {
    fields.push({
      attrName: `${endName}_coupler_loss`,
      getter: `${endName}.radios.0.antennas.0.cabling.coupler_loss`,
      component: GenericScaledField,
      units: 'dB',
      precision: 1,
      reCalculate: true,
      label: 'Coupler Loss',
      editable: false,
      lk: {
        shared: defaultLkShared,
        unlicensed_1plus1: genericLkAntennaGetters(endName, 2, {
          getter: 'cabling.coupler_loss',
        }),
      },
      show({ formGetter, choices }) {
        const end = getEndFromChoices(choices, endName);
        // NOTE this is probably the wrong condition to check but it works
        // for now as the functionality is only required for PTP-700
        const hasChoices = end.antenna_protection_choices?.length > 1;
        const protection = formGetter(
          `${endName}.radios.0.antennas.0.config.antenna_protection`
        );
        return (
          hasChoices && protection === 'Common Antenna - Symmetric Coupling'
        );
      },
    });
  }

  if (opts.diversitySpacing) {
    fields.push(diversitySpacingDecl(endName));
  }

  if (opts.reflectionMitigation) {
    // PTP-700 only reflection mitigation
    fields.push({
      label: 'Reflection Mitigation',
      attrName: `${endName}_reflection_mitigation`,
      getter: `${endName}.radios.0.antennas.0.config.reflection_mitigation`,
      component: FormCheckbox,
      componentProps: ({ formGetter }) => {
        return {
          style: { paddingBottom: '1rem' },
          defaultChecked: formGetter(
            `${endName}.radios.0.antennas.0.config.reflection_mitigation`
          ),
        };
      },
      lk: {
        shared: defaultLkShared,
        unlicensed_1plus1: {
          getter: [
            // NOTE these are intentionally the same
            `${endName}.radios.0.antennas.0.config.reflection_mitigation`,
            `${endName}.radios.0.antennas.0.config.reflection_mitigation`,
          ],
        },
      },
      show({ formGetter, choices }) {
        const end = getEndFromChoices(choices, endName);
        // NOTE this is probably the wrong condition to check but it works
        // for now as the functionality is only required for PTP-700
        const hasChoices = end.antenna_protection_choices?.length > 1;
        const protection = formGetter(
          `${endName}.radios.0.antennas.0.config.antenna_protection`
        );
        return hasChoices && protection === 'Spatial Diversity';
      },
    });
  }

  fields.push({
    attrName: `${endName}_feeder_loss`,
    getter: `${endName}.radios.0.antennas.0.cabling.selected_feeder_loss`,
    component: FeederLossField,
    show: ({ formGetter, choices }: PTPShowProps) => {
      const cabling = getCablingFromChoices(choices, endName);
      return cabling?.show_feeder_loss;
    },
    lk: {
      shared: ({ lk, field, choices, endName }) => {
        const end = getEndFromChoices(choices, endName);
        const protection = end?.antenna_protection;
        if (
          lk === 'hot_standby' &&
          protection === 'Common Antenna - Asymmetric Coupling'
        ) {
          return false;
        }
        return defaultLkShared({ lk, field, choices, endName });
      },
    },
    componentProps({ choices, path }: PTPShowProps) {
      return {
        endName: endName,
        precision: 1,
        units: 'dB',
        defaultLimit: '',
        valueGetter: `${endName}.radios.0.antennas.0.cabling.selected_feeder_loss`,
        choices: choices,
        componentProps: {},
        label: 'Feeder Loss',
        disabled: true,
        path,
      };
    },
  });

  if (opts.secondaryFeederLoss) {
    fields.push({
      attrName: `${endName}_secondary_feeder_loss`,
      getter: `${endName}.radios.0.antennas.0.cabling.selected_secondary_feeder_loss`,
      component: SecondaryFeederLossField,
      lk: {
        shared: defaultLkShared,
      },
      show({ formGetter, choices }) {
        // show when 2+2 and not SD
        const eq = getEquipmentFromChoices(choices);
        const lk = eq.link_protection?.value;
        return (
          lk === 'co_polar_2plus2'
          || lk === 'cross_polar_2plus2'
          || lk === 'xpic_2plus2'
        );
      },
      componentProps({ choices, path }: PTPShowProps) {
        return {
          endName,
          precision: 1,
          units: 'dB',
          choices,
        };
      },
    });
  }

  if (opts.supportsLicensedSD) {
    fields.push({
      label: 'DIVIDER',
      showInShared: true,
      show({ choices }) {
        return isLicensedSD(choices);
      },
    });
    fields.push(
      genericAntennaControl(endName, {
        label: 'Diverse Antenna',
        getter: `${endName}.radios.0.antennas.0.diverse_antenna.lp_antenna_id`,
        show({ choices }) {
          return isLicensedSD(choices);
        },
      })
    );
    fields.push(
      genericAntennaHeight(endName, {
        label: 'Antenna Height',
        getter: `${endName}.radios.0.antennas.0.diverse_antenna.height`,
        show({ choices }) {
          return isLicensedSD(choices);
        },
      })
    );
    fields.push(diversitySpacingDecl(endName));
  }

  if (opts.powerSource === true) {
    fields.push({
      attrName: `${endName}_power_source`,
      getter: `${endName}.properties.power_source`,
      refreshesChoices: true,
      nextValue(currentValue: string, newChoices: PTPChoices) {
        const choices = getEndFromChoices(
          newChoices,
          endName
        ).power_source_choices;
        if (choices != null) {
          if (!checkValueInChoices(choices, currentValue)) {
            return getEndFromChoices(newChoices, endName).default_power_source;
          }
        }
        return null;
      },
      label: 'Power Source',
      lk: {
        shared: defaultLkShared,
      },
      component: FormSelect,
      componentProps({ choices, path }: PTPShowProps) {
        return {
          choices: getEndFromChoices(choices, endName).power_source_choices,
        };
      },
      show: ({ choices }: PTPShowProps) => {
        return Boolean(
          getEndFromChoices(choices, endName).power_source_choices
        );
      },
    });
  }

  if (opts.ethernetCable === true) {
    fields.push({
      attrName: `${endName}_ethernet_cable`,
      getter: `${endName}.properties.ethernet_cable`,
      refreshesChoices: true,
      nextValue(currentValue: string, newChoices: PTPChoices) {
        const choices = getEndFromChoices(
          newChoices,
          endName
        ).ethernet_cable_choices;
        if (choices != null) {
          if (!checkValueInChoices(choices, currentValue)) {
            return getEndFromChoices(newChoices, endName)
              .default_ethernet_cable;
          }
        }
        return null;
      },
      label: ({ choices }: PTPShowProps) => {
        const eq = getEquipmentFromChoices(choices);
        if (eq.product.value.startsWith('PTP 820A')) {
          return 'IDU-RFU';
        } else {
          return 'Ethernet Cable';
        }
      },
      lk: {
        shared: defaultLkShared,
      },
      component: FormSelect,
      componentProps({ choices, path }: PTPShowProps) {
        return {
          choices: getEndFromChoices(choices, endName).ethernet_cable_choices,
        };
      },
      show: ({ choices }: PTPShowProps) => {
        return Boolean(
          getEndFromChoices(choices, endName).ethernet_cable_choices
        );
      },
    });
  }

  if (opts.spatialDiversity) {
    fields.push({
      attrName: `${endName}_spatial_diversity`,
      getter: `${endName}.radios.0.antennas.0.config.unlicensed_sd`,
      component: SpatialDiversityField,
      label: 'Spatial Diversity',
      min: 0,
      max: 300,
      precision: 1,
      usePrefs: 'heightUnits',
      show: ({ formGetter, choices, pathIndex }: PTPShowProps) => {
        return (
          getEndFromChoices(choices, endName, pathIndex).allow_unlicensed_sd &&
          !isAntennaInternal(endName, pathIndex)({ formGetter, choices })
        );
      },
      lk: {
        shared: defaultLkShared,
      },
      componentProps({ choices }: PTPShowProps) {
        return {
          endName,
          defaultDiversitySpacing: getEndFromChoices(choices, endName)
            .default_unlicensed_sd,
          diversitySpacingGetter: `${endName}.radios.0.antennas.0.config.diversity`,
          reflectionMitigationGetter: `${endName}.radios.0.antennas.0.config.reflection_mitigation`,
        };
      },
    });
  }

  let cableLossCtrlConfig = {
    attrName: `${endName}_cable_loss`,
    getter: `${endName}.radios.0.antennas.0.cabling.user_cable_loss`,
    component: GenericScaledField,
    units: 'dB',
    precision: 1,
    min: 0,
    max: 999,
    label: 'Cable Loss',
    lk: {
      shared: defaultLkShared,
    },
    show({ formGetter, choices, pathIndex }: PTPShowProps) {
      return (
        !isAntennaInternal(endName, pathIndex)({ formGetter, choices }) &&
        !getCablingFromChoices(choices, endName, pathIndex)?.show_feeder_loss
      );
    },
  };

  if (opts.cableChoices) {
    // derive config from cableLossCtrlConfig
    const prefix = `${endName}.radios.0.antennas.0.cabling`;
    fields.push({
      ...cableLossCtrlConfig,
      component: CableChoicesField,
      lk: {
        shared: false,
        unlicensed_1plus1: genericLkAntennaGetters(endName, 2, {
          getter: 'cabling.user_cable_loss',
          feederCalculateGetter: 'cabling.feeder_calculate',
          feederTypeGetter: 'cabling.feeder_type',
          feederLengthGetter: 'cabling.feeder_length',
          calculatedCableLossGetter: 'cabling.calculated_cable_loss',
        }),
        '2plus0': genericLkAntennaGetters(endName, 2, {
          getter: 'cabling.user_cable_loss',
          feederCalculateGetter: 'cabling.feeder_calculate',
          feederTypeGetter: 'cabling.feeder_type',
          feederLengthGetter: 'cabling.feeder_length',
          calculatedCableLossGetter: 'cabling.calculated_cable_loss',
        }),
      },
      componentProps: {
        endName,
        feederCalculateGetter: `${prefix}.feeder_calculate`,
        feederTypeGetter: `${prefix}.feeder_type`,
        feederLengthGetter: `${prefix}.feeder_length`,
        calculatedCableLossGetter: `${prefix}.calculated_cable_loss`,
      },
      show({ formGetter, choices, pathIndex }: PTPShowProps) {
        const cabling = getCablingFromChoices(choices, endName, pathIndex);
        return (
          !isAntennaInternal(endName, pathIndex)({ formGetter, choices }) &&
          cabling?.allow_feeder_calculate &&
          !cabling?.show_feeder_loss
        );
      },
    });
  } else {
    fields.push(cableLossCtrlConfig);
  }

  if (opts.scanLoss === true) {
    fields.push({
      attrName: `${endName}_scan_loss`,
      getter: `${endName}.radios.0.antennas.0.config.scan_loss`,
      component: ScanLoss,
      recalc: true,
      lk: {
        shared: false,
        // NOTE I'm not sure if these getters are even used since it seems
        // getters are manually reconstructed from "pathIndex" in the
        // <ScanLoss /> component.
        // NOTE 1plus1 is missing here because 700 BSA does not support it
        '2plus0': genericLkAntennaGetters(endName, 2, {
          getter: 'config.scan_loss',
        }),
      },
      componentProps({ choices, path }: PTPShowProps) {
        return {
          endName: endName,
          path,
        };
      },
      show({ choices }) {
        const eq = getEquipmentFromChoices(choices);
        if (endName === 'local') {
          return eq.product.value === 'PTP 700 BSA';
        } else {
          return eq.remote_product?.value === 'PTP 700 BSA';
        }
      },
      nextValue(currentValue, newChoices) {
        if (currentValue == null) {
          return 0.0;
        }
      },
    });
  }

  fields.push(
    {
      attrName: `${endName}_use_positioner`,
      getter: `${endName}.radios.0.antennas.0.config.use_positioner`,
      component: FormCheckbox,
      componentProps: ({ formGetter }: PTPShowProps) => {
        return {
          defaultChecked: formGetter(
            `${endName}.radios.0.antennas.0.config.use_positioner`
          ),
        };
      },
      lk: {
        shared: defaultLkShared,
      },
      label: 'Positioner',
      show: ({ formGetter, choices, pathIndex }: PTPShowProps) => {
        return (
          getEndFromChoices(choices, endName, pathIndex).supports_positioner &&
          isAntennaInternal(endName, pathIndex)({ formGetter, choices })
        );
      },
    },
    {
      label: 'DIVIDER',
    },
    {
      label: 'Gas Group',
      attrName: `${endName}_gas_group`,
      getter: `${endName}.radios.0.power.gas_group`,
      component: FormSelect,
      lk: {
        shared: defaultLkShared,
      },
      nextValue(currentValue, newChoices) {
        const gasChoices = getEndFromChoices(
          newChoices,
          endName
        ).gas_group_choices;
        if (gasChoices != null) {
          const newValues = gasChoices.filter((c) => c.value);
          if (!newValues.includes(currentValue)) {
            return newValues[0].value;
          }
        }
      },
      componentProps: ({ choices }: PTPShowProps) => {
        return {
          choices: getEndFromChoices(choices, endName).gas_group_choices,
        };
      },
      show: ({ choices }: PTPShowProps) => {
        return getEndFromChoices(choices, endName).supports_hazloc;
      },
    },
    genericMaxEirp(endName),
    genericMaxPower(endName)
  );

  fields.push({
    attrName: `${endName}_tx_frequency`,
    getter: `${endName}.radios.0.frequency.tx_frequency`,
    component: TxFrequencyField,
    show: ({ formGetter, choices }: PTPShowProps) => {
      const product = getEquipmentFromChoices(choices).product.value;
      return txFrequencySupportedDevices.includes(product);
    },
    lk: {
      shared: false,
      '2plus0': {
        getter: [
          `${endName}.radios.0.frequency.tx_frequency`,
          `${endName}.radios.1.frequency.tx_frequency`,
        ],
        separate: ['cross_polar', 'co_polar'],
      },
      '2plus2': {
        getter: [
          `${endName}.radios.0.frequency.tx_frequency`,
          `${endName}.radios.1.frequency.tx_frequency`,
        ],
        separate: ['cross_polar_2plus2', 'co_polar_2plus2'],
      },
      '4plus0': {
        getter: [
          `${endName}.radios.0.frequency.tx_frequency`,
          `${endName}.radios.1.frequency.tx_frequency`,
          `${endName}.radios.2.frequency.tx_frequency`,
          `${endName}.radios.3.frequency.tx_frequency`,
        ],
        // TODO does this need to be separate?
      },
    },
    componentProps({ choices, path }: PTPShowProps) {
      return {
        endName: endName,
        precision: 3,
        units: 'MHz',
        defaultLimit: '',
        choices: choices,
        componentProps: {},
        label: 'Tx Frequency',
        disabled: true,
        path: path,
      };
    },
  });

  fields.push(
    {
      label: 'DIVIDER',
    },
    genericInterference(endName),
    {
      label: 'DIVIDER',
    },
    genericMacAddress(endName)
  );

  return {
    name: `${endName}_end_config`,
    fields,
  };
}

export function remoteProductNextValue(
  currentValue: string,
  newChoices: PTPChoices
) {
  const eq = getEquipmentFromChoices(newChoices);
  if (eq.remote_product == null) {
    return eq.product.value;
  }
  return eq.remote_product.value;
}

export function atpcFieldValue() {}

export function complexPolarizationConfig() {
  return {
    attrName: 'polarization',
    getter: 'local.radios.0.equipment.polarization',
    lk: {
      '2plus0': {
        getter: [
          'local.radios.0.equipment.polarization',
          'local.radios.1.equipment.polarization',
        ],
        separate: ['cross_polar', 'xpic', 'sd_2plus0'],
        sync(getter, setter, currentIdx, newValue) {
          const otherIdx = currentIdx === 1 ? 0 : 1;
          const otherValue =
            newValue === 'vertical' ? 'horizontal' : 'vertical';
          setter(
            `local.radios.${otherIdx}.equipment.polarization`,
            otherValue,
            { shouldDirty: true }
          );
        },
      },
      '2plus2': {
        getter: [
          'local.radios.0.equipment.polarization',
          'local.radios.1.equipment.polarization',
        ],
        separate: ['cross_polar_2plus2', 'xpic_2plus2', 'sd_2plus2'],
        sync(getter, setter, currentIdx, newValue) {
          const otherIdx = currentIdx === 1 ? 0 : 1;
          const otherValue =
            newValue === 'vertical' ? 'horizontal' : 'vertical';
          setter(
            `local.radios.${otherIdx}.equipment.polarization`,
            otherValue,
            { shouldDirty: true }
          );
        },
      },
      '4plus0': {
        getter: [
          'local.radios.0.equipment.polarization',
          'local.radios.1.equipment.polarization',
          'local.radios.2.equipment.polarization',
          'local.radios.3.equipment.polarization',
        ],
        separate: ['cross_polar_4plus0', 'xpic_4plus0'],
        sync(getter, setter, currentIdx, newValue) {
          const otherValue =
            newValue === 'vertical' ? 'horizontal' : 'vertical';
          const otherIdx = [1, 0, 3, 2][currentIdx];
          setter(
            `local.radios.${otherIdx}.equipment.polarization`,
            otherValue,
            { shouldDirty: true }
          );

          // do an extra check on the other pair
          // can get duplicates when going from co-polar to cross
          for (const pair of [
            [0, 1],
            [2, 3],
          ]) {
            if (!pair.includes(currentIdx)) {
              const values = getter([
                `local.radios.${pair[0]}.equipment.polarization`,
                `local.radios.${pair[1]}.equipment.polarization`,
              ]);

              if (values[0] === values[1]) {
                const otherValue2 =
                  values[0] === 'vertical' ? 'horizontal' : 'vertical';
                setter(
                  `local.radios.${pair[1]}.equipment.polarization`,
                  otherValue2,
                  { shouldDirty: true }
                );
              }
            }
          }
        },
      },
    },
  };
}
