import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Tab, TabPane } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import messages from 'src/messages';
import AccessPointsPanel from '../pmp/AccessPointsPanel';
import MeshLinksPanel from '../mesh/MeshLinksPanel';
import PMPLinksPanel from '../pmp/PMPLinksPanel';
import { setPmpSelectedTableTab } from 'src/pages/mainframe/mainframe.reducer';

const indexToUrl = ['/aps', '/mesh', '/subscribers'];

const getActiveIndex = (pathname) => {
  if (pathname === '/aps') {
    return 0;
  } else if (pathname === '/mesh') {
    return 1;
  } else if (pathname === '/subscribers') {
    return 2;
  }
};

function PMPTabbedView() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const panes = [
    {
      menuItem: {
        as: NavLink,
        to: '/aps',
        id: 'aps',
        key: 'aps',
        content: formatMessage(messages.networkDevices),
      },
      render: () => (
        <TabPane>
          <AccessPointsPanel />
        </TabPane>
      ),
    },
    {
      menuItem: {
        as: NavLink,
        to: '/mesh',
        id: 'mesh',
        key: 'mesh',
        content: formatMessage(messages.meshLinks),
      },
      render: () => (
        <TabPane>
          <MeshLinksPanel />
        </TabPane>
      ),
    },
    {
      menuItem: {
        as: NavLink,
        to: '/subscribers',
        id: 'pmp',
        key: 'pmp',
        content: formatMessage(messages.pmpLinks),
      },
      render: () => (
        <TabPane>
          <PMPLinksPanel />
        </TabPane>
      ),
    },
  ];

  return (
    <div className="pmp-tabbed-view">
      <Tab
        panes={panes}
        activeIndex={getActiveIndex(location.pathname)}
        onTabChange={(_, data) => {
          const { activeIndex } = data;
          dispatch(setPmpSelectedTableTab({ tab: indexToUrl[activeIndex] }));
        }}
      />
    </div>
  );
}

export default PMPTabbedView;
