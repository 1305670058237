import React, { useState, useEffect, useRef } from 'react';
import { get } from 'lodash';
import useDebounce from '../../hooks/useDebounce';
import * as Yup from 'yup';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Formik, Form as FormFormik, Field } from 'formik';
import { getWithAuth, postWithAuth } from '../../api';
import SemanticField from '../../components/controls/SemanticField';
import additionalMessages from '../../messages';
import { Modal, Button, Form, Message, Grid } from 'semantic-ui-react';
import {
  getDropdownOptions,
  trimObject,
  getSelectOptions,
  sortObjectsByKey,
  insideAntennaSector,
} from '../../utils/useful_functions';

import filterWorker from '../../workers/filter.worker';
import { distanceBetweenTwoPoints } from '../pmp/aplayout/AccessPointProperties';
import { KILOMETERS_TO_MILES } from '../../app.constants';

function MeshLinkDialog(props) {
  const { formatMessage } = props.intl;
  const [open, setOpen] = useState(true);
  const [spFilter, setSPFilter] = useState('');
  const [errMsg, setErrMsg] = useState(null);
  const { projectId, prefs, accessPoints, meshLinks } = useSelector(
    (state) => state.mainFrame
  );
  const [accessPointProperties, setAccessPointProperties] = useState([]);
  const [unConnectedNDList, setUnConnectedNDList] = useState([]);
  let apConnectedNumOfMeshlinks = useRef(0);

  const getUnConnectedNDList = (apId) => {
    getWithAuth(`project/${projectId}/access_point/${apId}/mesh_links`)
      .then((res) => {
        apConnectedNumOfMeshlinks.current = res.length;
        const localIdList = res.map((item) => item.local_id);
        const remoteIdList = res.map((item) => item.remote_id);
        const connectedNDListIds = [
          ...new Set([...localIdList, ...remoteIdList]),
        ];
        const sourceNDDetails = accessPointProperties.filter(
          (item) => item.id === apId
        );
        let result = accessPointProperties
          .filter(
            (item) => !connectedNDListIds.includes(item.id) && item.id !== apId
          )
          .filter(
            (
              item // insideAntennaSector using twice to check in both the ends i.e., point a to b and pint b to a
            ) =>
              insideAntennaSector(
                sourceNDDetails[0].beamwidth,
                sourceNDDetails[0].azimuth,
                sourceNDDetails[0].latitude,
                sourceNDDetails[0].longitude,
                item.latitude,
                item.longitude
              ) &&
              insideAntennaSector(
                item.beamwidth,
                item.azimuth,
                item.latitude,
                item.longitude,
                sourceNDDetails[0].latitude,
                sourceNDDetails[0].longitude
              )
          )
          .map((item, index) => {
            const distanceBtwTwoNDs = Number.parseFloat(
              distanceBetweenTwoPoints(
                {
                  latitude: sourceNDDetails[0].latitude,
                  longitude: sourceNDDetails[0].longitude,
                },
                { latitude: item.latitude, longitude: item.longitude }
              ) / 1000
            ).toFixed(3);
            return {
              ...item,
              distance: Number(distanceBtwTwoNDs),
              name: `${item.name} (${
                prefs.rangeUnits === 'mi'
                  ? Number.parseFloat(
                      KILOMETERS_TO_MILES * distanceBtwTwoNDs
                    ).toFixed(3)
                  : distanceBtwTwoNDs
              } ${prefs.rangeUnits})`,
            };
          })
          .filter((item) => item.distance > 0.004 && item.distance < 0.301);
        setUnConnectedNDList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let selectedDNOptions = [];
  const [apId, setApId] = useState('');
  useEffect(() => {
    getWithAuth(`project/${projectId}/access_points/supports_mesh`)
      .then((res) => {
        setAccessPointProperties(res);
        selectedDNOptions = getSelectOptions(res);
        selectedDNOptions =
          (selectedDNOptions.length &&
            sortObjectsByKey(selectedDNOptions, 'text')) ||
          [];
        if (!props.apId) {
          setApId(selectedDNOptions[0]['key']);
        } else {
          setApId(props.apId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  if (accessPointProperties.length) {
    selectedDNOptions = getSelectOptions(accessPointProperties);
    selectedDNOptions =
      (selectedDNOptions.length &&
        sortObjectsByKey(selectedDNOptions, 'text')) ||
      [];
  }
  let apName = null;
  if (props.apId) {
    const apObject = selectedDNOptions.filter((ap) => ap.key === props.apId);
    apName = get(apObject, '0.text');
  }
  const [filteredItems, setFilteredItems] = useState([]);
  useEffect(() => {
    if (apId) getUnConnectedNDList(apId);
  }, [apId]);
  const debounceFilter = useDebounce(spFilter, 500);
  const onCloseHandler = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    const worker = new filterWorker();

    worker.onmessage = (m) => {
      const results = JSON.parse(m.data);
      setFilteredItems(
        results.sort((itemOne, itemTwo) => itemOne.distance - itemTwo.distance)
      );
    };

    worker.postMessage(
      JSON.stringify({
        command: 'filter',
        items: unConnectedNDList,
        filter: debounceFilter,
      })
    );
    return () => {
      if (worker) {
        worker.terminate();
      }
    };
  }, [debounceFilter, unConnectedNDList]);

  const getDnOptions = () => {
    let eligibleOptions = [];
    if (apId !== '' && filteredItems.length) {
      const options = getDropdownOptions(filteredItems);
      const selectedApName = accessPoints.features.filter(
        (el) => el.properties.id === apId
      )[0].properties.name;
      let removeOptions = [];
      const generateLinkName = (name1, name2) =>
        `${name1.trim()} to ${name2.trim()}`;

      for (const meshLink of meshLinks.features) {
        const linkName = meshLink.properties.name;
        for (const el of options) {
          const elParts = el.props.children
            .split('(')
            .map((part) => part.trim());

          const linkMatches =
            linkName === generateLinkName(elParts[0], selectedApName) ||
            linkName === generateLinkName(elParts[1], selectedApName) ||
            linkName === generateLinkName(selectedApName, elParts[0]) ||
            linkName === generateLinkName(selectedApName, elParts[1]);

          if (linkMatches) {
            removeOptions.push(
              ...options.filter(
                (opt) =>
                  opt.props.children.includes(elParts[0]) ||
                  opt.props.children.includes(elParts[1])
              )
            );
          }
        }
      }
      eligibleOptions = options.filter(
        (ar) => !removeOptions.find((rm) => rm.props.value === ar.props.value)
      );
    }
    return eligibleOptions;
  };
  const DNsInRangeOptions = getDnOptions();

  const initialValues = {
    apIds: apId,
    ndIds: [], //unConnectedNDList.map((item) => item.id), // disabled by default selection
  };
  const validationSchema = Yup.object({
    ndIds: Yup.array()
      .of(Yup.string())
      .required(
        formatMessage(additionalMessages.requiredSelectError, {
          fieldName: 'DNs in Range',
        })
      ),
  });

  const onSubmit = (values, { setSubmitting }) => {
    trimObject(values);
    let data = {
      ap1_id: apId,
      ap2_ids: values.ndIds.map((id) => id?.id || id),
    };

    postWithAuth(`project/${projectId}/mesh_links`, data)
      .then((response) => {
        onCloseHandler();
        if (props.successCallback) {
          props.successCallback();
        }
      })
      .catch((err) => {
        setSubmitting(false);
        setErrMsg(err.message ?? err.detail);
      });
  };

  return (
    <Modal
      onClose={onCloseHandler}
      size="tiny"
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>
        {apName != null ? (
          `Attach Mesh Links to ${apName}`
        ) : (
          <FormattedMessage
            id="meshLinks.createMeshLink"
            defaultMessage="Create Mesh Links"
          />
        )}
        <Button
          circular
          icon="close"
          title={formatMessage(additionalMessages.close)}
          floated="right"
          onClick={onCloseHandler}
        />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            return (
              <FormFormik className="ui form">
                <Grid stretched>
                  {!props.apId && (
                    <Grid.Row>
                      <Grid.Column>
                        <SemanticField
                          fluid
                          search
                          selection
                          label={formatMessage(
                            additionalMessages.addMeshLinkSelectedDN
                          )}
                          component={Form.Dropdown}
                          options={selectedDNOptions}
                          onChange={(e, data) => {
                            formik.setFieldValue('ndIds', []);
                            setApId(data.value);
                            setFilteredItems(unConnectedNDList);
                            setErrMsg(null);
                          }}
                          name="apIds"
                          id="apIds"
                        ></SemanticField>
                        {formik.errors.apIds ? (
                          <Message className="flex-grow-0" negative>
                            <p>{formik.errors.apIds}</p>
                          </Message>
                        ) : null}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    <Grid.Column style={{ height: '40vh' }}>
                      <Form.Input
                        className="flex-grow-0"
                        label={formatMessage(
                          additionalMessages.addMeshLinkDNSInRange
                        )}
                        placeholder={formatMessage(additionalMessages.filter)}
                        fluid
                        icon={{
                          name: 'cancel',
                          link: true,
                          onClick: () => {
                            formik.setFieldValue('ndIds', []);
                            setSPFilter('');
                          },
                          title: formatMessage(additionalMessages.clearFilter),
                        }}
                        value={spFilter}
                        onChange={(e) => {
                          setSPFilter(e.target.value);
                          formik.setFieldValue('ndIds', []);
                        }}
                        autoFocus
                      ></Form.Input>
                      <Field as="select" name="ndIds" multiple>
                        {DNsInRangeOptions}
                      </Field>
                      {!formik.values.ndIds.length &&
                      DNsInRangeOptions.length ? (
                        <Message className="flex-grow-0" negative>
                          <p>
                            {formatMessage(
                              additionalMessages.attachMeshLinkSelectOneOrMoreDNs
                            )}
                          </p>
                        </Message>
                      ) : null}
                      {formik.values.ndIds.length &&
                      apConnectedNumOfMeshlinks.current +
                        formik.values.ndIds.length >
                        4 ? (
                        <Message className="flex-grow-0" negative>
                          <p>
                            {formatMessage(
                              additionalMessages.attachMeshLinkDNSupportMaxMeshLinks
                            )}
                          </p>
                        </Message>
                      ) : null}
                      {formik.errors.ndIds && formik.values.ndIds.length ? (
                        <Message className="flex-grow-0" negative>
                          <p>{formik.errors.ndIds}</p>
                        </Message>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {formik.values.ndIds.length > 0 &&
                apConnectedNumOfMeshlinks.current +
                  formik.values.ndIds.length <=
                  4 ? (
                  <div
                    style={{
                      borderBottom: '1px solid rgba(34, 36, 38, 0.15)',
                      padding: '10px 0',
                    }}
                  >
                    {formatMessage(
                      additionalMessages.attachMeshLinkNumOfDNsSelected
                    )}
                    {formik.values.ndIds.length}
                  </div>
                ) : null}
                <div className="modal-actions">
                  <Button onClick={onCloseHandler}>
                    {formatMessage(additionalMessages.cancel)}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!formik.isValid || !formik.values.ndIds.length}
                    loading={formik.isSubmitting}
                    color="blue"
                  >
                    {formatMessage(additionalMessages.ok)}
                  </Button>
                </div>
              </FormFormik>
            );
          }}
        </Formik>
      </Modal.Content>
      {errMsg && (
        <Message negative attached>
          <Message.Header>
            {formatMessage(additionalMessages.attachSMError)}
          </Message.Header>
          <p>{errMsg}</p>
        </Message>
      )}
    </Modal>
  );
}

export default injectIntl(MeshLinkDialog);
