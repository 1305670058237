import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Modal, Button, Dropdown, Form, Input, Icon } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { meterToAny, anyToMeter } from 'src/utils/useful_functions';
import { CLUTTER_LABELS } from 'src/app.constants';
import messages from 'src/messages';
import { injectIntl } from 'react-intl';
import { addPoint, updateSelection } from './profile.reducer';
import { cloneDeep } from 'lodash';

function getIndex(array, value, units) {
  const index = array.findIndex((val) => meterToAny(val, units, 3) >= value);
  return index !== -1 ? index : array.length;
}

type addPointHandlerProps = {
  range_str: string;
  terrain_height_str: string;
  obstruction_height_str: string;
  clutter_type: string;
  profile: {
    ranges: number[];
  };
  rangeUnits: string;
  heightUnits: string;
  dispatch: Function;
  setUpdated: Function;
};

const addPointHandler = ({
  range_str,
  terrain_height_str,
  obstruction_height_str,
  clutter_type,
  profile,
  rangeUnits,
  heightUnits,
  dispatch,
  setUpdated,
}: addPointHandlerProps) => {
  let range = parseFloat(range_str);
  let terrain_height = parseFloat(terrain_height_str);
  let obstruction_height = parseFloat(obstruction_height_str);
  const clutterTypes = Object.keys(CLUTTER_LABELS);
  const clutterLabels = Object.values(CLUTTER_LABELS);
  clutter_type = clutterTypes[clutterLabels.indexOf(clutter_type)];
  let index = getIndex(profile.ranges, range, rangeUnits);
  let numOfRemovePoints = 0;
  const profileClone = cloneDeep(profile);
  if (profile.ranges.length === index) {
    index = index - 1;
    numOfRemovePoints = 1;
    range = profile.ranges[index];
  } else if (range === meterToAny(profile.ranges[index], rangeUnits, 3)) {
    numOfRemovePoints = 1;
    range = profile.ranges[index];
  } else {
    range = anyToMeter(range, rangeUnits, 1, false);
  }
  terrain_height = anyToMeter(terrain_height, heightUnits, 1, false);
  obstruction_height = anyToMeter(obstruction_height, heightUnits, 1, false);
  profileClone.ranges.splice(index, numOfRemovePoints, range);
  profileClone.heights.splice(index, numOfRemovePoints, terrain_height);
  profileClone.clutter_types.splice(index, numOfRemovePoints, clutter_type);
  profileClone.obstructions.splice(
    index,
    numOfRemovePoints,
    obstruction_height
  );
  dispatch(addPoint(profileClone));
  dispatch(
    updateSelection({
      startIndex: index,
      endIndex: index,
      target: 'table',
    })
  );
  setUpdated({ status: true });
};

type getEstimatedHeightValueProps = {
  heightType: string;
  profile: {
    ranges: number[];
  };
  rangeUnits: string;
  heightUnits: string;
  getValues: Function;
};

function getEstimatedHeightValue({
  heightType,
  profile,
  rangeUnits,
  heightUnits,
  getValues,
}: getEstimatedHeightValueProps) {
  const index = getIndex(profile.ranges, getValues('range'), rangeUnits);
  const heightValues = profile[heightType];
  if (index === 0) {
    return meterToAny(heightValues[0], heightUnits, 1);
  } else if (index === profile.ranges.length) {
    return meterToAny(heightValues[index - 1], heightUnits, 1);
  } else {
    const rangeDiff = profile.ranges[index] - profile.ranges[index - 1];
    const heightDiff = heightValues[index] - heightValues[index - 1];
    const estimatedVal =
      ((anyToMeter(getValues('range'), rangeUnits, 3) -
        profile.ranges[index - 1]) /
        rangeDiff) *
        heightDiff +
      heightValues[index - 1];
    return meterToAny(estimatedVal, heightUnits, 1);
  }
}

type AddPointProps = {
  intl: any;
  open: boolean;
  onClose: Function;
  dropDownList: any;
  setUpdated: Function;
};

const AddPoint = ({
  intl,
  open,
  onClose,
  dropDownList,
  setUpdated,
}: AddPointProps) => {
  const { prefs } = useSelector((state: RootStateOrAny) => state.mainFrame);
  const { formatMessage } = intl;
  const profile = useSelector((state: RootStateOrAny) => state.profile);
  const { heightUnits, rangeUnits } = prefs;
  const { handleSubmit, control, reset, setValue, getValues } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    addPointHandler({
      range_str: data.range,
      terrain_height_str: data.terrain_height,
      obstruction_height_str: data.obstruction_height,
      clutter_type: data.clutter_type,
      profile,
      heightUnits,
      rangeUnits,
      dispatch,
      setUpdated,
    });
    reset();
    onClose();
  };
  const handleClose = () => {
    reset();
    onClose();
  };
  const dropdownOptions = dropDownList.map((item) => ({
    key: item,
    value: item.split(' (')[0], // splitting the dropdown value as name and number ex: Evergreen Forest (20.0) as Evergreen Forest and (20.0) and storing name as value
    text: item,
  }));

  return (
    <Modal className="addPoint" open={open} onClose={handleClose} size="mini">
      <Modal.Header>
        Add Point
        <Button
          circular
          icon="close"
          title={formatMessage(messages.close)}
          floated="right"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Description>
            <Form.Field className="range">
              <label>Range:</label>
              <Controller
                name="range"
                control={control}
                defaultValue="0.000"
                render={({ field }) => (
                  <Input
                    type="text"
                    {...field}
                    onChange={(e) => {
                      // Prevent negative values
                      if (/^\d*\.?\d*$/.test(e.target.value)) {
                        field.onChange(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      // Parse the input value and fix the decimals
                      const lastRangeVal = meterToAny(
                        profile.ranges[profile.ranges.length - 1],
                        rangeUnits,
                        3
                      );
                      const value = parseFloat(e.target.value);
                      const fixedValue = value.toFixed(3);
                      // Set the fixed value back in the input field
                      setValue(
                        'range',
                        isNaN(value)
                          ? '0.000'
                          : Number(fixedValue) >= lastRangeVal
                          ? lastRangeVal
                          : fixedValue
                      );
                    }}
                  />
                )}
              />
              <span>{rangeUnits === 'mi' ? 'miles' : 'kilometers'}</span>
            </Form.Field>
            <Form.Field className="height">
              <label>Height:</label>
              <Controller
                name="terrain_height"
                control={control}
                defaultValue="0.0"
                render={({ field }) => (
                  <Input
                    type="text"
                    {...field}
                    onChange={(e) => {
                      // Prevent negative values
                      if (/^\d*\.?\d*$/.test(e.target.value)) {
                        field.onChange(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      // Parse the input value and fix the decimals
                      const value = parseFloat(e.target.value);
                      const fixedValue = value.toFixed(1);
                      // Set the fixed value back in the input field
                      setValue(
                        'terrain_height',
                        isNaN(value) ? '0.0' : fixedValue
                      );
                    }}
                  />
                )}
              />
              <span>{heightUnits === 'ft' ? 'feet' : 'meters'}</span>
              <Button
                icon="interpolate-icon"
                size="large"
                title={formatMessage(
                  messages.addProfilePointsEstimatedHeightTitle
                )}
                onClick={(event, data) => {
                  event.preventDefault();
                  setValue(
                    'terrain_height',
                    getEstimatedHeightValue({
                      heightType: 'heights',
                      profile,
                      heightUnits,
                      rangeUnits,
                      getValues,
                    })
                  );
                }}
              />
            </Form.Field>
            {profile.clutter_types?.length && !profile.is_lidar ? (
              <Form.Field className="clutterType">
                <label>Clutter Type:</label>
                <Controller
                  name="clutter_type"
                  control={control}
                  defaultValue={dropdownOptions[0].value}
                  render={({ field }) => (
                    <Dropdown
                      data-testid="clutterType"
                      placeholder="Select Clutter Type"
                      selection
                      options={dropdownOptions}
                      value={field.value}
                      onChange={(e, { value }) => field.onChange(value)}
                    />
                  )}
                />
                <span>{heightUnits === 'ft' ? 'feet' : 'meters'}</span>
              </Form.Field>
            ) : null}
            <Form.Field className="obstruction">
              <label>Obstruction:</label>
              <Controller
                name="obstruction_height"
                control={control}
                defaultValue="0.0"
                render={({ field }) => (
                  <Input
                    type="text"
                    {...field}
                    onChange={(e) => {
                      // Prevent negative values
                      if (/^\d*\.?\d*$/.test(e.target.value)) {
                        field.onChange(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      // Parse the input value and fix the decimals
                      const value = parseFloat(e.target.value);
                      const fixedValue = value.toFixed(1);
                      // Set the fixed value back in the input field
                      setValue(
                        'obstruction_height',
                        isNaN(value) ? '0.0' : fixedValue
                      );
                    }}
                  />
                )}
              />
              <span>{heightUnits === 'ft' ? 'feet' : 'meters'}</span>
              <Button
                icon="interpolate-icon"
                size="large"
                title={formatMessage(
                  messages.addProfilePointsEstimatedObstructionTitle
                )}
                onClick={(event, data) => {
                  event.preventDefault();
                  setValue(
                    'obstruction_height',
                    getEstimatedHeightValue({
                      heightType: 'obstructions',
                      profile,
                      heightUnits,
                      rangeUnits,
                      getValues,
                    })
                  );
                }}
              />
            </Form.Field>
          </Modal.Description>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>{formatMessage(messages.cancel)}</Button>
        <Button color="blue" onClick={handleSubmit(onSubmit)}>
          {formatMessage(messages.apply)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default injectIntl(AddPoint);
