import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import additionalMessages from '../../messages';
import MapContainer from '../map/mapPanel';
import MapToolbar from '../map/mapToolbar';
import { useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import ViewshedModePanel from '../viewshed/ViewshedModePanel';
import { states } from '../map/leafletCanvas';
import SiteModePanel from '../map/SiteModePanel';
import PMPModePanel from '../map/PMPModePanel';
import PTPModePanel from '../map/PTPModePanel';
import MeshModePanel from '../map/MeshModePanel';
import AFCPanel from '../map/afc/AFCPanel';

const fullWidthPanels = [
  '/about',
  '/login',
  '/projects',
  '/bom',
  '/best_server',
  '/aps',
  '/mesh',
  '/subscribers',
  '/ptp',
  '/user_email_stats',
  '/mesh/',
  '/terragraph',
  '/antennas/',
];

const isFullWidth = (location) => {
  return fullWidthPanels.some(
    (v) =>
      location.pathname.startsWith(v) ||
      ['/network_sites', '/subscriber_sites'].includes(location.pathname)
  );
};

const WorkbenchPanelContainer = (props) => {
  const { projectId, expandSidebar, loadingProject } = props;
  const { formatMessage } = props.intl;
  const location = useLocation();

  const showDetail = location.pathname !== '/' && location.pathname !== '';

  const { mapState } = useSelector((state) => state.mainFrame);
  const viewshedMode = mapState === states.MAP_VIEWSHED_MODE;
  const networkSiteMode = mapState === states.MAP_CREATE_NETWORK_SITE;
  const subscriberSiteMode = mapState === states.MAP_CREATE_SUBSCRIBER_SITE;
  const apMode = mapState === states.MAP_CREATE_ACCESS_POINT;
  const pmpLinkMode = mapState === states.MAP_CREATE_PMP_LINK;
  const ptpLinkMode = mapState === states.MAP_CREATE_PTP_LINK;
  const meshLinkMode = mapState === states.MAP_CREATE_MESH_LINK;
  const afcMode = mapState === states.MAP_AFC_MODE;
  let panelClass = 'panelContainer';
  let extraStyle = null;
  if (showDetail) {
    panelClass = 'panelContainerVisible';
    if (isFullWidth(location)) {
      extraStyle = { width: '100%' };
    }
  }
  let sidebarClass = null;
  if (!projectId) {
    sidebarClass = 'hidden';
  } else if (!expandSidebar) {
    sidebarClass = 'collapsed';
  }

  return (
    <>
      <div className="detailWrapper">
        <Dimmer inverted active={loadingProject}>
          <Loader size="large" indeterminate>
            Loading...
          </Loader>
        </Dimmer>
        <MapContainer />
        {viewshedMode ? <ViewshedModePanel /> : null}
        {networkSiteMode ? (
          <SiteModePanel
            isNetwork={true}
            header={formatMessage(additionalMessages.networkSiteMode)}
          />
        ) : null}
        {subscriberSiteMode ? (
          <SiteModePanel
            isNetwork={false}
            header={formatMessage(additionalMessages.subscriberSiteMode)}
          />
        ) : null}
        {apMode ? <PMPModePanel isAP={true} /> : null}
        {pmpLinkMode ? <PMPModePanel isAP={false} /> : null}
        {ptpLinkMode ? <PTPModePanel /> : null}
        {meshLinkMode ? <MeshModePanel /> : null}
        <MapToolbar />
      </div>
      <div className={panelClass} style={extraStyle}>
        {props.children}
      </div>
      {afcMode ? <AFCPanel /> : null}
    </>
  );
};

const WorkbenchPanel = injectIntl(
  connect((state, ownProps) => {
    const { loggedIn, projectId, expandSidebar, loadingProject } =
      state.mainFrame;
    return {
      ...ownProps,
      loggedIn,
      projectId,
      expandSidebar,
      loadingProject,
    };
  })(WorkbenchPanelContainer)
);

export default WorkbenchPanel;
